/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// react-countup component


// Material Kit 2 React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function DefaultCounterCard({ color, count, title, description, ...rest }) {
  return (
    <SoftBox p={2} textAlign="center" lineHeight={1}>
      <SoftTypography variant="h1" color={color} textGradient>
      </SoftTypography>
      {title && (
        <SoftTypography variant="h5" mt={2} mb={1}>
          {title}
        </SoftTypography>
      )}
      {description && (
        <SoftTypography variant="body2" color="text">
          {description}
        </SoftTypography>
      )}
    </SoftBox>
  );
}

// Setting default props for the DefaultCounterCard
DefaultCounterCard.defaultProps = {
  color: "info",
  description: "",
  title: "",
};

// Typechecking props for the DefaultCounterCard
DefaultCounterCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  count: PropTypes.number.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default DefaultCounterCard;
