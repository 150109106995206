"use client";
import React, { useEffect, useRef, useState } from "react";
import styles from "../../../../css/VideoEditor/video.module.css";
import { useGlobalState } from "../Store/Store";
import PropTypes from "prop-types";

const ScaleRangeInput = ({ max, value, onChange, height, markings, backgroundColor }) => {
  const ref = useRef(null);
  const refIsMouseDown = useRef(false);
  const [canvasSize, setCanvasSize] = useState({ width: 50, height });
  const store = useGlobalState()
  const showIndicator = store. isSceneVisible.get()

  useEffect(() => {
    // Update canvas size based on container size
    const handleResize = () => {
      if (ref.current) {
        setCanvasSize({
          width: ref.current.parentElement?.clientWidth ?? 50,
          height: height,
        });
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [height]);

  useEffect(() => {
    if (ref.current) {
      const canvas = ref.current;
      canvas.width = canvasSize.width;
      canvas.height = canvasSize.height;
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        markings.forEach((marking) => {
          ctx.strokeStyle = marking.color;
          ctx.lineWidth = marking.width;
          ctx.beginPath();
          for (let i = 0; i < max; i += marking.interval) {
            ctx.moveTo((i / max) * canvas.width, 0);
            ctx.lineTo((i / max) * canvas.width, marking.size);
          }
          ctx.stroke();
        });
      }
    }
  }, [markings, backgroundColor, max, canvasSize]);

  const updateFromMouseEvent = (e) => {
    const rect = ref.current?.getBoundingClientRect();
    if (rect) {
      const x = e.clientX - rect.left;
      const value = (x / canvasSize.width) * max;
      const normalizedValue = Math.min(max, Math.max(0, value));
      console.log("normailzeValue",normalizedValue)
      onChange(normalizedValue);
    }
  };

  return (
    <div
      className={styles.scaleRangeInput}
      onMouseDown={(e) => {
      refIsMouseDown.current = true;
        updateFromMouseEvent(e);
      }}
      onMouseUp={(e) => {
      refIsMouseDown.current = false;
      }}
      onMouseMove={(e) => {
       if (refIsMouseDown.current) {
                updateFromMouseEvent(e);
            }
      }}
      onMouseLeave={(e) => {
      refIsMouseDown.current = false;
      }}
    >
      <canvas ref={ref}></canvas>
      {showIndicator && (
      <div
        className={styles.rangeIndicator}
        style={{
          // height: `${height}px`,
          minHeight:'auto',
          transform: `translateX(${(value / max) * canvasSize.width}px) translateX(-2px)`,
        }}
      ></div>)}
    </div>
  );
};

ScaleRangeInput.propTypes = {
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  height: PropTypes.number.isRequired,
  markings: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      interval: PropTypes.number.isRequired,
      size: PropTypes.number.isRequired,
    })
  ).isRequired,
  backgroundColor: PropTypes.string.isRequired,
};

export default ScaleRangeInput;
