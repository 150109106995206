// import React, { useState } from 'react';
// import axios from 'axios';
// const FacebookSdk = () => {
//   const [accessToken, setAccessToken] = useState('');
//   const handleLogin = async () => {
//     // Redirect user to Facebook's OAuth dialog
//     const appId = '1078309636537448'; // Replace with your Facebook App ID
//     const redirectUri = 'https://influenzo.io/'; // Replace with your redirect URI
//     const state = '98754321'; // Isko change karna hai har baar
//     const oauthUrl = `https://www.facebook.com/v20.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&state=${state}`;
//     window.location.href = oauthUrl;
//   };
//   const handleCallback = async () => {
//     const urlParams = new URLSearchParams(window.location.search);
//     const code = urlParams.get('code');
//     if (code) {
//       try {
//         const appId = '1078309636537448'; // Replace with your Facebook App ID
//         const appSecret = '34ac456787c266e2ff81d29c8745d946'; // Replace with your Facebook App Secret
//         const redirectUri = 'https://influenzo.io/'; // Replace with your redirect URI
//         const tokenUrl = `https://graph.facebook.com/v20.0/oauth/access_token?client_id=${appId}&redirect_uri=${redirectUri}&client_secret=${appSecret}&code=${code}`;
//         const response = await axios.get(tokenUrl);
//         setAccessToken(response.data.access_token);
//         // Optional: You might want to redirect or display the token elsewhere
//         console.log('Access Token:', response.data.access_token);
//       } catch (error) {
//         console.error('Error fetching access token:', error);
//       }
//     }
//   };
//   // Check for authorization code on initial render
//   React.useEffect(() => {
//     handleCallback();
//   }, []);
//   return (
//     <div>
//       <h1>Instagram Access Token</h1>
//       <button onClick={handleLogin}>Login with Facebook</button>
//       {accessToken && <p>Access Token: {accessToken}</p>}
//     </div>
//   );
// };
// export default FacebookSdk;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
const FacebookSdk = () => {
  const [accessToken, setAccessToken] = useState('');
  const [longLivedToken, setLongLivedToken] = useState('');
  const handleLogin = async () => {
    // Redirect user to Facebook's OAuth dialog
    const appId = '1078309636537448'; // Replace with your Facebook App ID
    const redirectUri = 'https://influenzo.io/'; // Replace with your redirect URI
    const state = '98754321'; // Replace with a unique state value
    // Define the permissions you need
    const permissions = 'public_profile,email,read_insights,pages_show_list,ads_management,business_management,instagram_basic,instagram_manage_insights,instagram_content_publish,pages_read_engagement';
    // Construct the OAuth URL with permissions
    const oauthUrl = `https://www.facebook.com/v20.0/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&scope=${permissions}&state=${state}`;
    window.location.href = oauthUrl;
  };
  const handleCallback = async () => {
        // const urlParams = new URLSearchParams(window.location.search);
        // const code = urlParams.get('code');
    const code = "AQBzyywX_Fmdgy_zHpVruFJ0Tv8y8fqx-5udFKtVv5Jt874nZqCnCRo56yYtrTqwGPy0DsoJ7kLIBiDcMg3s5DgZn-CF2VtjbJcIahSIIORc8bgITSwzSx9JdNrXTeNCE4nT2wRKD1X8jwCgnUQGYbZzuck3h1h37xB9IheWcy0VZHRy81Ql4v7fJhUauYWcg5V0ZTvBUFH8KMqDZPOex6xT1nTPCbgot85n2NdV0liwKsKAuhMnge375hWKDMB85fiOMzR8eueV8dp6YYpRYjTODWehdetqw_6W1-7lNU6XvurQFYqrTD2L3CJ-d6ceAJbxwOTk6yn6DoKhqe2o95CC_I_UlG0wDEKT4VHJHPFRA6qvwDivBXMwVBDKUyBwyEQ";
    if (code) {
      try {
        const appId = '1078309636537448'; // Replace with your Facebook App ID
        const appSecret = '34ac456787c266e2ff81d29c8745d946'; // Replace with your Facebook App Secret
        const redirectUri = 'https://influenzo.io/'; // Replace with your redirect URI
        // Exchange the code for a short-lived token
        const tokenUrl = `https://graph.facebook.com/v20.0/oauth/access_token?client_id=${appId}&redirect_uri=${redirectUri}&client_secret=${appSecret}&code=${code}`;
        const response = await axios.get(tokenUrl);
        const shortLivedToken = response.data.access_token;
        setAccessToken(shortLivedToken);
        // Exchange the short-lived token for a long-lived token
        const longLivedTokenUrl = `https://graph.facebook.com/v20.0/oauth/access_token?grant_type=fb_exchange_token&client_id=${appId}&client_secret=${appSecret}&fb_exchange_token=${shortLivedToken}`;
        const longLivedResponse = await axios.get(longLivedTokenUrl);
        setLongLivedToken(longLivedResponse.data.access_token);
        // Optional: You might want to redirect or display the token elsewhere
        console.log('Short-Lived Access Token:', shortLivedToken);
        console.log('Long-Lived Access Token:', longLivedResponse.data.access_token);
      } catch (error) {
        console.error('Error fetching access token:', error);
      }
    }
  };
  // Check for authorization code on initial render
  useEffect(() => {
    handleCallback();
  }, []);
  return (
    <div>
      <h1>Facebook Access Token</h1>
      <button onClick={handleLogin}>Login with Facebook</button>
      <h1>EAAPUt4bXZCGgBOxQTZCkbFsFD1KmUC2mhiCvfVSLMLgHuBh8wIjUbDA8QJFJAuAoAPn5joGeUDZCFB7ZA8WZAtJu6H4yHwEXm3wbu6eymaSMNfa5LYD8x2iXI4MjXPh7abrZCBvTXBUli4N7L4ZAx9p9KGpEchVM0y1fUZBlmgybkwZCvtZBMZBmBBebRwf</h1>
      {accessToken && <p>Short-Lived Access Token: {accessToken}</p>}
      {longLivedToken && <p>Long-Lived Access Token: {longLivedToken}</p>}
    </div>
  );
};
export default FacebookSdk;