"use client";
import React, { useState } from "react";
import { useGlobalState, addUploadedMedia, isEditorVideoElement, isEditorImageElement, getMediaDuration, addUploadedAudio, getAudioDuration, addAnimation } from "../Store/Store";
import UploadButton from "./UploadButton";
import MediaResource from "./MediaResource";
import SoftBox from "components/SoftBox";
import AudioResource from "./AudioResource";
import { MdOutlineTextFields, MdOutlineAnimation, MdOutlinePermMedia, MdOutlineAudioFile } from "react-icons/md";
import TextResource from "./TextResource";
import { AnimationResource } from "./AnimationResource";
import { DiHtml5Multimedia } from "react-icons/di";
import EffectResource from "./EffectResource";
import style from "../../../../css/VideoEditor/video.module.css"

const TEXT_RESOURCES = [
  { id: 1, name: "Title", fontSize: 28, fontWeight: 600 },
  { id: 2, name: "Subtitle", fontSize: 16, fontWeight: 600 },
  { id: 3, name: "Body", fontSize: 14, fontWeight: 400 },
  { id: 4, name: "Caption", fontSize: 12, fontWeight: 400 },
  { id: 5, name: "Heading 1", fontSize: 24, fontWeight: 800 },
  { id: 6, name: "Heading 2", fontSize: 20, fontWeight: 800 },
  { id: 7, name: "Heading 3", fontSize: 18, fontWeight: 800 },
  { id: 8, name: "Heading 4", fontSize: 16, fontWeight: 800 },
  { id: 9, name: "Heading 5", fontSize: 14, fontWeight: 800 },
  { id: 10, name: "Heading 6", fontSize: 12, fontWeight: 800 }
];

const MediaResourcesPanel = () => {
  const store = useGlobalState();
  const [selectedResourceType, setSelectedResourceType] = useState(null);
  const [textColors, setTextColors] = useState(
    TEXT_RESOURCES.reduce((acc, text) => {
      acc[text.id] = "#000000"; // Default color
      return acc;
    }, {})
  );

  const handleColorSelect = (id, color) => {
    setTextColors(prevColors => ({ ...prevColors, [id]: color }));
  };

  const selectedElement = store.elementToAddAnimation.get();
  const selectedEditedElement = selectedElement && selectedElement.media && Object.values(selectedElement.media)?.[0]
    ? JSON.parse(JSON.stringify(Object.values(selectedElement.media)[0]))
    : null;

  // Utility function to check if an animation of a certain type exists
  const hasAnimationOfType = (animations, type) => {
    return animations.some((animation) => animation.type === type);
  };

  // Filter animations for the selected element
  const selectedElementAnimations = store.animations.get().filter((animation) => animation.targetId === selectedElement?.name);

  // Check for specific animation types
  const hasFadeInAnimation = hasAnimationOfType(selectedElementAnimations, "fadeIn");
  const hasFadeOutAnimation = hasAnimationOfType(selectedElementAnimations, "fadeOut");
  const hasSlideInAnimation = hasAnimationOfType(selectedElementAnimations, "slideIn");
  const hasSlideOutAnimation = hasAnimationOfType(selectedElementAnimations, "slideOut");
  const hasConstantAnimation = hasAnimationOfType(selectedElementAnimations, "breathe");


  // console.log("selec", selectedElementAnimations)

  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (!files) return;
    // setSelectedResourceType("media");
    for (const file of files) {
      const type = file.type.startsWith("video") ? "video" : "image";
      const name = file.name;
      const url = URL.createObjectURL(file);

      let duration = null;
      if (type === "video") {
        try {
          const durationInSec = await getMediaDuration(url);
          duration = durationInSec * 1000;
        } catch (error) {
          console.error("Error getting media duration:", error);
        }
      } else {
        duration = 20000; // Default duration for images
      }
      addUploadedMedia(type, url, name, duration);
    }
  };

  const handleAudioFileChange = async (event) => {
    const files = event.target.files;
    if (!files) return;
    // setSelectedResourceType("audio");
    for (const file of files) {
      const type = "audio";
      const name = file.name;
      const url = URL.createObjectURL(file);

      let duration = null;
      if (type === "audio") {
        try {
          const durationInSec = await getAudioDuration(url);
          duration = durationInSec * 1000;
        } catch (error) {
          console.error("Error getting media duration:", error);
        }
      }
      addUploadedAudio(type, url, name, duration);
    }
  };

  const handleDuplicateMedia = (media) => {
    const { type, src, name, duration } = media;
    const newMedia = { type, src, name: `${name} (Copy)`, duration };
    addUploadedMedia(type, src, newMedia.name, duration);
  };

  const handleDuplicateAudio = (audio) => {
    const { type, src, name, duration } = audio;
    const newAudio = { type, src, name: `${name} (Copy)`, duration };
    addUploadedAudio(type, src, newAudio.name, duration);
  };

  const handlePanel = (resource) => {
    if (resource === "media") {
      setSelectedResourceType("media")
    } else if (resource === "audio") {
      setSelectedResourceType("audio")
    } else if (resource === "text") {
      setSelectedResourceType("text")
    } else if (resource === "effect") {
      setSelectedResourceType("effect")
    } else if (resource === "animation") {
      setSelectedResourceType("animation")
    }
  }

  const buttonStyles = (panel) => ({
    border: 'none',
  });

  const iconColor = (panel) => ({
    color: selectedResourceType === panel ? '#008dda' : 'black', 
  });


  return (
    <SoftBox style={{ marginTop: "0px", height: "100%" }}>
      <SoftBox style={{ position: "sticky", top: "0", zIndex: 1, backgroundColor: "white" }}>
        <button className="bg-transparent text-center mx-2 mb-2 py-2 px-2 cursor-pointer border-none" onClick={() => handlePanel("media")} style={buttonStyles("media")}>
          <MdOutlinePermMedia style={iconColor("media")}/>
        </button>
        <button className="bg-transparent text-center mx-2 mb-2 py-2 px-2 cursor-pointer border-none" onClick={() => handlePanel("audio")} style={buttonStyles("audio")}>
          <MdOutlineAudioFile style={iconColor("audio")}/>
        </button>
        <button className="bg-transparent text-center mx-2 mb-2 py-2 px-2 cursor-pointer border-none" onClick={() => handlePanel("text")} style={buttonStyles("text")}>
          <MdOutlineTextFields style={iconColor("text")}/>
        </button>
        <button className="bg-transparent text-center mx-2 mb-2 py-2 px-2 cursor-pointer border-none" onClick={() => handlePanel("animation")} style={buttonStyles("animation")}>
          <MdOutlineAnimation style={iconColor("animation")}/>
        </button>
        <button className="bg-transparent text-center mx-2 mb-2 py-2 px-2 cursor-pointer border-none" onClick={() => handlePanel("effect")} style={buttonStyles("effect")}>
          < DiHtml5Multimedia style={iconColor("effect")}/>
        </button>
      </SoftBox>
      <SoftBox
        style={{
          height: "400px",
          overflowY: "auto",
          marginTop: "4px",
          maxHeight: "300px",
          marginLeft: "16px",
          "-ms-overflow-style": "none",
          scrollbarWidth: "none",
        }}
      >
        {selectedResourceType === "media" && (
          <>
            <UploadButton
              accept="image/*,video/mp4,video/x-m4v,video/*"
              multiple
              className={style.uploadButton}
              onChange={handleFileChange}
              toggle={false}
              resource="media"
            />
            <div style={{
              display: "flex",
              flexDirection: "column",
              height: "400px",
              overflowY: "auto",
              marginTop: "4px",
              border: "1px solid #ccc",
              padding: "8px"
            }}>
              {store.uploadedMedia.get().map((media, index) => (
                <MediaResource
                  key={index}
                  media={media}
                  onDuplicate={handleDuplicateMedia}  // Pass the duplication handler
                />
              ))}
            </div>
          </>
        )}

        {selectedResourceType === "audio" && (
          <>
            <UploadButton
              accept="audio/*"
              multiple
              className={style.uploadButton}
              onChange={handleAudioFileChange}
              toggle={false}
              resource="audio"
            />
            <div style={{
              display: "flex",
              flexDirection: "column",
              height: "265px",
              overflowY: "auto",
              marginTop: "4px",
              border: "1px solid #ccc",
              padding: "8px"
            }}>
              {store.uploadedAudio.get().map((audio, index) => (
                <AudioResource
                  key={index}
                  audio={audio}
                  onDuplicate={handleDuplicateAudio}  // Pass the duplication handler
                />
              ))}
            </div>
          </>
        )}

        {selectedResourceType === "text" && (
          <div style={{
            display: "flex",
            flexDirection: "column",
            height: "400px",
            overflowY: "auto",
            marginTop: "4px",
            border: "1px solid #ccc",
            padding: "8px"
          }}>
            {TEXT_RESOURCES.map(text => (
              <div key={text.id} style={{ marginBottom: "8px" }}>
                <TextResource
                  id={text.id}
                  sampleText={text.name}
                  fontSize={text.fontSize}
                  fontWeight={text.fontWeight}
                  fontColor={textColors[text.id]}
                />
              </div>
            ))}
          </div>
        )}

        {selectedResourceType === "animation" && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {selectedElement && !hasFadeInAnimation ? (
              <button
                className="text-sm px-[16px] py-[8px] font-semibold hover:bg-slate-700 hover:text-white cursor-pointer"
                onClick={() => {
                  addAnimation({
                    name: "fadeIn",
                    type: "fadeIn",
                    targetId: selectedElement?.name ?? "",
                    duration: 1000,
                    properties: {}
                  })
                }}
              >
                Add Fade In
              </button>
            ) : null}
            {selectedElement && !hasFadeOutAnimation ? (
              <button
                className="text-sm px-[16px] py-[8px] font-semibold hover:bg-slate-700 hover:text-white cursor-pointer"
                onClick={() => {
                  addAnimation({
                    name: "fadeOut",
                    type: "fadeOut",
                    targetId: selectedElement?.name ?? "",
                    duration: 1000,
                    properties: {}
                  })
                }}
              >
                Add Fade Out
              </button>
            ) : null}
            {selectedElement && !hasSlideInAnimation ? (
              <button
                className="text-sm px-[16px] py-[8px] font-semibold hover:bg-slate-700 hover:text-white cursor-pointer"
                onClick={() => {
                  addAnimation({
                    name: "slideIn",
                    type: "slideIn",
                    targetId: selectedElement?.name ?? "",
                    duration: 1000,
                    properties: {
                      direction: "left",
                      useClipPath: false,
                      textType: "none"
                    }
                  })
                }}
              >
                Add Slide In
              </button>
            ) : null}
            {selectedElement && !hasSlideOutAnimation ? (
              <button
                className="text-sm px-[16px] py-[8px] font-semibold hover:bg-slate-700 hover:text-white cursor-pointer"
                onClick={() => {
                  addAnimation({
                    name: "slideOut",
                    type: "slideOut",
                    targetId: selectedElement?.name ?? "",
                    duration: 1000,
                    properties: {
                      direction: "right",
                      useClipPath: false,
                      textType: "none"
                    }
                  })
                }}
              >
                Add Slide Out
              </button>
            ) : null}
            {selectedElement && !hasConstantAnimation ? (
              <button
                className="text-sm px-[16px] py-[8px] font-semibold hover:bg-slate-700 hover:text-white cursor-pointer"
                onClick={() => {
                  addAnimation({
                    name: "breathe",
                    type: "breathe",
                    targetId: selectedElement?.name ?? "",
                    duration: 2000,
                    properties: {}
                  })
                }}
              >
                Add Breathe
              </button>
            ) : null}
            <div style={{ display: "flex", flexDirection: "column" }}>
              {selectedElementAnimations.map((animation, index) => (
                <div key={index}>
                  <AnimationResource animation={animation} />
                </div>
              ))}
            </div>
          </div>
        )}
        {selectedResourceType === "effect" && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {selectedEditedElement &&
              (isEditorImageElement(selectedEditedElement) ||
                isEditorVideoElement(selectedEditedElement)) ? (
              <EffectResource editorElement={selectedEditedElement} />
            ) : null}
          </div>
        )}
      </SoftBox>
    </SoftBox>
  );
};

export default MediaResourcesPanel;
