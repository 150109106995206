const Password = (value) => {
    const containSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(value);
    const containUppercase = /[A-Z]/.test(value);
    const containLowercase = /[a-z]/.test(value);
    const containNumber = /[0-9]/.test(value);

    let fulfilledConditions = 0;
    let missingConditionsMsg = "";

    if (containSpecialChar) fulfilledConditions++;
    else missingConditionsMsg += "special character ";

    if (containUppercase) fulfilledConditions++;
    else missingConditionsMsg += "uppercase letter ";

    
    if (containNumber) fulfilledConditions++;
    else missingConditionsMsg += "number ";

    if (containLowercase) fulfilledConditions++;
    else missingConditionsMsg += "lowercase letter ";

    if (value.length >= 8) fulfilledConditions++;
    else missingConditionsMsg += "and 8 characters ";

    if (fulfilledConditions === 5 && value.length >= 8) {
        return { message: "Strong Password", severity: "strong" };
    } else if (fulfilledConditions >= 1 && value.length >= 3) {
        return { message: "Moderate Password (Please include " + missingConditionsMsg.trim() + ")", severity: "moderate" };
    } else {
        return { message: "Weak Password", severity: "weak" };
    }
}

export default Password;
