"use client";
import React, { useState } from "react";
import { addTextResource } from "../Store/Store";
import { MdAdd } from "react-icons/md";
import PropTypes from "prop-types";

const TextResource = ({ fontSize, fontWeight, sampleText, id, initialFontColor }) => {
  const [fontColor, setFontColor] = useState(initialFontColor);

  const addText = () => {
    addTextResource({
      id: id,
      text: sampleText,
      fontSize: fontSize,
      fontWeight: fontWeight,
      fontColor: fontColor // Include fontColor in the resource
    });
  };

  return (
    <div
      className="px-2"
      style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}
    >
      <div
        style={{
          fontSize: `${fontSize}px`,
          fontWeight: `${fontWeight}`,
          color: fontColor // Apply the font color here
        }}
      >
        {sampleText}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="color"
          value={fontColor}
          onChange={(e) => setFontColor(e.target.value)}
          style={{ marginRight: '8px', cursor: 'pointer' }}
        />
        <MdAdd onClick={addText} style={{ cursor: 'pointer' }} />
      </div>
    </div>
  );
};

TextResource.propTypes = {
  id: PropTypes.number.isRequired,
  fontSize: PropTypes.number.isRequired,
  fontWeight: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  sampleText: PropTypes.string.isRequired,
  initialFontColor: PropTypes.string, // Rename prop to reflect its purpose
};

export default TextResource;
