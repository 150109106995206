"use client";
import React, { useState } from "react";
import { addAudioResource, useGlobalState,addUploadedAudio,getAudioDuration} from "../Store/Store";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MdAdd, MdMoreVert } from "react-icons/md";
import PropTypes from "prop-types";
import UploadButton from "./UploadButton";

const AudioResource = ({ audio, onDuplicate }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDuplicate = () => {
    onDuplicate(audio); // Call the onCopy function passed as a prop
    handleMenuClose();
  };

  const addAudio = () => {
    console.log("Added Audio", audio);
    addAudioResource(audio.type, audio.src, audio.name, audio.duration);
  };

  const handleAudioFileChange = async (event) => {
    const files = event.target.files;
    if (!files) return;
    // setSelectedResourceType("audio");
    for (const file of files) {
      const type = "audio";
      const name = file.name;
      const url = URL.createObjectURL(file);

      let duration = null;
      if (type === "audio") {
        try {
          const durationInSec = await getAudioDuration(url);
          duration = durationInSec * 1000;
        } catch (error) {
          console.error("Error getting media duration:", error);
        }
      }
      addUploadedAudio(type, url, name, duration);
    }
  };

  return (
    <div
      className="px-2"
      style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}
    >
      <div className="text-black" style={{ fontSize: "14px" }}>
        {audio.name}
      </div>

      {/* Icon for adding audio */}
      <MdAdd onClick={addAudio} style={{ cursor: "pointer" }} />

      {/* More options (three dots) */}
      <IconButton onClick={handleMenuOpen}>
        <MdMoreVert />
      </IconButton>

      {/* Context menu with the Duplicate option */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
      </Menu>
    </div>
  );
};

AudioResource.propTypes = {
  audio: PropTypes.shape({
    type: PropTypes.oneOf(['audio']).isRequired,
    src: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
  }).isRequired,
  onDuplicate: PropTypes.func.isRequired,
};

export default AudioResource;
