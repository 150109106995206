import React, { useRef, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "../../../../css/VideoEditor/video.module.css";
import { useGlobalState } from "../Store/Store";

function DragableView(props) {
  const ref = useRef({
    div: null,
    isDragging: false,
    initialMouseX: 0,
    initialValue: 0,
  });
  const { current: data } = ref;

  const store = useGlobalState()
  const {element} = props
  // console.log("elemeent",element)
  const calculateNewValue = useCallback(
    (mouseX) => {
      if (!data.div) return 0;
      // console.log("mediaStart",mouseX)
      const deltaX = mouseX - data.initialMouseX;
      // console.log("deltaX",deltaX)
      const deltaValue = (deltaX / data.div.parentElement.clientWidth) * props.total;
      store.deltaValue.set(prev => ({
        ...prev,
        [element.name]: deltaValue
      }));
      
      // console.log("deltaValue",store.deltaValue.get());
      const cal = Math.min(props.total, data.initialValue + deltaValue)
      store.calculateValue.set(cal);
      // console.log("cal",store.calculateValue.get())
      return cal; // Ensures the value is within bounds
    },
    [props.total, data.div, data.initialMouseX, data.initialValue]
  );


  const handleMouseDown = (event) => {
    if (!data.div || props.disabled) return;
    data.isDragging = true;
    // console.log("first ", data.isDragging)
    data.initialMouseX = event.clientX;
    // console.log("second",data.initialMouseX)
    data.initialValue = props.value;
    // console.log("third", data.initialValue)
  };

  const handleMouseMove = useCallback(
    (event) => {
      if (!data.div || !data.isDragging || props.disabled) return;
      const newValue = calculateNewValue(event.clientX);
      // const mediaStart = calculateMediaStart(newValue);
      // console.log("mediaStart",mediaStart)
      data.div.style.left = `${(newValue / props.total) * 100}%`;
      // console.log("div stykr",data.div.style.left)
      event.stopPropagation();
      event.preventDefault();
    },
    [calculateNewValue, data.isDragging, data.div, props.total, props.disabled]
  );

  const handleMouseUp = useCallback(
    (event) => {
      if (!data.div || !data.isDragging || props.disabled) return;
      data.isDragging = false;
      const newValue = calculateNewValue(event.clientX);
      // const mediaStart = calculateMediaStart(newValue);
      // console.log("mediaStart",mediaStart)
      props.onChange(newValue);
      event.stopPropagation();
      event.preventDefault();
    },
    [calculateNewValue, data.isDragging, data.div, props.onChange, props.disabled]
  );

  const handleTouchStart = (event) => {
    if (!data.div || props.disabled) return;
    data.isDragging = true;
    const touch = event.touches[0];
    data.initialMouseX = touch.clientX;
    data.initialValue = props.value;
  };

  const handleTouchMove = useCallback(
    (event) => {
      if (!data.div || !data.isDragging || props.disabled) return;
      const touch = event.touches[0];
      const newValue = calculateNewValue(touch.clientX);
      data.div.style.left = `${(newValue / props.total) * 100}%`;
      event.stopPropagation();
      event.preventDefault();
    },
    [calculateNewValue, data.isDragging, data.div, props.total, props.disabled]
  );

  const handleTouchEnd = useCallback(
    (event) => {
      if (!data.div || !data.isDragging || props.disabled) return;
      data.isDragging = false;
      const touch = event.changedTouches[0];
      const newValue = calculateNewValue(touch.clientX);
      props.onChange(newValue);
      event.stopPropagation();
      event.preventDefault();
    },
    [calculateNewValue, data.isDragging, data.div, props.onChange, props.disabled]
  );

  useEffect(() => {
    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [handleMouseUp, handleMouseMove]);

  useEffect(() => {
    window.addEventListener("touchend", handleTouchEnd);
    window.addEventListener("touchmove", handleTouchMove);
    return () => {
      window.removeEventListener("touchend", handleTouchEnd);
      window.removeEventListener("touchmove", handleTouchMove);
    };
  }, [handleTouchEnd, handleTouchMove]);

  return (
    <div
      ref={(r) => {
        data.div = r;
      }}
      className={styles.draggable}
      style={{
        left: `${(props.value / props.total) * 100}%`,
        top: 0,
        bottom: 0,
        ...props.style,
      }}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
    >
      {props.children}
    </div>
  );
}

DragableView.propTypes = {
  element: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  value: PropTypes.number.isRequired, // Current value of the draggable element
  total: PropTypes.number.isRequired, // Total range for the draggable element
  onChange: PropTypes.func.isRequired, // Callback function for value changes
  disabled: PropTypes.bool, // Whether the draggable element is disabled
  style: PropTypes.object, // Additional styles for the draggable element
  children: PropTypes.node, // Child components or elements
};

export default DragableView;