

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import SoftBox from "../../../../SoftBox";
import SoftTypography from "../../../../SoftTypography";
import SoftInput from "../../../../SoftInput";
import SoftButton from "../../../../SoftButton";

// Images
import macbook from "assets/images/macbook.png";

function Newsletter() {
  return (
    <SoftBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <SoftTypography variant="h4">Be the first to see the news</SoftTypography>
            <SoftTypography variant="body2" color="text" mb={3}>
              Your company may not be in the software business, but eventually, a software company
              will be in your business.
            </SoftTypography>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <SoftInput type="email" label="Email Here..." fullWidth />
              </Grid>
              <Grid item xs={4}>
                <SoftButton variant="gradient" color="info" sx={{ height: "100%" }}>
                  Subscribe
                </SoftButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <SoftBox position="relative">
              <SoftBox component="img" src={macbook} alt="macbook" width="100%" />
            </SoftBox>
          </Grid>
        </Grid>
      </Container>
    </SoftBox>
  );
}

export default Newsletter;
