import React from "react"
const Page =()=>{
    return(
        <>
         <div style={{transition: '12s'}}>
            Next
         </div>
        </>
    )
}

export default Page