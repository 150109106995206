
"use client";
import React from "react";
import SeekPlayer from "./SeekPlayer";
import { useGlobalState,handleSeek } from "../Store/Store";
import TimeFrameView from "./TimeFrameView";
import styles from "../../../../css/VideoEditor/video.module.css";
import PropTypes from "prop-types";
import ScaleRangeInput from "./ScaleRangeInput";

const MARKINGS = [
  {
    interval: 5000,
    color: "black",
    size: 16,
    width: 1,
  },
  {
    interval: 1000,
    color: "black",
    size: 8,
    width: 1,
  },
];


const TimeLine = () => {
  const store = useGlobalState();
  const percentOfCurrentTime = (store.currentTimeInMs.get() / store.maxTime.get()) * 100;
  const scene = store.sceneElements.get()


  return (
    <>
      <SeekPlayer />
    <div className={styles.timeline}>
      <ScaleRangeInput
        max={store.maxTime.get()}
        value={store.currentTimeInMs.get()}
        onChange={handleSeek}
        height={30}
        markings={MARKINGS}
        backgroundColor="transparent"
      />
      <div className={styles.timelineWrapper}>
        {!scene || scene.length === 0 ? (
          <div></div> // or loading indicator
        ) : (
          scene.map((element) => (
            // element.name === sceneName ? (
            <TimeFrameView key={element.name} 
            element={element} 
            />
          // ):null
          ))
        )}
      </div>
      {/* <div
        className={styles.timeIndicator}
        style={{
          left: `${percentOfCurrentTime}%`,
          // transform: `translateX(${percentOfCurrentTime}%) translateX(2px)`,
        }}
      /> */}
    </div>
    </>
  );
};

TimeLine.propTypes = {
  scene: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      // Define other properties of the scene element here
    })
  ),
};

export default TimeLine;
