// src/HealthCheck.js
import React from 'react';

const Health = () => {
  return (
    <div>
      OK
    </div>
  );
};

export default Health;
