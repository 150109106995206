/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import SoftBox from "components/SoftBox";

import Footer from '../../Landing_Page/Footer'
import Nav from '../../Landing_Page/Nav'

// Author page sections
import Profile from "./sections/Profile";
import Posts from "./sections/Posts";
import Contact from "../Contact";

// Images
import bgImage from "assets/images/city-profile.jpg";
import { ThemeProvider } from "@mui/material/styles";
import theme from "assets/theme";
import CssBaseline from "@mui/material/CssBaseline";

function Author() {
  return (
    <>
    <ThemeProvider theme={theme}>
    <CssBaseline />
      <Nav link_color={'black'} border_color={'var(--text-400-color)'}/>
      <SoftBox bgColor="white">
        <SoftBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Profile />
          <Posts />
        </Card>
        <Contact />
        <Footer />
      </SoftBox>
      </ThemeProvider>
    </>
  );
}

export default Author;
