// // import { RxCross2 } from "react-icons/rx";
// // import { IoLogoInstagram, IoLogoTwitter } from "react-icons/io";
// // import { AiOutlineYoutube } from "react-icons/ai";

// // import React, { useState, useEffect } from "react";
// // import { useNavigate } from "react-router-dom";
// // import Select from '@mui/joy/Select';
// // import Option from '@mui/joy/Option';
// // import { Link } from "react-router-dom";

// // import dStyle from '../../../css/ProfilePage/Details.module.css';
// // import '../../../css/theme.css';

// // import CustomStepper from "./Stepper";

// // import data from '../../../assets/Json/countries.json';
// // import content from '../../../assets/Json/content.json';

// // import api from "../../../backend_api";

// // const steps = ['Personal Details', 'Content Details', 'Social Details'];
// // import { CircularProgress } from '@mui/material';

// // const Details = () => {

// //     const navigate = useNavigate();
// //     const [loading, setLoading] = useState(false)
// //     const [activeForm, setActiveForm] = useState(1);

// //     const [selectedCountry, setSelectedCountry] = useState("");
// //     const [selectedState, setSelectedState] = useState("");

// //     const selectedCountryData = data.find(country => country.name === selectedCountry);
// //     const statesForSelectedCountry = selectedCountryData ? selectedCountryData.states : [];

// //     const [keywords, setKeywords] = useState([]);
// //     const [inputValue, setInputValue] = useState("");

// //     const [contents, setContents] = useState("");

// //     const [personalDetails, setPersonalDetails] = useState({
// //         fullname: '',
// //         country: selectedCountry,
// //         state: selectedState,
// //         date_of_birth: '',
// //         gender: '',
// //         contact: ''
// //     });

// //     const [contentDetails, setContentDetails] = useState({
// //         niche: contents,
// //         keywords: keywords,
// //         brief: ''
// //     });

// //     useEffect(() => {
// //         setPersonalDetails(prevState => ({
// //             ...prevState,
// //             country: selectedCountry,
// //             state: selectedState
// //         }));
// //     }, [selectedCountry, selectedState]);

// //     useEffect(() => {
// //         setContentDetails(prevState => ({
// //             ...prevState,
// //             niche: contents,
// //             keywords: keywords
// //         }));
// //     }, [contents, keywords]);

// //     const handleNext = (e) => {
// //         e.preventDefault();
// //         console.log(personalDetails);
// //         console.log(contentDetails);
// //         setActiveForm(activeForm + 1);
// //     }

// //     const handleBack = (e) => {
// //         e.preventDefault();
// //         setActiveForm(activeForm - 1);
// //     }

// //     const handleContent = (e) => {
// //         if (e && e.target) {
// //             setContents(e.target.textContent);
// //         }
// //     };

// //     const handleCountryChange = (e, newValue) => {
// //         if (newValue) {
// //             setSelectedCountry(newValue);
// //             setSelectedState(""); // Reset state selection when country changes
// //         }
// //     };

// //     const handleStateChange = (e, newValue) => {
// //         if (newValue) {
// //             setSelectedState(newValue);
// //         }
// //     };

// //     const handleInputValue = (event) => {
// //         setInputValue(event.target.value);
// //     };

// //     const handleKey = (event) => {
// //         if (event.key === 'Enter' || event.key === ',') {
// //             event.preventDefault(); // Prevent form submission or comma insertion
// //             addKeyword();
// //         }
// //     };

// //     const addKeyword = () => {
// //         const newKeyword = inputValue.trim();
// //         if (newKeyword !== '' && !keywords.includes(newKeyword)) {
// //             setKeywords([...keywords, newKeyword]);
// //             setInputValue(""); // Clear the input field after adding keyword
// //         }
// //     };

// //     const removeKeyword = (keyword) => {
// //         const updatedKeywords = keywords.filter(kw => kw !== keyword);
// //         setKeywords(updatedKeywords);
// //     };

// //     const handlepersonalDataChange = (e) => {
// //         e.preventDefault();
// //         const { name, value } = e.target;
// //         setPersonalDetails(prevState => ({
// //             ...prevState,
// //             [name]: value
// //         }));
// //     };

// //     const handlecontentDataChange = (e) => {
// //         e.preventDefault();
// //         const { name, value } = e.target;
// //         setContentDetails(prevState => ({
// //             ...prevState,
// //             [name]: value
// //         }));
// //     };

// //     const handleSubmit = async (e) => {
// //         e.preventDefault();
// //         setLoading(false)
// //         // Combine the personalDetails and contentDetails into one object
// //         const combinedData = {
// //             ...personalDetails,
// //             ...contentDetails,
// //             keywords: keywords
// //         };
// //         console.log(combinedData);
// //         const route = "/api/profile-data/create/";

// //         try {
// //             const res = await api.post(route, combinedData);
// //             console.log("created profile data");
// //             setActiveForm(activeForm + 1);
// //             setTimeout(() => {
// //                 navigate('/creator/EasyFind')
// //             }, 200);

// //         }
// //         catch (error) {
// //             // console.log(error.response.data);
// //             const message = error.response.data;
// //             console.log(message['detail']);
// //             // setLerror(message['detail'])
// //         } finally {
// //             setLoading(false)
// //         }
// //     }

// //     const renderForm = (formNumber) => {
// //         switch (formNumber) {
// //             case 1:
// //                 return (
// //                     <>
// //                         <div className={dStyle.form2 + ' ' + dStyle['details']}  >
// //                             <div className={dStyle['page-input-field']}>
// //                                 <label>Full Name</label>
// //                                 <input type="text" name="fullname" value={personalDetails.fullName} onChange={handlepersonalDataChange} placeholder="Full Name" className={dStyle['in-put']} />
// //                             </div>
// //                             <div className={dStyle['page-input-field']}>
// //                                 <div className={dStyle.address}>
// //                                     <div className={dStyle['address-field']}>
// //                                         <label id="countryId">Country</label>
// //                                         <Select
// //                                             slotProps={{
// //                                                 listbox: {
// //                                                     sx: {
// //                                                         minHeight: 'auto',
// //                                                         overflow: 'auto',
// //                                                         minWidth: '60px',
// //                                                         fontSize: '12px',
// //                                                         textWrap: 'pretty',
// //                                                     },
// //                                                 },
// //                                             }}
// //                                             className={dStyle['content-select']}
// //                                             name='country'
// //                                             value={selectedCountry}
// //                                             placeholder="Select Country"
// //                                             onChange={handleCountryChange} >
// //                                             {data.map((country) => (
// //                                                 <Option className={dStyle.option}
// //                                                     key={country.name} value={country.name}>
// //                                                     {country.name}
// //                                                 </Option>
// //                                             ))}
// //                                         </Select>
// //                                     </div>
// //                                     <div className={dStyle['address-field']}>
// //                                         <label>State</label>
// //                                         <Select
// //                                             slotProps={{
// //                                                 listbox: {
// //                                                     sx: {
// //                                                         minHeight: 'auto',
// //                                                         overflow: 'auto',
// //                                                         minWidth: '60px',
// //                                                         fontSize: '12px',
// //                                                         textWrap: 'pretty',
// //                                                     },
// //                                                 },
// //                                             }}
// //                                             className={dStyle['content-select']}
// //                                             name='state'
// //                                             value={selectedState}
// //                                             placeholder="Select State"
// //                                             onChange={handleStateChange} >
// //                                             {statesForSelectedCountry.map(state => (
// //                                                 <Option className={dStyle['option']} key={state.code} value={state.name}>{state.name}</Option>
// //                                             ))}
// //                                             <Option value="Other">Other</Option>
// //                                         </Select>
// //                                     </div>
// //                                 </div>
// //                             </div>
// //                             <div className={dStyle['page-input-field']} >
// //                                 <label>DOB</label>
// //                                 <input type="date" name="date_of_birth" value={personalDetails.dob} onChange={handlepersonalDataChange} placeholder=" DOB" className={dStyle['in-put']} />
// //                             </div>
// //                             <div className={dStyle['page-input-field']}>
// //                                 <label >Gender</label>
// //                                 <input type="text" name="gender" value={personalDetails.gender} onChange={handlepersonalDataChange} className={dStyle['in-put']} placeholder="Gender" />
// //                             </div>
// //                             <div className={dStyle['page-input-field']}>
// //                                 <label >Contact</label>
// //                                 <input type="tel" name="contact" value={personalDetails.contact} onChange={handlepersonalDataChange} className={dStyle['in-put']} placeholder="Contact" />
// //                             </div>

// //                         </div>
// //                         <div className={dStyle['page-btn']}>
// //                             <button onClick={handleNext} className={dStyle['navigate-btn']} style={{ background: 'var(--ten-color)', color: 'var(--white-color)' }}>Next</button>
// //                         </div>
// //                     </>
// //                 );
// //             case 2:
// //                 return (
// //                     <>
// //                         <div className={dStyle.form2 + ' ' + dStyle['details']} >
// //                             <div className={dStyle['page-input-field']}>
// //                                 <label>Type of Content</label>
// //                                 <Select placeholder="Select Content" className={dStyle['content-select']} name='niche' value={contents} onChange={handleContent} >
// //                                     {content.map(content => (
// //                                         <Option
// //                                             className={dStyle['option']} key={content.name} value={content.name}>{content.name}</Option>
// //                                     ))}
// //                                     <Option
// //                                         className={dStyle['option']} value="">Other</Option>
// //                                 </Select>
// //                             </div>
// //                             <div className={dStyle['page-input-field']}>
// //                                 <label>Keywords</label>
// //                                 <input type="textarea" placeholder="" className={dStyle['in-put']} name="keywords" value={inputValue} onChange={handleInputValue} onKeyDown={handleKey} />
// //                                 <div className={dStyle.inputData} style={{ display: 'flex', flexWrap: 'wrap' }}>
// //                                     {keywords.map((keyword, index) => (
// //                                         <div className={dStyle.data} key={index} >
// //                                             {keyword}
// //                                             <RxCross2 onClick={() => removeKeyword(keyword)} size={12} style={{ marginLeft: '2px' }} />
// //                                         </div>
// //                                     ))}
// //                                 </div>

// //                             </div>
// //                             <div className={dStyle['page-input-field']}>
// //                                 <label>Brief</label>
// //                                 <input type="textarea" name="brief" value={contentDetails.brief} onChange={handlecontentDataChange} placeholder="Tell in brief about your content" className={dStyle['in-put']} />
// //                             </div>
// //                         </div >
// //                         <div className={dStyle['page-btn']}>
// //                             <button className={dStyle['navigate-btn']} onClick={handleBack} style={{ background: 'var(--white-color)', color: 'var(--ten-color)' }}>Back</button>
// //                             <button onClick={handleNext} className={dStyle['navigate-btn']} style={{ background: 'var(--ten-color)', color: 'var(--white-color)' }}>Next</button>
// //                         </div>
// //                     </>
// //                 );
// //             case 3:
// //                 return (
// //                     <>
// //                         <div className={dStyle.form2 + ' ' + dStyle['details']} >
// //                             <button className={dStyle['social-connect']} style={{ background: 'linear-gradient(to top right,#f9ce34 , #ee2a7b, #6228d7 )' }} ><IoLogoInstagram size={24} style={{ verticalAlign: 'middle' }} /><Link to="/iglogin" target='_blank' className={dStyle.link}>Instagram</Link></button>
// //                             <button className={dStyle['social-connect']} disabled style={{ background: 'linear-gradient(to top right,#ff0000, #d70000 )' }}><AiOutlineYoutube size={24} style={{ verticalAlign: 'middle' }} />YouTube</button>
// //                             <button className={dStyle['social-connect']} disabled style={{ background: '#1DA1F2' }}><IoLogoTwitter size={24} style={{ verticalAlign: 'middle' }} />Twitter</button>
// //                         </div>
// //                         <div className={dStyle['page-btn']}>
// //                             <button className={dStyle['navigate-btn']} onClick={handleBack} style={{ background: 'var(--white-color)', color: 'var(--ten-color)' }}>Back</button>
// //                             <button type="submit" className={dStyle['navigate-btn']} style={{ background: 'var(--ten-color)', color: 'var(--white-color)' }} disabled={loading}>
// //                                 {loading ? <CircularProgress size={16} sx={{ color: "white" }} /> : "Submit"}
// //                             </button>
// //                         </div>
// //                     </>
// //                 );

// //             default:
// //                 return null;
// //         }
// //     };

// //     return (

// //         <div className={dStyle['page-body']}>
// //             <div className={dStyle['page-wrapper']}>
// //                 <form className={dStyle.main} onSubmit={handleSubmit}>
// //                     <CustomStepper activeStep={activeForm} steps={steps} next={handleNext} />
// //                     {renderForm(activeForm)}
// //                 </form>
// //             </div>
// //         </div>

// //     );
// // };

// // export default Details;


// import { RxCross2 } from "react-icons/rx";
// import { IoLogoInstagram, IoLogoTwitter } from "react-icons/io";
// import { AiOutlineYoutube } from "react-icons/ai";

// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import Select from '@mui/joy/Select';
// import Option from '@mui/joy/Option';
// import { Link } from "react-router-dom";

// import dStyle from '../../../css/ProfilePage/Details.module.css';
// import '../../../css/theme.css';

// import CustomStepper from "./Stepper";

// import data from '../../../assets/Json/countries.json';
// import content from '../../../assets/Json/content.json';

// import api from "../../../backend_api";
// import { CircularProgress } from '@mui/material';

// const steps = ['Personal Details', 'Content Details', 'Social Details'];

// const Details = () => {

//     const navigate = useNavigate();

//     const [activeForm, setActiveForm] = useState(1);

//     const [selectedCountry, setSelectedCountry] = useState("");
//     const [selectedState, setSelectedState] = useState("");

//     const selectedCountryData = data.find(country => country.name === selectedCountry);
//     const statesForSelectedCountry = selectedCountryData ? selectedCountryData.states : [];

//     const [keywords, setKeywords] = useState("");
//     const [inputValue, setInputValue] = useState("");

//     const [contents, setContents] = useState("");

//     const [personalDetails, setPersonalDetails] = useState({
//         fullName: '',
//         country: selectedCountry,
//         state: selectedState,
//         dob: '',
//         gender: '',
//         contact: ''
//     });

//     const [contentDetails, setContentDetails] = useState({
//         content: contents,
//         keywords: keywords,
//         brief: ''
//     });

//     useEffect(() => {
//         setPersonalDetails(prevState => ({
//             ...prevState,
//             country: selectedCountry,
//             state: selectedState
//         }));
//     }, [selectedCountry, selectedState]);

//     useEffect(() => {
//         setContentDetails(prevState => ({
//             ...prevState,
//             content: contents,
//             keywords: keywords
//         }));
//     }, [contents, keywords]);

//     const handleNext = (e) => {
//         e.preventDefault();
//         console.log(personalDetails);
//         console.log(contentDetails);
//         setActiveForm(activeForm + 1);
//     }

//     const handleBack = (e) => {
//         e.preventDefault();
//         setActiveForm(activeForm - 1);
//     }

//     const handleContent = (e, newValue) => {
//         if (newValue) {
//             setContents(newValue);
//         }
//     };

//     const handleCountryChange = (e, newValue) => {
//         if (newValue) {
//             setSelectedCountry(newValue);
//             setSelectedState(""); // Reset state selection when country changes
//         }
//     };

//     const handleStateChange = (e, newValue) => {
//         if (newValue) {
//             setSelectedState(newValue);
//         }
//     };

//     const handleInputValue = (event) => {
//         setInputValue(event.target.value);
//     };

//     const handleKey = (event) => {
//         if (event.key === 'Enter' || event.key === ',') {
//             event.preventDefault(); // Prevent form submission or comma insertion
//             addKeyword();
//         }
//     };

//     const addKeyword = () => {
//         const newKeyword = inputValue.trim();
//         if (newKeyword !== '') {
//             // Check if the keyword already exists in the string
//             const keywordArray = keywords.split(',').map(keyword => keyword.trim());
//             if (!keywordArray.includes(newKeyword)) {
//                 // Add the new keyword to the existing keywords
//                 const updatedKeywords = keywords ? `${keywords}, ${newKeyword}` : newKeyword;
//                 setKeywords(updatedKeywords);
//                 setInputValue(""); // Clear the input field after adding keyword
//             }
//         }
    
//     };

//     const removeKeyword = (keyword) => {
//         const updatedKeywordsArray = keywords.split(',').map(kw => kw.trim()).filter(kw => kw !== keyword);
//         const updatedKeywords = updatedKeywordsArray.join(', ');
//         setKeywords(updatedKeywords);
//     };

//     const handlepersonalDataChange = (e) => {
//         e.preventDefault();
//         const { name, value } = e.target;
//         setPersonalDetails(prevState => ({
//             ...prevState,
//             [name]: value
//         }));
//     };

//     const handlecontentDataChange = (e) => {
//         e.preventDefault();
//         const { name, value } = e.target;
//         setContentDetails(prevState => ({
//             ...prevState,
//             [name]: value
//         }));
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
// <<<<<<< HEAD
// =======
//         setLoading(true)
// >>>>>>> fcda3e02c01f9fecfda84c8e1a68d522a78297a5
//         // Combine the personalDetails and contentDetails into one object
//         const combinedData = {
//             fullname: personalDetails.fullName,
//             country: personalDetails.country,
//             state: personalDetails.state,
//             date_of_birth: personalDetails.dob,
//             gender: personalDetails.gender,
//             contact: parseInt(personalDetails.contact, 10),  
//             niche: contentDetails.content,
//             keywords: keywords,
//             brief: contentDetails.brief,
//         };
//         console.log(combinedData);
//         const route = "/api/profile-data/create";

//         try {
//             const res = await api.post(route, combinedData);
//             console.log("created profile data");
//             setActiveForm(activeForm + 1);
//             setTimeout(() => {
//                 navigate('/')
//             }, 100);

//         }
//         catch (error) {
//             // console.log(error.response.data);
//             const message = error.response.data;
//             console.log(message['detail']);
//             // setLerror(message['detail'])
// <<<<<<< HEAD
// =======
//         } finally {
//             setLoading(false)
// >>>>>>> fcda3e02c01f9fecfda84c8e1a68d522a78297a5
//         }
//     }

//     const renderForm = (formNumber) => {
//         switch (formNumber) {
//             case 1:
//                 return (
//                     <>
//                         <div className={dStyle.form2 + ' ' + dStyle['details']}  >
//                             <div className={dStyle['page-input-field']}>
//                                 <label>Full Name</label>
//                                 <input type="text" name="fullName" value={personalDetails.fullName} onChange={handlepersonalDataChange} placeholder="Full Name" className={dStyle['in-put']} />
//                             </div>
//                             <div className={dStyle['page-input-field']}>
//                                 <div className={dStyle.address}>
//                                     <div className={dStyle['address-field']}>
//                                         <label id="countryId">Country</label>
//                                         <Select
//                                             slotProps={{
//                                                 listbox: {
//                                                     sx: {
//                                                         minHeight: 'auto',
//                                                         overflow: 'auto',
//                                                         minWidth: '60px',
//                                                         fontSize: '12px',
//                                                         textWrap: 'pretty',
//                                                     },
//                                                 },
//                                             }}
//                                             className={dStyle['content-select']}
//                                             name='country'
//                                             value={selectedCountry}
//                                             placeholder="Select Country"
//                                             onChange={handleCountryChange} >
//                                             {data.map((country) => (
//                                                 <Option className={dStyle.option}
//                                                     key={country.name} value={country.name}>
//                                                     {country.name}
//                                                 </Option>
//                                             ))}
//                                         </Select>
//                                     </div>
//                                     <div className={dStyle['address-field']}>
//                                         <label>State</label>
//                                         <Select
//                                             slotProps={{
//                                                 listbox: {
//                                                     sx: {
//                                                         minHeight: 'auto',
//                                                         overflow: 'auto',
//                                                         minWidth: '60px',
//                                                         fontSize: '12px',
//                                                         textWrap: 'pretty',
//                                                     },
//                                                 },
//                                             }}
//                                             className={dStyle['content-select']}
//                                             name='state'
//                                             value={selectedState}
//                                             placeholder="Select State"
//                                             onChange={handleStateChange} >
//                                             {statesForSelectedCountry.map(state => (
//                                                 <Option className={dStyle['option']} key={state.code} value={state.name}>{state.name}</Option>
//                                             ))}
//                                             <Option value="Other">Other</Option>
//                                         </Select>
//                                     </div>
//                                 </div>
//                             </div>
//                             <div className={dStyle['page-input-field']} >
//                                 <label>DOB</label>
//                                 <input type="date" name="dob" value={personalDetails.dob} onChange={handlepersonalDataChange} placeholder=" DOB" className={dStyle['in-put']} />
//                             </div>
//                             <div className={dStyle['page-input-field']}>
//                                 <label >Gender</label>
//                                 <input type="text" name="gender" value={personalDetails.gender} onChange={handlepersonalDataChange} className={dStyle['in-put']} placeholder="Gender" />
//                             </div>
//                             <div className={dStyle['page-input-field']}>
//                                 <label >Contact</label>
//                                 <input type="tel" name="contact" value={personalDetails.contact} onChange={handlepersonalDataChange} className={dStyle['in-put']} placeholder="Contact" />
//                             </div>

//                         </div>
//                         <div className={dStyle['page-btn']}>
//                             <button onClick={handleNext} className={dStyle['navigate-btn']} style={{ background: 'var(--ten-color)', color: 'var(--white-color)' }}>Next</button>
//                         </div>
//                     </>
//                 );
//             case 2:
//                 return (
//                     <>
//                         <div className={dStyle.form2 + ' ' + dStyle['details']} >
//                             <div className={dStyle['page-input-field']}>
//                                 <label>Type of Content</label>
//                                 <Select placeholder="Select Content" className={dStyle['content-select']} name='content' value={contents} onChange={handleContent} >
//                                     {content.map(content => (
//                                         <Option
//                                             className={dStyle['option']} key={content.name} value={content.name}>{content.name}</Option>
//                                     ))}
//                                     <Option
//                                         className={dStyle['option']} value="">Other</Option>
//                                 </Select>
//                             </div>
//                             <div className={dStyle['page-input-field']}>
//                                 <label>Keywords</label>
//                                 <input type="textarea" placeholder="" className={dStyle['in-put']} name="keywords" value={inputValue} onChange={handleInputValue} onKeyDown={handleKey} />
//                                 <div className={dStyle.inputData} style={{ display: 'flex', flexWrap: 'wrap' }}>
//                                 {keywords.split(',').filter(keyword => keyword.trim() !== '').map((keyword, index) => (
//                                         <div className={dStyle.data} key={index} >
//                                             {keyword.trim()}
//                                             <RxCross2 onClick={() => removeKeyword(keyword.trim())} size={12} style={{ marginLeft: '2px' }} />
//                                         </div>
//                                     ))}
//                                 </div>

//                             </div>
//                             <div className={dStyle['page-input-field']}>
//                                 <label>Brief</label>
//                                 <input type="textarea" name="brief" value={contentDetails.brief} onChange={handlecontentDataChange} placeholder="Tell in brief about your content" className={dStyle['in-put']} />
//                             </div>
//                         </div >
//                         <div className={dStyle['page-btn']}>
//                             <button className={dStyle['navigate-btn']} onClick={handleBack} style={{ background: 'var(--white-color)', color: 'var(--ten-color)' }}>Back</button>
//                             <button onClick={handleNext} className={dStyle['navigate-btn']} style={{ background: 'var(--ten-color)', color: 'var(--white-color)' }}>Next</button>
//                         </div>
//                     </>
//                 );
//             case 3:
//                 return (
//                     <>
//                         <div className={dStyle.form2 + ' ' + dStyle['details']} >
//                             <button className={dStyle['social-connect']} style={{ background: 'linear-gradient(to top right,#f9ce34 , #ee2a7b, #6228d7 )' }} ><IoLogoInstagram size={24} style={{ verticalAlign: 'middle' }} /><Link to="/iglogin" target='_blank' className={dStyle.link}>Instagram</Link></button>
//                             <button className={dStyle['social-connect']} disabled style={{ background: 'linear-gradient(to top right,#ff0000, #d70000 )' }}><AiOutlineYoutube size={24} style={{ verticalAlign: 'middle' }} />YouTube</button>
//                             <button className={dStyle['social-connect']} disabled style={{ background: '#1DA1F2' }}><IoLogoTwitter size={24} style={{ verticalAlign: 'middle' }} />Twitter</button>
//                         </div>
//                         <div className={dStyle['page-btn']}>
//                             <button className={dStyle['navigate-btn']} onClick={handleBack} style={{ background: 'var(--white-color)', color: 'var(--ten-color)' }}>Back</button>
//                             <button type="submit" className={dStyle['navigate-btn']} style={{ background: 'var(--ten-color)', color: 'var(--white-color)' }}>Submit</button>
//                         </div>
//                     </>
//                 );

//             default:
//                 return null;
//         }
//     };

//     return (

//         <div className={dStyle['page-body']}>
//             <div className={dStyle['page-wrapper']}>
//                 <form className={dStyle.main} onSubmit={handleSubmit}>
//                     <CustomStepper activeStep={activeForm} steps={steps} next={handleNext} />
//                     {renderForm(activeForm)}
//                 </form>
//             </div>
//         </div>

//     );
// };

// export default Details;

import { RxCross2 } from "react-icons/rx";
import { IoLogoInstagram, IoLogoTwitter } from "react-icons/io";
import { AiOutlineYoutube } from "react-icons/ai";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { Link } from "react-router-dom";

import dStyle from '../../../css/ProfilePage/Details.module.css';
import '../../../css/theme.css';

import CustomStepper from "./Stepper";

import data from '../../../assets/Json/countries.json';
import content from '../../../assets/Json/content.json';

import api from "../../../backend_api";
import { CircularProgress } from '@mui/material';

// const steps = ['Personal Details', 'Content Details', 'Social Details'];
const steps = ['Personal Details', 'Content Details'];

const Details = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [activeForm, setActiveForm] = useState(1);

    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedState, setSelectedState] = useState("");

    const selectedCountryData = data.find(country => country.name === selectedCountry);
    const statesForSelectedCountry = selectedCountryData ? selectedCountryData.states : [];

    const [keywords, setKeywords] = useState("");
    const [inputValue, setInputValue] = useState("");

    const [contents, setContents] = useState("");

    const [personalDetails, setPersonalDetails] = useState({
        fullName: '',
        country: selectedCountry,
        state: selectedState,
        dob: '',
        gender: '',
        contact: ''
    });

    const [contentDetails, setContentDetails] = useState({
        content: contents,
        keywords: keywords,
        brief: ''
    });

    useEffect(() => {
        setPersonalDetails(prevState => ({
            ...prevState,
            country: selectedCountry,
            state: selectedState
        }));
    }, [selectedCountry, selectedState]);

    useEffect(() => {
        setContentDetails(prevState => ({
            ...prevState,
            content: contents,
            keywords: keywords
        }));
    }, [contents, keywords]);

    const handleNext = (e) => {
        e.preventDefault();
        console.log(personalDetails);
        console.log(contentDetails);
        setActiveForm(activeForm + 1);
    }

    const handleBack = (e) => {
        e.preventDefault();
        setActiveForm(activeForm - 1);
    }

    const handleContent = (e) => {
        if (e && e.target) {
            setContents(e.target.textContent);
        }
    };

    const handleCountryChange = (e, newValue) => {
        if (newValue) {
            setSelectedCountry(newValue);
            setSelectedState(""); // Reset state selection when country changes
        }
    };

    const handleStateChange = (e, newValue) => {
        if (newValue) {
            setSelectedState(newValue);
        }
    };

    const handleInputValue = (event) => {
        setInputValue(event.target.value);
    };

    const handleKey = (event) => {
        if (event.key === 'Enter' || event.key === ',') {
            event.preventDefault(); // Prevent form submission or comma insertion
            addKeyword();
        }
    };

    const addKeyword = () => {
        const newKeyword = inputValue.trim();
        if (newKeyword !== '') {
            // Check if the keyword already exists in the string
            const keywordArray = keywords.split(',').map(keyword => keyword.trim());
            if (!keywordArray.includes(newKeyword)) {
                // Add the new keyword to the existing keywords
                const updatedKeywords = keywords ? `${keywords}, ${newKeyword}` : newKeyword;
                setKeywords(updatedKeywords);
                setInputValue(""); // Clear the input field after adding keyword
            }
        }
    
    };

    const removeKeyword = (keyword) => {
        const updatedKeywordsArray = keywords.split(',').map(kw => kw.trim()).filter(kw => kw !== keyword);
        const updatedKeywords = updatedKeywordsArray.join(', ');
        setKeywords(updatedKeywords);
    };

    const handlepersonalDataChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setPersonalDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handlecontentDataChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        setContentDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        // Combine the personalDetails and contentDetails into one object
        const combinedData = {
            fullname: personalDetails.fullName,
            country: personalDetails.country,
            state: personalDetails.state,
            date_of_birth: personalDetails.dob,
            gender: personalDetails.gender,
            contact: parseInt(personalDetails.contact, 10),  
            niche: contentDetails.content,
            keywords: keywords,
            brief: contentDetails.brief,
        };
        console.log(combinedData);
        const route = "/api/profile-data/create/";

        try {
            const res = await api.post(route, combinedData);
            console.log("created profile data");
            setActiveForm(activeForm + 1);
            setTimeout(() => {
                navigate('/creator/EasyFind')
            }, 100);

        }
        catch (error) {
            // console.log(error.response.data);
            const message = error.response.data;
            console.log(message['detail']);
            // setLerror(message['detail'])
        } finally {
            setLoading(false)
        }
    }

    const renderForm = (formNumber) => {
        switch (formNumber) {
            case 1:
                return (
                    <>
                        <div className={dStyle.form2 + ' ' + dStyle['details']}  >
                            <div className={dStyle['page-input-field']}>
                                <label>Full Name</label>
                                <input type="text" name="fullName" value={personalDetails.fullName} onChange={handlepersonalDataChange} placeholder="Full Name" className={dStyle['in-put']} />
                            </div>
                            <div className={dStyle['page-input-field']}>
                                <div className={dStyle.address}>
                                    <div className={dStyle['address-field']}>
                                        <label id="countryId">Country</label>
                                        <Select
                                            slotProps={{
                                                listbox: {
                                                    sx: {
                                                        minHeight: 'auto',
                                                        overflow: 'auto',
                                                        minWidth: '60px',
                                                        fontSize: '12px',
                                                        textWrap: 'pretty',
                                                    },
                                                },
                                            }}
                                            className={dStyle['content-select']}
                                            name='country'
                                            value={selectedCountry}
                                            placeholder="Select Country"
                                            onChange={handleCountryChange} >
                                            {data.map((country) => (
                                                <Option className={dStyle.option}
                                                    key={country.name} value={country.name}>
                                                    {country.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className={dStyle['address-field']}>
                                        <label>State</label>
                                        <Select
                                            slotProps={{
                                                listbox: {
                                                    sx: {
                                                        minHeight: 'auto',
                                                        overflow: 'auto',
                                                        minWidth: '60px',
                                                        fontSize: '12px',
                                                        textWrap: 'pretty',
                                                    },
                                                },
                                            }}
                                            className={dStyle['content-select']}
                                            name='state'
                                            value={selectedState}
                                            placeholder="Select State"
                                            onChange={handleStateChange} >
                                            {statesForSelectedCountry.map(state => (
                                                <Option className={dStyle['option']} key={state.code} value={state.name}>{state.name}</Option>
                                            ))}
                                            <Option value="Other">Other</Option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                            <div className={dStyle['page-input-field']} >
                                <label>DOB</label>
                                <input type="date" name="dob" value={personalDetails.dob} onChange={handlepersonalDataChange} placeholder=" DOB" className={dStyle['in-put']} />
                            </div>
                            <div className={dStyle['page-input-field']}>
                                <label >Gender</label>
                                <input type="text" name="gender" value={personalDetails.gender} onChange={handlepersonalDataChange} className={dStyle['in-put']} placeholder="Gender" />
                            </div>
                            <div className={dStyle['page-input-field']}>
                                <label >Contact</label>
                                <input type="tel" name="contact" value={personalDetails.contact} onChange={handlepersonalDataChange} className={dStyle['in-put']} placeholder="Contact" />
                            </div>

                        </div>
                        <div className={dStyle['page-btn']}>
                            <button onClick={handleNext} className={dStyle['navigate-btn']} style={{ background: 'var(--ten-color)', color: 'var(--white-color)' }}>Next</button>
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <div className={dStyle.form2 + ' ' + dStyle['details']} >
                            <div className={dStyle['page-input-field']}>
                                <label>Type of Content</label>
                                <Select placeholder="Select Content" className={dStyle['content-select']} name='content' value={contents} onChange={handleContent} >
                                    {content.map(content => (
                                        <Option
                                            className={dStyle['option']} key={content.name} value={content.name}>{content.name}</Option>
                                    ))}
                                    <Option
                                        className={dStyle['option']} value="">Other</Option>
                                </Select>
                            </div>
                            <div className={dStyle['page-input-field']}>
                                <label>Competition</label>
                                <input type="textarea" placeholder="Enter ig usernames of your competition" className={dStyle['in-put']} name="keywords" value={inputValue} onChange={handleInputValue} onKeyDown={handleKey} />
                                <div className={dStyle.inputData} style={{ display: 'flex', flexWrap: 'wrap' }}>
                                {keywords.split(',').filter(keyword => keyword.trim() !== '').map((keyword, index) => (
                                        <div className={dStyle.data} key={index} >
                                            {keyword.trim()}
                                            <RxCross2 onClick={() => removeKeyword(keyword.trim())} size={12} style={{ marginLeft: '2px' }} />
                                        </div>
                                    ))}
                                </div>

                            </div>
                            <div className={dStyle['page-input-field']}>
                                <label>Brief</label>
                                <input type="textarea" name="brief" value={contentDetails.brief} onChange={handlecontentDataChange} placeholder="Tell in brief about your content. We'll help you to improve it ;)" className={dStyle['in-put']} />
                            </div>
                        </div >
                        <div className={dStyle['page-btn']}>
                            <button className={dStyle['navigate-btn']} onClick={handleBack} style={{ background: 'var(--white-color)', color: 'var(--ten-color)' }}>Back</button>
                            {/* <button onClick={handleNext} className={dStyle['navigate-btn']} style={{ background: 'var(--ten-color)', color: 'var(--white-color)' }}>Next</button> */}
                            <button type="submit" className={dStyle['navigate-btn']} style={{ background: 'var(--ten-color)', color: 'var(--white-color)' }} disabled={loading}>
                                 {loading ? <CircularProgress size={16} sx={{ color: "white" }} /> : "Submit"}
                             </button>
                        </div>
                    </>
                );
            // case 3:
            //     return (
            //         <>
            //             <div className={dStyle.form2 + ' ' + dStyle['details']} >
            //                 <button className={dStyle['social-connect']} style={{ background: 'linear-gradient(to top right,#f9ce34 , #ee2a7b, #6228d7 )' }} ><IoLogoInstagram size={24} style={{ verticalAlign: 'middle' }} /><Link to="/iglogin" target='_blank' className={dStyle.link}>Instagram</Link></button>
            //                 <button className={dStyle['social-connect']} disabled style={{ background: 'linear-gradient(to top right,#ff0000, #d70000 )' }}><AiOutlineYoutube size={24} style={{ verticalAlign: 'middle' }} />YouTube</button>
            //                 <button className={dStyle['social-connect']} disabled style={{ background: '#1DA1F2' }}><IoLogoTwitter size={24} style={{ verticalAlign: 'middle' }} />Twitter</button>
            //             </div>
            //             <div className={dStyle['page-btn']}>
            //                 <button className={dStyle['navigate-btn']} onClick={handleBack} style={{ background: 'var(--white-color)', color: 'var(--ten-color)' }}>Back</button>
            //                 <button type="submit" className={dStyle['navigate-btn']} style={{ background: 'var(--ten-color)', color: 'var(--white-color)' }} disabled={loading}>
            //                     {loading ? <CircularProgress size={16} sx={{ color: "white" }} /> : "Submit"}
            //                 </button>
            //             </div>
            //         </>
            //     );

            default:
                return null;
        }
    };

    return (

        <div className={dStyle['page-body']}>
            <div className={dStyle['page-wrapper']}>
                <form className={dStyle.main} onSubmit={handleSubmit}>
                    <CustomStepper activeStep={activeForm} steps={steps} next={handleNext} />
                    {renderForm(activeForm)}
                </form>
            </div>
        </div>

    );
};

export default Details;