"use client";
import React, { useRef, useState, useEffect } from "react";
import { addMediaResource, useGlobalState, handleDelete ,getMediaDuration,addUploadedMedia} from "../Store/Store";
import { Button, Menu, MenuItem, IconButton } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';  // For the three dots icon
import PropTypes from "prop-types";
import styles from "../../../../css/VideoEditor/video.module.css";
import { formatTimeToMinSec } from "../utils/utils";
import UploadButton from "./UploadButton";


import SoftButton from "components/SoftButton";


const MediaResource = ({ media, onDuplicate }) => {
  const store = useGlobalState();
  const [selectedScene, setSelectedScene] = useState("");
  const sceneVideo = store.sceneVideos.get();
  const videoref = useRef(null);
  const imgref = useRef(null);
  const [formattedVideoLength, setFormattedVideoLength] = useState("00:00");
  const [resolution, setResolution] = useState({ w: 0, h: 0 });
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);  // For the three-dots menu
  const selectedSceneState = store.selectedScene.get()

  const handleSceneSelect = (index, sceneStart, sceneName, sceneEnd) => {
    setSelectedScene(sceneName);
    store.selectedScene.set({
      ...selectedSceneState,
      [media.name]: sceneName,  
    });
    addMediaResource(
      media.type,
      media.src,
      media.name,
      media.duration,
      sceneStart,
      sceneName,
      sceneEnd,
      resolution.w,
      resolution.h
    );
    setAnchorEl(null);
  };

  useEffect(() => {
    const selectedSceneState = store.selectedScene.get();
    if (selectedSceneState[media.name]) {
      setSelectedScene(selectedSceneState[media.name]);
    }
  }, [store.selectedScene, media.name]);

  // Duplicate media functionality
  const handleDuplicate = () => {
    const newMedia = {
      ...media,
      name: `${media.name} (Copy)`
    };
    onDuplicate(newMedia);
    setMenuAnchorEl(null);  // Close the menu after duplication
  };

  const deleteMedia = () => {
    handleDelete(media.name, true)
  }

  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (!files) return;
    // setSelectedResourceType("media");
    for (const file of files) {
      const type = file.type.startsWith("video") ? "video" : "image";
      const name = file.name;
      const url = URL.createObjectURL(file);

      let duration = null;
      if (type === "video") {
        try {
          const durationInSec = await getMediaDuration(url);
          duration = durationInSec * 1000;
        } catch (error) {
          console.error("Error getting media duration:", error);
        }
      } else {
        duration = 20000; // Default duration for images
      }
      addUploadedMedia(type, url, name, duration);
    }
  };

  return (
    <div className={styles.mediaContainer}>
      {/* <UploadButton
          accept="image/*,video/mp4,video/x-m4v,video/*"
          multiple
          className="bg-transparent text-center mx-2 mb-2 py-2 px-2 cursor-pointer border-none"
          onChange={handleFileChange}
          toggle={false}
          resource="media"
        /> */}
      <div className={styles.mediaWrapper}>
        <div className={styles.mediaNameInfo}>{media.name}</div>

        {media.type === "video" && (
          <video
            ref={videoref}
            className={styles.mediaVideo}
            src={media.src}
            height={100}
            width={100}
            onLoadedData={() => {
              const videoLength = videoref.current?.duration ?? 0;
              setFormattedVideoLength(formatTimeToMinSec(videoLength));
              setResolution({
                w: videoref.current?.videoWidth ?? 0,
                h: videoref.current?.videoHeight ?? 0,
              });
            }}
          />
        )}

        {media.type === "image" && (
          <img
            ref={imgref}
            className={styles.mediaVideo}
            src={media.src}
            height={100}
            width={60}
            alt={media.name}
            onLoad={() => {
              setResolution({
                w: imgref.current?.naturalWidth ?? 0,
                h: imgref.current?.naturalHeight ?? 0,
              });
            }}
          />
        )}

        {media.type === "video" && (
          <div className={styles.mediaInfo}>{formattedVideoLength}</div>
        )}

       <SoftButton
          variant="outlined"
          onClick={(event) => setAnchorEl(event.currentTarget)}
          style={{ color: "black" }}
          sx={{
            width: "100%", position: "relative",
            color:"black",
            '&:hover': {
              backgroundColor: 'transparent',
              borderColor: 'inherit',
            }
          }}
        >
          {selectedScene ? selectedScene : "Select Scene"}
        </SoftButton>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {sceneVideo.map((scene, index) => (
            <MenuItem
              key={scene.name}
              onClick={() => handleSceneSelect(index, scene.start, scene.name, scene.end)}
              style={{ color: 'black' }} // Inline style for black text
            >
              Scene {index + 1}
            </MenuItem>
          ))}
        </Menu>

        {/* Three dots menu for duplication */}
        <IconButton
          onClick={(event) => setMenuAnchorEl(event.currentTarget)}
          style={{ position: "absolute", right: "0", top: "0" }}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(null)}
        >
          <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
          <MenuItem onClick={deleteMedia}>Delete</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

MediaResource.propTypes = {
  media: PropTypes.shape({
    type: PropTypes.oneOf(['video', 'image']).isRequired,
    src: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
  }).isRequired,
  onDuplicate: PropTypes.func.isRequired,  // Function for handling media duplication
};

export default MediaResource;
