import React, { useEffect } from 'react';
import SingleBanner from './Banners/SingleBanner';
import Footer1 from './FooterExtra/Footer1';
import Footer from '../Landing_Page/Footer';
import Nav from '../Landing_Page/Nav';

const Termsandconditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='extrapage'>
      <Nav link_color={'black'} border_color={'var(--text-400-color)'}/>
      <SingleBanner
        bannerimage="https://w7.pngwing.com/pngs/350/481/png-transparent-banner-cover-background.png"
        heading="Terms &amp; Conditions"
      />

      <div className='pgcont1'>
        <h3>What is Lorem Ipsum?</h3>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
      </div>

      <div className='pgcont1'>
        <h3>Where does it come from?</h3>
        <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
      </div>

      <div className='pgcont1'>
        <h3>Where can I get some?</h3>
        <p>There are many variations of passages of Lorem Ipsum available.</p>
      </div>

      <div className='pgcont1'>
        <h3>Why do we use it?</h3>
        <p>It is a long established fact that a reader will be distracted by the readable content.</p>
      </div>

      <div className='pgcont1'>
        <h3>The standard Lorem Ipsum passage, used since the 1500s</h3>
        <p>&quot;Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.&quot;</p>
      </div>

      <div className='pgcont1'>
        <h3>Section 1.10.32 of &quot;de Finibus Bonorum et Malorum&quot;, written by Cicero in 45 BC</h3>
        <p>&quot;Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.&quot;</p>
      </div>

      <Footer1 />
      <Footer />
    </div>
  );
};

export default Termsandconditions;
