import { VscGraphLine } from "react-icons/vsc";
import { MdOutlineKeyboardArrowLeft, MdManageSearch, MdOutlinePersonSearch } from "react-icons/md";
import { BsRobot, BsPersonVcard, BsPersonBadge } from "react-icons/bs";
import { FaChartPie, FaMagnifyingGlassChart, FaPersonChalkboard } from "react-icons/fa6";
import { TbCameraHeart, TbSettingsSearch, TbTrendingUp } from "react-icons/tb";
import { GrSchedulePlay, GrDocumentVideo } from "react-icons/gr";
import { BiBarChartAlt2, BiMessageSquareAdd, BiChart } from "react-icons/bi";
import { FiSend } from "react-icons/fi";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../../constants"

import React, { useState, useEffect } from 'react';

import "../../../css/LandingPage/landing.css";
import '../../../css/theme.css';

import Nav from './Nav.jsx';
import Footer from './Footer.jsx';

import '../../../css/LandingPage/animatedblob.css';
import InteractiveBubble from "./InteractiveBubble"
import Footer1 from "../Extra/FooterExtra/Footer1";
import Pricing from "../Pricing_Page/Pricing";

import img0 from "../../../assets/images/1.mp4"
import img1 from "../../../assets/images/2.mp4"
import img2 from "../../../assets/images/4.mp4"
import img3 from "../../../assets/images/3.mp4"
// import img2 from "../../../assets/images/palak.mp4"

import image1 from "../../../assets/icons/LandingPage/content.png"
import image2 from "../../../assets/icons/LandingPage/guide.png"
import image3 from "../../../assets/icons/LandingPage/recording.png"
import image4 from "../../../assets/icons/LandingPage/per.png"
import { TbCameraCog } from "react-icons/tb";
import { useNavigate ,Link} from 'react-router-dom';

const LandingPage = () => {
  // function AudienceToolsGroup() {
  // Initialize state to track whether the div is active or not
  const [isActive, setIsActive] = useState(true);
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [activeIndex, setActiveIndex] = useState('0'); // State to track active index
  const imageMap = {
    0: img0,
    1: img1,
    2: img2,
    3: img3
  };
  const [imageSrc, setImageSrc] = useState(imageMap[activeIndex]);
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => {
        const nextIndex = (parseInt(prevIndex, 10) + 1) % 4;
        return nextIndex.toString();
      });
      // setImageSrc(imageMap[activeIndex])
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Update imageSrc whenever activeIndex changes
    setImageSrc(imageMap[activeIndex]);
  }, [activeIndex]);

  // useEffect(() => {
  //   const videos = document.querySelectorAll('.main-video');
  //   videos.forEach(video => {
  //     video.pause();
  //     video.currentTime = 0;
  //   });

  //   const activeSlide = document.querySelector(`.carousel-item:nth-child(${parseInt(activeIndex, 10) + 1})`);
  //   if (activeSlide) {
  //     const activeVideos = activeSlide.querySelectorAll('.main-video');
  //     activeVideos.forEach(video => video.play());
  //   }
  // }, [activeIndex]);
const navigate = useNavigate()
  const getStarted = (e)=>{
    e.preventDefault();
    navigate('/creator/EasyInsight');
  }

  const handleClick = (index,image) => {
    setImageSrc(imageMap[index])
    setActiveIndex(index);
  };

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  const handleToggle1 = () => {
    setIsActive1(!isActive1);
  };

  const handleToggle2 = () => {
    setIsActive2(!isActive2);
  };

  
  return (
    <>

      <div className='landing-page' style={{ overflow: 'hidden' }}>

        {/* <div className="text-container">
Content Creation Made Easy
  </div>
  <div className="text-container">
Content Creation Made Easy
  </div>
  <div style={{ fontSize:'75px'}} className="text-container"><em>Senior Selachimorpha at DigitalOcean</em></div>
  <div className="text-container"style={{ marginTop: '10px' }}>
Content Creation Made Easy */}
        {/* </div> */}
        <h1 style={{ textAlign: 'center', fontSize: 'clamp(18px,4vw,24px)', color: 'white', opacity: '1', marginTop: '-7.5rem' }} className='type-xl text-container' >INFLUENZO</h1>
        <h2 style={{ textAlign: 'center', fontSize: 'clamp(42px, 7vw, 84px)', color: 'white', fontFamily: 'Comfortaa', marginTop: '-2.25rem', fontWeight: '700' }} className='type-xl text-container' >Content Creation</h2>
        <h2 style={{ textAlign: 'center', fontSize: 'clamp(42px, 7vw, 84px)', color: 'white', fontFamily: 'Comfortaa', fontWeight: '500', marginTop: '4rem' }} className='type-xl text-container' >Made Easy</h2>
        <div className="center">

          
  {/* <Link to="/creator/EasyFind"> */}
    <button className="button large-button" style={{ marginTop: '24rem' }} onClick={()=>{navigate("/creator/EasyFind")}}>
      Get Started
    </button>
  {/* </Link> */}
</div>


        <div className="gradient-bg" style={{ height: '97vh' }} >
          <Nav link_color={'white'}/>
          <svg xmlns="http://www.w3.org/2000/svg">
            <defs>
              <filter id="goo">
                <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                <feColorMatrix
                  in="blur"
                  mode="matrix"
                  values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8"
                  result="goo"
                />
                <feBlend in="SourceGraphic" in2="goo" />
              </filter>
            </defs>
          </svg>
          <div className="gradients-container">
            <div className="g1"></div>
            <div className="g2"></div>
            <div className="g3"></div>
            <div className="g4"></div>
            <div className="g5"></div>
            <InteractiveBubble />
          </div>
        </div>

        <div id="section1" className='section main-screen curved' >
          <div className='container-fluid'>
            <div className='tools-list'>
              <div className={`tools-group group-audience ${isActive ? 'is-active' : '' }`} onClick={handleToggle}>
                <div className='tools-group-header'>
                  <div className='group-icon'><VscGraphLine /></div>
                  <div className='group-label'>ANALYTICS</div>
                  <div className='group-expand'><MdOutlineKeyboardArrowLeft size={16} style={{ verticalAlign: 'middle' }} /></div>
                </div>
                <div className='tools-group-body'>
                  <div className='tools-group-content'>
                    <div className='tools-group-title'>Easy to understand data</div>
                    <a className='group-tool' href="https://bot.inflact.com/">
                      <div className='tool-icon'><BiBarChartAlt2 /></div>
                      <div className='tool-title'>Growth</div>
                    </a>
                    <a className='group-tool' href="https://bot.inflact.com/">
                      <div className='tool-icon'><FaPersonChalkboard /></div>
                      <div className='tool-title'>Profile Analyzer</div>
                    </a>
                    <a className='group-tool' href="/tools/instagram-search/">
                      <div className='tool-icon'><BsRobot /></div>
                      <div className='tool-title'>Smart Chatbot</div>
                    </a>
                    <a className='group-tool' href="/tools/profile-analyzer/">
                      <div className='tool-icon'><FaChartPie /></div>
                      <div className='tool-title'>Audience Relatibilty Index</div>
                    </a>
                  </div>
                </div>
              </div>
              <div className={`tools-group group-client ${isActive1 ? 'is-active' : '' }`} onClick={handleToggle1}>
                <div className='tools-group-header'>
                  <div className='group-icon'><FaMagnifyingGlassChart /></div>
                  <div className='group-label'>DISCOVER</div>
                  <div className='group-expand'><MdOutlineKeyboardArrowLeft size={16} style={{ verticalAlign: 'middle' }} /></div>
                </div>
                <div className='tools-group-body'>
                  <div className='tools-group-content'>
                    <div className='tools-group-title'>Know what to create next</div>
                    <a className='group-tool' href="/direct-messages/">
                      <div className='tool-icon'><FiSend /></div>
                      <div className='tool-title'>Advanced Direct Messenger</div>
                    </a>
                    <a className='group-tool' href="/direct-messages/#auto-replies">
                      <div className='tool-icon'>< MdManageSearch size={22} /></div>
                      <div className='tool-title'>Content Research</div>
                    </a>
                    <a className='group-tool' href="/direct-messages/">
                      <div className='tool-icon'>< MdOutlinePersonSearch size={22} /></div>
                      <div className='tool-title'>Competition Research</div>
                    </a>
                    <a className='group-tool' href="/direct-messages/">
                      <div className='tool-icon'>< TbTrendingUp /></div>
                      <div className='tool-title'>Content Trends</div>
                    </a>
                  </div>
                </div>
              </div>
              <div className={`tools-group group-content ${isActive2 ? 'is-active' : ''} `} onClick={handleToggle2}>
                <div className='tools-group-header'>
                  <div className='group-icon'><TbCameraHeart /></div>
                  <div className='group-label'>CONTENT</div>
                  <div className='group-expand'><MdOutlineKeyboardArrowLeft size={16} style={{ verticalAlign: 'middle' }} /></div>
                </div>
                <div className='tools-group-body'>
                  <div className='tools-group-content'>
                    <div className='tools-group-title'>Save your efforts with us</div>
                    <a className='group-tool' href="/tools/instagram-hashtag-generator/">
                      <div className='tool-icon'><GrDocumentVideo /></div>
                      <div className='tool-title'>Short Video AI Editor&quot;Coming Soon&quot;</div>
                    </a>
                    <a className='group-tool' href="/tools/instagram-top-hashtags/">
                      <div className='tool-icon'><GrSchedulePlay /></div>
                      <div className='tool-title'>Scheduled Posting</div>
                    </a>
                    <a className='group-tool' href="/tools/instagram-top-hashtags/">
                      <div className='tool-icon'><BsPersonVcard /></div>
                      <div className='tool-title'>Personalized Content Creation Plans</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='trusting-info">
               Trusted by <strong>15,000+</strong> individuals, businesses and influencers. <strong>Excellent 4.3 out of 5</strong> on <span className='trustpilot-rank"><i className='icon-star"></i>Trustpilot</span> Based on 575 reviews.    </div>
                </div> */}
          </div>

          <div className='content-section how-it-works' data-scroll-target="how-it-works">
            <div className='container-fluid'>
              <div className='row'>
                <div className='col-12 col-lg-8'>
                  <h2 className='section-title'>HOW TO MAKE QUALITY CONTENT</h2>
                  <p className='section-text'>
                    Do you spend a lot of efforts on making your content? Social media apps won&apos;t be enough to help you to understand your audience, research your competition, know what&apos;s going viral. Use Influenzo to connect with your audience, make plans in no time so that you can spend your efforts in something that will really make you grow.</p>
                </div>
              </div>
              <div className='how-it-works-carousel'>
                <div className='carousel-inner swiper'>
                  <div className='swiper-wrapper'>
                    <div className='carousel-item swiper-slide'>
                      <div className='carousel-item-content'>
                        <video className='main-video is-desktop video-x1 b-lazy' autoPlay muted disablePictureInPicture controls={false} width="800" height="450" src={imageSrc} alt="desktop video thumbnail" />
                        {/* <img className='main-video is-desktop video-x2 b-lazy' width="800" height="450" src={img1} alt="desktop video thumbnail" /> */}
                        <video className='main-video is-mobile b-lazy' autoPlay muted disablePictureInPicture controls={false} width="320" height="688" src={imageSrc} alt="mobile video thumbnail" />

                      </div>
                    </div>
                    {/* <div className='carousel-item swiper-slide'>
                      <div className='carousel-item-content'>
                        <img className='main-video is-desktop video-x1 b-lazy' width="800" height="450" src={img2} alt="desktop video thumbnail" />
                        <img className='main-video is-desktop video-x2 b-lazy' width="800" height="450" src={img1} alt="desktop video thumbnail" />
                        <img className='main-video is-mobile b-lazy' width="320" height="688" src={img2} alt="mobile video thumbnail" />

                      </div>
                    </div>
                    <div className='carousel-item swiper-slide'>
                      <div className='carousel-item-content'>
                       <img className='main-video is-desktop video-x1 b-lazy' width="800" height="450" src={img3} alt="desktop video thumbnail" />
                        <img className='main-video is-desktop video-x2 b-lazy' width="800" height="450" src={img1} alt="desktop video thumbnail" />
                        <img className='main-video is-mobile b-lazy' width="320" height="688" src={img3} alt="mobile video thumbnail" />

                      </div>
                    </div>
                    <div className='carousel-item swiper-slide'>
                      <div className='carousel-item-content'>
                       <img className='main-video is-desktop video-x1 b-lazy' width="800" height="450" src={img1} alt="desktop video thumbnail" />
                        <img className='main-video is-desktop video-x2 b-lazy' width="800" height="450" src={img1} alt="desktop video thumbnail" />
                        <img className='main-video is-mobile b-lazy' width="320" height="688" src={img1} alt="mobile video thumbnail" />

                      </div>
                    </div> */}
                  </div>
                </div>
                <div className='carousel-nav-mobile'>
                  <div className='carousel-control control-prev' >
                    <div className='control-icon icon-arrow_left'></div>
                  </div>
                  <div className={`carousel-slide-title ${activeIndex === '0' ? "is-active" : ""}`} onClick={() => handleClick('0',img0)}>Dashboard with all options</div>
                  <div className={`carousel-slide-title ${activeIndex === '1' ? "is-active" : ""}`} onClick={() => handleClick('1',img1)}>Discover Engine</div>
                  <div className={`carousel-slide-title ${activeIndex === '2' ? "is-active" : ""}`} onClick={() => handleClick('2',img2)}>Personalized & Customized Plans</div> 
                  <div className={`carousel-slide-title ${activeIndex === '3' ? "is-active" : ""}`} onClick={() => handleClick('3',img3)}>.....And Much More .....</div> 
                  <div className='carousel-control control-next' >
                    <div className='control-icon icon-arrow_left'></div>
                  </div>
                </div>
                <div className='carousel-nav'>
                  <div className='carousel-nav-title'>Start Making Quality Content:</div>
                  <div className={`btn carousel-indicator ${activeIndex === '0' ? "is-active" : ""}`} onClick={() => handleClick('0',img0)}>
                    <div className='btn-inner'>
                      <div className='btn-icon'><BiChart /></div> <div className='btn-text'>Dashboard with all options</div>
                    </div>
                  </div>
                  <div className={`btn carousel-indicator ${activeIndex === '1' ? "is-active" : ""}`} onClick={() => handleClick('1',img1)}>
                    <div className='btn-inner'>
                      <div className='btn-icon'>< TbSettingsSearch /></div>
                      <div className='btn-text'>Discover Engine</div>
                    </div>
                  </div>
                  <div className={`btn carousel-indicator ${activeIndex === '2' ? "is-active" : ""}`} onClick={() => handleClick('2',img2)}>
                    <div className='btn-inner'>
                      <div className='btn-icon'><BsPersonBadge /></div>
                      <div className='btn-text'>Personalized & Customized Plans</div>
                    </div>
                  </div>
                  <div className={`btn carousel-indicator ${activeIndex === '3' ? "is-active" : ""}`} onClick={() => handleClick('3',img3)}>
                    <div className='btn-inner'>
                      <div className='btn-icon'><BiMessageSquareAdd /></div>
                      <div className='btn-text'>.....And Much More .....</div>
                    </div>
                  </div> 
                </div>
              </div>
              <div className='services-actions-list'>
                <div className='services-action-info'>
                  <img className='action-image img-border' src={image1} width="100" height="200" alt="" loading="lazy" />        <div className='action-title'>
                    <span>Content </span> <span>Discovery</span>        </div>
                  <div className='action-description'>
                    <span>Use Influenzo for </span><span> content & competition research</span>        </div>
                </div>
                <div className='services-action-info'>
                  <img className='action-image img-border' src={image2} width="100" height="200" alt="" loading="lazy" />        <div className='action-title'>
                    <span>Personalized</span> <span>Guide</span>        </div>
                  <div className='action-description'>
                    <span>Use Influenzo to create a </span> <span>personalized guide just for you</span>        </div>
                </div>
                <div className='services-action-info'>
                  <img className='action-image img-border' src={image3} width="100" height="200" alt="" loading="lazy" />        <div className='action-title'>
                    <span>Start </span> <span>Recording</span>        </div>
                  <div className='action-description'>
                    <span>Start recording and </span> <span>start your journey with us</span>        </div>
                </div>
                <div className='services-action-info'>
                  <img className='action-image img-border' src={image4} width="100" height="200" alt="" loading="lazy" />        <div className='action-title'>
                    <span>Content</span> <span>Performance</span>        </div>
                  <div className='action-description'>
                    <span>Use Influenzo</span> <span>to see how your content performed</span>        </div>
                </div>
              </div>
            </div >
          </div >
        </div >
        <div id="section2" className='section'  >
          {/* <Pricing/> */}
          Palak
        </div>
        <div id="section3" className='section main-screen curved'  >
          sharma
        </div>

        <div>
          <Footer1 />
        </div>
        <div>
          <Footer />
        </div>
      </div >
    </>
  )
}
export default LandingPage;

