import React from "react";

import eStyle from '../../css/ErrorPage/ErrorPage.module.css'
import Nav from "../Pages/Landing_Page/Nav";

import earth from '../../assets/icons/ErrorPage/earth.svg';
import err from '../../assets/icons/ErrorPage/error.svg'
import rocket from '../../assets/icons/ErrorPage/rocket.svg';
import astronaut from '../../assets/icons/ErrorPage/astronaut.svg'
import moon from '../../assets/icons/ErrorPage/moon.svg';

const ErrorPage = () => {
    return (
        <div className={eStyle['error-container']}>
            <div className={eStyle['stars']}>
                <Nav />
                <div className={eStyle['central-body-container']}>
                    <div className={eStyle['central-body']}>
                        <img className={eStyle['image-404']} src={err} width="300px" />
                        <a href="error.svg" className={eStyle['btn-go-home']} target="_blank">HOME</a>
                    </div>
                </div>
                <div className={eStyle['objects']}>
                    <img className={eStyle['object_rocket']} src={rocket} width="40px" />
                    <div className={eStyle['earth-moon']}>
                        <img className={eStyle['object_earth']} src={earth} width="100px" />
                        <img className={eStyle['object_moon']} src={moon} width="80px" />
                    </div>
                    <div className={eStyle['box_astronaut']}>
                        <img className={eStyle['object_astronaut']} src={astronaut} width="140px" />
                    </div>
                </div>
                <div className={eStyle['glowing_stars']}>
                    <div className={eStyle['star']}></div>
                    <div className={eStyle['star']}></div>
                    <div className={eStyle['star']}></div>
                    <div className={eStyle['star']}></div>
                    <div className={eStyle['star']}></div>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage;