import React, { useState } from 'react';
import { MdEmail, MdOutlineWifiPassword } from "react-icons/md";
import fStyle from '../../../css/LoginPage/Login.module.css';
import api from '../../../backend_api';  // Adjust this import based on your actual API utility
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import PropTypes from "prop-types";
// EmailVerify Component
export const EmailVerify = ({ nextStep }) => {
    return (
        <form className={fStyle['email-verify-form']}>
            <h3 className={fStyle['email-verify-title']}>Verify Email</h3>
            <div className={fStyle['email-verify-input-field']}>
                <MdEmail />
                <input type="email" placeholder="Email" />
            </div>
            <button type="button" className={fStyle['email-verify-btn']} onClick={nextStep}>Next</button>
            <a href='login' className={fStyle['back-to-signin']}>Sign in</a>
        </form>
    );
};

// OtpVerify Component
export const OtpVerify = ({ nextStep }) => {
    return (
        <form className={fStyle['otp-verify-form']}>
            <h3 className={fStyle['otp-verify-title']}>Verify OTP</h3>
            <div className={fStyle['otp-verify-input-field']}>
                <input type="text" placeholder="Enter OTP" />
            </div>
            <button type="button" className={fStyle['otp-verify-btn']} onClick={nextStep}>Next</button>
        </form>
    );
};

// Reset Component
export const Reset = () => {
    const [showPasswords, setShowPasswords] = useState(false);
    const [rError, setRError] = useState(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const togglePasswordVisibility = () => {
        setShowPasswords(!showPasswords);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let form = new FormData(e.target);
        let formObj = Object.fromEntries(form.entries());

        if (formObj.newPassword !== formObj.confirmPassword) {
            setRError("Passwords do not match");
            return;
        }

        const route = "/api/user/update_password/";  // Adjust this API route to match your backend

        try {
            const res = await api.post(route, {
                new_password: formObj.newPassword,
                confirm_password: formObj.confirmPassword,
            });

            console.log(res.data);
            navigate("/login");  // Adjust the route based on where you want to navigate after reset
        } catch (error) {
            const message = error.response?.data;
            if (message && message.detail) {
                setRError(message.detail);
            } else {
                console.error(error);
            }
        }
    };

    return (
        <form className={fStyle['reset-form']} onSubmit={handleSubmit}>
            <h3 className={fStyle['reset-title']}>Reset Password</h3>
            <div className={fStyle['reset-input-field']}>
                <MdOutlineWifiPassword />
                <input
                    type={showPasswords ? "text" : "password"}
                    placeholder="New Password"
                />
            </div>
            <div className={fStyle['reset-input-field']}>
                <MdOutlineWifiPassword />
                <input
                    type={showPasswords ? "text" : "password"}
                    placeholder="Confirm Password"
                />
            </div>
            <div className={fStyle['show-passwords']}>
                <input
                    type="checkbox"
                    id="showPasswords"
                    checked={showPasswords}
                    onChange={togglePasswordVisibility}
                />
                <label htmlFor="showPasswords" style={{ color: 'black', marginTop: '2%', marginLeft: '4px' }}>Show Passwords</label>
            </div>
            {rError && <p className={fStyle['error-message']} style={{ color: 'red' }}>{rError}</p>}
            <button type="submit" className={fStyle['reset-btn']} disabled={loading}>
                {loading ? <CircularProgress size={16} sx={{color:"white"}}/> : "Reset"}
            </button>
        </form>
    );
};

EmailVerify.propTypes = {
    nextStep: PropTypes.func.isRequired, // Callback function for value changes
};

OtpVerify.propTypes = {
    nextStep: PropTypes.func.isRequired, // Callback function for value changes
};