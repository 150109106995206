import React from 'react';
import { recordCanvasMedia, handleSeek, setPlaying, useGlobalState} from "../Store/Store";
import style from "../../../../css/VideoEditor/video.module.css"
import { MdOutlineFileDownload } from "react-icons/md";
const DownloadButton = () => {
    const store = useGlobalState()
    const audioUrls = store.combinedUrl.get()
    const audioUrls2 = store.bothCombineUrl.get()
    const videoUrls = store.downloadUrl.get()
    const videoUrls2 = store.recordedVideo.get()

    const discardActive =()=>{
        const canvas = store.canvas.get();
        canvas.discardActiveObject();
    }

    return (
        <>
            <button
                onClick={() => {
                    handleSeek(0);
                    discardActive()
                    setTimeout(() => {
                        setPlaying(true);
                        recordCanvasMedia();
                    }, 1000);
                }}
            className={style.downloadButton}
            >
                Download <MdOutlineFileDownload size="16" style={{ verticalAlign: 'middle', marginLeft: "4px", }}/>
            </button>
            <audio src={audioUrls} style={{ display: 'none' }}></audio>
            <audio src={audioUrls2} style={{ display: 'none' }}></audio>
            <video src={videoUrls} style={{ display: 'none' }}></video>
            <video src={videoUrls2} style={{ display: 'none' }}></video>
        </>
    );
};

export default DownloadButton;