import React, { useRef, useEffect, useState } from 'react';
import '../../../css/LandingPage/animatedblob.css';

const InteractiveBubble = () => {
    const interBubbleRef = useRef(null);
    const [coordinates, setCoordinates] = useState({ curX: 0, curY: 0, tgX: 0, tgY: 0 });

    useEffect(() => {
        const move = () => {
            setCoordinates((coords) => {
                const { curX, curY, tgX, tgY } = coords;
                const newCurX = curX + (tgX - curX) / 20;
                const newCurY = curY + (tgY - curY) / 20;
                if (interBubbleRef.current) {
                    interBubbleRef.current.style.transform = `translate(${Math.round(newCurX)}px, ${Math.round(newCurY)}px)`;
                }
                return { ...coords, curX: newCurX, curY: newCurY };
            });
            requestAnimationFrame(move);
        };

        const handleMouseMove = (event) => {
            setCoordinates((coords) => ({
                ...coords,
                tgX: event.clientX,
                tgY: event.clientY,
            }));
        };

        window.addEventListener('mousemove', handleMouseMove);
        move();

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return <div className="interactive" ref={interBubbleRef}></div>;
};

export default InteractiveBubble;
