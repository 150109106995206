import React from "react";
import PropTypes from "prop-types";
import Stepper from '@mui/joy/Stepper';
import Step from '@mui/joy/Step';
import StepIndicator from '@mui/joy/StepIndicator';
import { FaCheck } from "react-icons/fa6";
import dStyle from '../../../css/ProfilePage/Details.module.css'

const CustomStepper = ({ activeStep, steps }) => {

    activeStep = activeStep - 1;
    return (
        <Stepper sx={{ width: '100%' }} className={dStyle['text-stepper']}>
            {steps.map((step, index) => (
                <Step
                    key={step}
                    orientation="vertical"
                    indicator={
                        <StepIndicator
                            className="indicator"
                            variant={activeStep <= index ? 'outlined' : 'solid'}
                            color={activeStep < index ? 'neutral' : 'primary'}
                        >
                            {activeStep <= index ? index + 1 : <FaCheck />}
                        </StepIndicator>
                    }
                    sx={{
                        '&::after': {
                            ...(activeStep > index &&
                                index !== 2 && { bgcolor: 'primary.solidBg' }),
                        },

                    }}
                >
                    {window.innerWidth < 768 ? (
                        <div className={dStyle['text-step']}>
                            {activeStep === index && (
                                <div style={{ textAlign: 'center' }}>{step}</div>
                            )}
                        </div>
                    )
                        : (
                            <div className={dStyle['text-step']} style={{ textAlign: 'center'}}>{step}</div>
                        )}
                </Step>
            ))}
        </Stepper>
    );
}
CustomStepper.propTypes = {
    activeStep: PropTypes.number.isRequired,
    steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default CustomStepper;

