import React from 'react'
import "../../../../css/LandingPage/landing.css"
import "../../../../css/theme.css"


const Footer1 = () => {
    return (
        <div className='content-section section-sign-up'>
          <div className='section-inner'>
            <div className='section-title' style={{ color:'black'}}>Start utilizing your efforts with AI
            </div>
            {/* <a className='btn btn-sign-up button' onMouseEnter={(e) => (e.target.style.borderColor = '#dde6fc')} onMouseLeave={(e) => (e.target.style.borderColor = '#dde6fc')}> */}
            <div className='btn btn-sign-up' onMouseEnter={(e) => (e.target.style.borderColor = '#dde6fc')} onMouseLeave={(e) => (e.target.style.borderColor = '#dde6fc')}>
              <div className='btn-inner'>
                <a className='button2' style={{ fontSize: '0.85rem', textDecoration: 'none'}} href="/creator/Easyfind">Get started</a>
              </div>
            </div>
            {/* <a href="#section2" className='btn btn-view-pricing'>
              <div className='btn-inner'>
              <div className='button-non-cta2' style={{ fontSize: '0.85rem' }}>View prices</div>

              </div>
            </a> */}
          </div>
        </div >
    )
}

export default Footer1