
import React, { useState } from 'react';
import '../../../css/LandingPage/Nav.css';
import PropTypes from "prop-types";

import { Helmet } from "react-helmet"
import { useNavigate, Link } from 'react-router-dom';

import influenzo from '../../../assets/icons/LandingPage/logo.png';

import { RiFacebookCircleLine, RiAiGenerate, RiInstagramLine, RiFontSize, RiShoppingBagLine, RiBloggerLine, RiTwitterLine, RiTiktokLine, RiPlayCircleLine, RiAddBoxLine, RiQuestionAnswerLine, RiPriceTag3Line, RiReplyAllLine, RiHashtag, RiMailLine } from "react-icons/ri";
import { BiCategory } from "react-icons/bi";
import { TbDeviceDesktopAnalytics, TbHistoryToggle, TbUsers, TbDeviceAnalytics, TbArrowGuide, TbUserSquareRounded, TbBulb, TbSend, TbBrandYoutube, TbDeviceIpadSearch } from "react-icons/tb";
import { TfiHeadphoneAlt, TfiThought } from "react-icons/tfi";
import { HiOutlineBeaker } from "react-icons/hi2";
import { BsFire, BsBarChart, BsBoxArrowInDown } from "react-icons/bs";
import { MdContentPaste, MdMenu, MdKeyboardArrowDown } from "react-icons/md";
import { RxRocket } from "react-icons/rx";
import { GrServices } from "react-icons/gr";
import { LuView } from "react-icons/lu";

const Nav = (props) => {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);
    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);
    const [mode, setmode] = useState('btn-outline-secondary' + ' ' + 'button-non-cta' + ' ' + 'btn-text');

    const signin = (e) => {
        e.preventDefault();
        navigate('/login');
    }

    const colorChange = (num) => {
        // e.preventDefault();
        setIsHovered(true);
        if (num === "one") {
            setIsHovered1(true);
            setIsHovered2(false);
            setIsHovered3(false);
        } else if (num === "two") {
            setIsHovered2(true);
            setIsHovered1(false);
            setIsHovered3(false);
        } else if (num === 'three') {
            setIsHovered3(true);
            setIsHovered1(false);
            setIsHovered2(false);
        }
        setmode('btn-outline-secondary' + ' ' + 'button-non-cta' + ' ' + 'btn-text' + ' ' + 'active');
    }

    const outChange = (e) => {
        e.preventDefault();
        setIsHovered(false);
        setmode('btn-outline-secondary' + ' ' + 'button-non-cta');
    }

    const colorChange1 = (e) => {
        e.preventDefault();
        setIsHovered1(true);
    }

    const outChange1 = (e) => {
        e.preventDefault();
        // setIsHovered1(false);
    }

    const colorChange2 = (e) => {
        e.preventDefault();
        setIsHovered2(true);
    }

    const outChange2 = (e) => {
        e.preventDefault();
        // setIsHovered2(false);
    }

    const colorChange3 = (e) => {
        e.preventDefault();
        setIsHovered3(true);
    }

    const outChange3 = (e) => {
        e.preventDefault();
        // setIsHovered3(false);
    }

    return (
        <>
            <Helmet>
                <script
                    src="header.js"
                    crossOrigin="anonymous"
                    async
                ></script>
            </Helmet>
            <nav className="header">
                <div className="main-menu">
                    <a className="logo" href="/"><img src={influenzo} width="5000" height="2000" loading="lazy" alt="Influenzo Logo" /></a>
                    <div className="burger menu-toggle ">
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                        <div className="line"></div>
                    </div>
                    <div className="menu-container">
                        <div className="menu-header">
                            <div className="menu-header-icon"><MdMenu size={36} color='black' /></div>
                            <div className="menu-header-title">Menu</div>
                        </div>
                        <div className="menu-sections-list">
                            <div className="menu-section" data-menu-section-type="single">
                                <a className="menu-section-header " href="/" style={{ color: isHovered ? '#6994f1' : `${props.link_color}` }}>General</a>
                            </div>
                            <div className="menu-section" data-menu-section-type="single" >
                                <a className="menu-section-header " href="/pricing" style={{ color: isHovered ? '#6994f1' : `${props.link_color}` }}>Pricing</a>
                            </div>
                            {/* <div className="menu-section is-active" data-menu-section-type="complex" onMouseOver={(e)=>colorChange("one")} onMouseLeave={outChange}>
                                <div className="menu-section-header" style={{ color: isHovered ? (isHovered1 ? 'white' : '#6994f1' ):  `${props.link_color}`}} onMouseOver={colorChange1} onMouseLeave={outChange1}>
                                    Services <div className="menu-section-icon "><MdKeyboardArrowDown style={{ verticalAlign: 'middle' }} /></div>
                                </div>
                                <div className="menu-section-body">
                                    <div className="menu-section-container">
                                        <div className="menu-groups-list">
                                            <div className="menu-group">
                                                <div className="menu-group-header">
                                                    <a className="group-title" href="#section1"><div className="group-icon icon-users"><TbUsers/></div><div className="group-title-text">Promo</div><div className="group-indicator icon-arrow_right"></div></a>                    <div className="group-text">Attract potential clients and target audience to your account</div>
                                                </div>
                                                <div className="menu-group-items">
                                                    <div className="group-items-header">
                                                        <div className="items-header-icon icon-users"><TbUsers/></div>
                                                        <a className="items-header-title" href="https://bot.inflact.com/">Promo</a>
                                                        <div className="items-header-back">
                                                            <div className="back-icon icon-arrow_down"></div>
                                                            <div className="back-text">Back</div>
                                                        </div>
                                                    </div>
                                                    
                                                    <Link className="menu-item" to="/pricing" data-menu-action="close-menu">
                                                        <div className="item-icon icon-menu-pricing"><RiPriceTag3Line style={{color:"black"}}/></div>
                                                        <div className="item-title">Pricing</div>
                                                    </Link>
                                                    <Link className="menu-item" to="/FAQ" data-menu-action="close-menu">
                                                        <div className="item-icon icon-menu-faq"><RiQuestionAnswerLine/></div>
                                                        <div className="item-title">FAQ</div>
                                                    </Link>
                                                   
                                                </div>
                                            </div>
                                            <div className="menu-group">
                                                <div className="menu-group-header">
                                                    <a className="group-title" href="/video"><div className="group-icon icon-direct_menu"><TbSend/></div><div className="group-title-text">Direct</div><div className="group-indicator icon-arrow_right"></div></a>                    <div className="group-text">Organize your DM for money-making with the all-in-one online chat and CRM for Instagram</div>
                                                </div>
                                                <div className="menu-group-items">
                                                    <div className="group-items-header">
                                                        <div className="items-header-icon "><TbSend /></div>
                                                        <a className="items-header-title" href="/direct-messages/">Direct</a>
                                                        <div className="items-header-back">
                                                            <div className="back-icon icon-arrow_down"></div>
                                                            <div className="back-text">Back</div>
                                                        </div>
                                                    </div>
                                                    <a className="menu-item" href="/direct-messages/#categories" data-menu-action="close-menu"><div className="item-icon icon-menu-categories"><BiCategory/></div><div className="item-title">Clients Categories</div></a>                    <a className="menu-item" href="/direct-messages/#auto-replies" data-menu-action="close-menu"><div className="item-icon icon-menu-autoreplies"><RiReplyAllLine/></div><div className="item-title">Clients Auto-Replies</div></a>                    <a className="menu-item" href="/direct-messages/#videos" data-menu-action="close-menu"><div className="item-icon icon-menu-kanban"><TbDeviceDesktopAnalytics/></div><div className="item-title">Kanban Desktops</div></a>                    <Link className="menu-item" to="/pricing" data-menu-action="close-menu"><div className="item-icon icon-menu-pricing"><RiPriceTag3Line/></div><div className="item-title">Pricing</div></Link>
                                                                     
                                                      <Link className="menu-item" to="/FAQ" data-menu-action="close-menu">
                                                      <div className="item-icon icon-menu-faq"><RiQuestionAnswerLine/></div>
                                                      <div className="item-title">FAQ</div>
                                                      </Link>                  
                                                      </div>
                                            </div>
                                            <div className="menu-group">
                                                <div className="menu-group-header">
                                                    <a className="group-title" href="/schedular"><div className="group-icon icon-plus">< RiAddBoxLine /></div><div className="group-title-text">Posting</div><div className="group-indicator icon-arrow_right"></div></a>                    <div className="group-text">Schedule IG content, preview the feed, use the auto-updated description</div>
                                                </div>
                                                <div className="menu-group-items">
                                                    <div className="group-items-header">
                                                        <div className="items-header-icon icon-plus">< RiAddBoxLine /></div>
                                                        <a className="items-header-title" href="/scheduled-posting/">Posting</a>
                                                        <div className="items-header-back">
                                                            <div className="back-icon icon-arrow_down"></div>
                                                            <div className="back-text">Back</div>
                                                        </div>
                                                    </div>
                                                    <a className="menu-item" href="/scheduled-posting/#how-it-works" data-menu-action="close-menu"><div className="item-icon icon-menu-hitworks"><RiPlayCircleLine/></div><div className="item-title">How it works</div></a>                    <Link className="menu-item" to="/pricing" data-menu-action="close-menu"><div className="item-icon icon-menu-pricing"><RiPriceTag3Line/></div><div className="item-title">Pricing</div></Link>                    
                                                    <Link className="menu-item" to="/FAQ" data-menu-action="close-menu">
                                                    <div className="item-icon icon-menu-faq"><RiQuestionAnswerLine/></div>
                                                    <div className="item-title">FAQ</div>
                                                    </Link>                  
                                                    </div>
                                            </div>
                                        </div>
                                        <div className="menu-section-info">
                                            <div className="section-info-title">
                                                <div className="info-title-icon icon-menu-services">< RxRocket/></div>
                                                <div className="info-title-text">Services</div>
                                            </div>
                                            <div className="section-info-text">Our Instagram marketing services are made for growth. Must-have for business, influencers, digital marketing specialists</div>
                                            <div className="section-info-buttons">
                                                <a className="btn info-button" href="/tutorial/">
                                                    <div className="btn-text">Educate in the</div>
                                                    <div className="btn-icon icon-menu-lab-readmore"><HiOutlineBeaker/></div>
                                                    <div className="btn-text">Lab</div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="menu-section" data-menu-section-type="complex" onMouseOver={(e) => colorChange("two")} onMouseLeave={outChange}>
                                <div className="menu-section-header" style={{ color: isHovered ? (isHovered2 ? 'white' : '#6994f1') : `${props.link_color}` }} onMouseOver={colorChange2} onMouseLeave={outChange2}>
                                    Tools            
                                    <div className="menu-section-icon "><MdKeyboardArrowDown style={{ verticalAlign: 'middle' }} />
                                    </div>
                                </div>
                                <div className="menu-section-body">
                                    <div className="menu-section-container">
                                        <div className="menu-groups-list">
                                            <div className="menu-group">
                                                <div className="menu-group-header">
                                                    <div className="group-title">
                                                        {/* <div className="group-icon icon-menu-hashtags"><RiHashtag /></div> */}
                                                        {/* <div className="group-title-text"><br/>{'\t'}{'\t'}{'\t'}</div> */}
                                                        <div className="group-indicator icon-arrow_right"></div>
                                                    </div>
                                                    {/* <div className="group-text">Boost posts discoverability, get famous in your niche organically</div> */}
                                                </div>
                                                <div className="menu-group-items">
                                                    <div className="group-items-header">
                                                        {/* <div className="items-header-icon icon-menu-hashtags"><RiHashtag /></div> */}
                                                        {/* <div className="group-title-text"><br/>{'\t'}{'\t'}{'\t'}</div> */}
                                                        {/* <div className="items-header-title">Hashtags</div> */}
                                                        <div className="items-header-back">
                                                            <div className="back-icon icon-arrow_down"></div>
                                                            {/* <div className="back-text">Back</div> */}
                                                        </div>
                                                    </div>
                                                    <a className="menu-item" href="/tools/instagram-hashtag-generator/">
                                                    <div className="item-icon icon-menu-hashgen">
                                                        {/* <RiAiGenerate /> */}
                                                        </div>
                                                    {/* <div className="item-title">Generator</div> */}
                                                    </a>
                                                    <a className="menu-item" href="/tools/instagram-top-hashtags/">
                                                    <div className="item-icon icon-menu-trends">
                                                        {/* < RxRocket /> */}
                                                        </div>
                                                    {/* <div className="item-title">Trends</div> */}
                                                    </a>
                                                    </div>
                                            </div>
                                            {/* <div className="menu-group">
                                                <div className="menu-group-header">
                                                    <div className="group-title">
                                                        <div className="group-icon icon-menu-profiles"><TbUserSquareRounded /></div>
                                                        <div className="group-title-text">Profiles</div>
                                                        <div className="group-indicator icon-arrow_right"></div>
                                                    </div>
                                                    <div className="group-text">Get precise info about competitors, influencers, target clientele</div>
                                                </div>
                                                <div className="menu-group-items">
                                                    <div className="group-items-header">
                                                        <div className="items-header-icon icon-menu-profiles"><TbUserSquareRounded /></div>
                                                        <div className="items-header-title">Profiles</div>
                                                        <div className="items-header-back">
                                                            <div className="back-icon icon-arrow_down"></div>
                                                            <div className="back-text">Back</div>
                                                        </div>
                                                    </div>
                                                    <a className="menu-item" href="/tools/profile-analyzer/"><div className="item-icon icon-menu-analize"><TbDeviceAnalytics /></div><div className="item-title">Analyzer</div></a>                    <a className="menu-item" href="/tools/instagram-search/"><div className="item-icon icon-menu-search"><TbDeviceIpadSearch /></div><div className="item-title">Search</div></a>                    <a className="menu-item" href="/tools/stories-viewer/"><div className="item-icon icon-menu-viewer">< TbHistoryToggle /></div><div className="item-title">Stories Viewer</div></a>                    <a className="menu-item" href="/profiles/instagram-viewer/"><div className="item-icon icon-menu-stalker"><LuView /></div><div className="item-title">Viewer for Instagram</div></a>                  </div>
                                            </div> */}
                                            <div className="menu-group">
                                                <div className="menu-group-header">
                                                    <a className="group-title" href="/creator/easyfind/"><div className="group-icon icon-menu-downloaders">
                                                        < RiHashtag />
                                                        {/* BsBoxArrowInDown */}
                                                    </div>
                                                    <div className="group-title-text">EasyFind</div>
                                                    <div className="group-indicator icon-arrow_right"></div>
                                                    </a>                    
                                                    <div className="group-text">Discover latest trends in your niche and go viral.</div>
                                                </div>
                                                <div className="menu-group-items">
                                                    <div className="group-items-header">
                                                        <div className="items-header-icon icon-menu-downloaders">< RiHashtag /></div>
                                                        <a className="items-header-title" href="/creator/easyfind/">EasyFind</a>                      
                                                        <div className="items-header-back">
                                                            <div className="back-icon icon-arrow_down"></div>
                                                            <div className="back-text">Back</div>
                                                        </div>
                                                    </div>
                                                    {/* <a className="menu-item" href="/downloader/facebook/"><div className="item-icon icon-menu-fb"><RiFacebookCircleLine /></div><div className="item-title">Facebook</div></a>                    <a className="menu-item" href="/downloader/twitter/"><div className="item-icon icon-menu-twitter"><RiTwitterLine /></div><div className="item-title">Twitter</div></a> */}
                                                    {/* <div className="menu-item">
                                                        <a className="menu-item" href="/downloader/instagram/" style={{ margin: '0 -14px' }}>
                                                            <div className="item-icon icon-menu-insta"><RiInstagramLine /></div>
                                                            <div className="item-title">Instagram</div>
                                                        </a>
                                                        <div className="sub-items">
                                                            <a className="sub-item" href="/downloader/instagram/photo/">Photo</a>                        <a className="sub-item" href="/downloader/instagram/video/">Video</a>                        <a className="sub-item" href="/downloader/instagram/profile/">Profile</a>                        <a className="sub-item" href="/downloader/instagram/stories/">Stories</a>                        <a className="sub-item" href="/downloader/instagram/igtv/">IGTV</a>                        <a className="sub-item" href="/downloader/instagram/avatar/">DP</a>                        <a className="sub-item" href="/downloader/instagram/reels/">Reels</a>                      </div>
                                                    </div>
                                                    <a className="menu-item" href="/downloader/facebook/"><div className="item-icon icon-menu-fb"><RiFacebookCircleLine /></div><div className="item-title">Facebook</div></a>                    <a className="menu-item" href="/downloader/twitter/"><div className="item-icon icon-menu-twitter"><RiTwitterLine /></div><div className="item-title">Twitter</div></a>
                                                    <a className="menu-item" href="/downloader/tiktok/"><div className="item-icon icon-menu-fb"><RiTiktokLine /></div><div className="item-title">TikTok</div></a> */}
                                                    {/* <a className="menu-item" href="/downloader/tiktok/">
                                                        <svg className="item-icon" version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                            width="18" height="20" viewBox="0 0 316.000000 360.000000"
                                                            preserveAspectRatio="xMidYMid meet">
                                                            <g transform="translate(0.000000,360.000000) scale(0.100000,-0.100000)">
                                                                <path d="M1623 3590 c-12 -5 -26 -18 -32 -29 -8 -14 -11 -397 -11 -1243 0
-1350 3 -1287 -63 -1397 -89 -148 -274 -234 -439 -203 -186 35 -327 178 -358
363 -48 281 213 539 493 490 103 -19 128 -17 149 9 17 20 18 48 18 334 l0 313
-26 24 c-25 24 -28 24 -212 23 -168 0 -197 -3 -284 -27 -302 -80 -568 -292
-717 -567 -52 -96 -106 -262 -122 -369 -18 -129 -7 -333 26 -456 50 -192 153
-369 293 -511 292 -294 710 -406 1111 -299 383 103 702 427 800 812 35 136 41
266 41 839 l0 553 53 -30 c181 -107 413 -175 633 -186 189 -9 174 -39 174 345
0 365 7 345 -120 356 -108 10 -191 31 -275 70 -270 125 -439 364 -464 655 -8
85 -11 97 -35 118 -27 23 -28 23 -319 22 -161 0 -302 -4 -314 -9z m522 -192
c51 -328 246 -591 539 -729 83 -39 216 -79 262 -79 55 0 54 4 54 -211 l0 -199
-28 0 c-51 0 -216 32 -297 57 -110 35 -246 101 -353 173 -50 33 -99 60 -110
60 -10 0 -31 -9 -45 -20 l-26 -21 -4 -712 c-3 -612 -6 -721 -20 -777 -77 -311
-261 -546 -532 -681 -145 -72 -266 -100 -430 -101 -152 0 -250 17 -373 67
-164 67 -322 187 -429 329 -60 79 -137 240 -165 341 -18 66 -21 109 -22 245
-1 144 2 176 23 250 81 291 276 525 545 650 118 56 229 82 374 88 l122 5 0
-204 0 -205 -87 0 c-276 -2 -511 -197 -568 -469 -18 -88 -18 -142 0 -230 23
-110 77 -209 159 -291 181 -182 442 -225 675 -112 92 45 215 169 260 262 63
132 61 85 61 1379 l0 1177 204 0 204 0 7 -42z"/>
                                                            </g>
                                                        </svg>


                                                        <div className="item-title">TikTok</div></a>                  */}
                                                </div>
                                            </div>
                                            <div className="menu-group">
                                                <div className="menu-group-header">
                                                    <a className="group-title" href="/creator/easyedit/">
                                                    <div className="group-icon icon-keyword-b">
                                                        <BsBoxArrowInDown />
                                                        </div>
                                                        <div className="group-title-text">EasyEdit</div>
                                                        <div className="group-indicator icon-arrow_right"></div>
                                                        </a>                    
                                                    <div className="group-text">Upload video and get your own template.</div>
                                                </div>
                                                <div className="menu-group-items">
                                                    <div className="group-items-header">
                                                        <div className="items-header-icon icon-keyword-b"><BsBoxArrowInDown /></div>
                                                        <a className="items-header-title" href="/creator/easyedit/">EasyEdit</a>                     
                                                        <div className="items-header-back">
                                                            <div className="back-icon icon-arrow_down"></div>
                                                            <div className="back-text">Back</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="menu-section-info">
                                            <div className="section-info-title">
                                                <div className="info-title-icon icon-menu-tools">< GrServices /></div>
                                                <div className="info-title-text">Tools</div>
                                            </div>
                                            <div className="section-info-text">Improve your content strategy with simple influenzo tools. Use them now</div>
                                            <div className="section-info-buttons">
                                                <a className="btn info-button" href="/tutorial/">
                                                    <div className="btn-text">Educate in the</div>
                                                    <div className="btn-icon icon-menu-lab-readmore"><HiOutlineBeaker /></div>
                                                    <div className="btn-text">Lab</div>
                                                </a>                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="menu-section" data-menu-section-type="complex" onMouseOver={(e)=>colorChange("three")} onMouseLeave={outChange}>
                                <div className="menu-section-header" style={{ color: isHovered ? (isHovered3 ? 'white' : '#6994f1' ):  `${props.link_color}`}} onMouseOver={colorChange3} onMouseLeave={outChange3}>
                                    Lab <div className="menu-section-icon "><MdKeyboardArrowDown style={{ verticalAlign: 'middle' }} /></div>
                                </div>
                                <div className="menu-section-body">
                                    <div className="menu-section-container">
                                        <div className="menu-groups-list">
                                            <div className="menu-group">
                                                <div className="menu-group-header">
                                                    <a className="group-title" href="/blog/">
                                                        <div className="group-icon icon-menu-blog"><RiBloggerLine/></div>
                                                        <div className="group-title-text">Blog</div>
                                                        <div className="group-indicator icon-arrow_right"></div>
                                                    </a>
                                                    <div className="group-text"></div>
                                                </div>
                                                <div className="menu-group-items">
                                                    <div className="group-items-header">
                                                        <div className="items-header-icon icon-menu-blog"><RiBloggerLine/></div>
                                                        <a className="items-header-title" href="/blog/">Blog</a>
                                                        <div className="items-header-back">
                                                            <div className="back-icon icon-arrow_down"></div>
                                                            <div className="back-text">Back</div>
                                                        </div>
                                                    </div>
                                                    <a className="menu-item" href="/blog/category/news/">
                                                        <div className="item-icon icon-menu-news"><BsFire/></div>
                                                        <div className="item-title">News</div>
                                                    </a> <a className="menu-item" href="/blog/category/article/">
                                                        <div className="item-icon icon-menu-articles"><TbBulb/></div>
                                                        <div className="item-title">Articles</div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="menu-group">
                                                <div className="menu-group-header">
                                                    <a className="group-title" href="/tutorial/">
                                                        <div className="group-icon icon-menu-tutorials"><TbArrowGuide /></div>
                                                        <div className="group-title-text">Tutorials</div>
                                                        <div className="group-indicator icon-arrow_right"></div>
                                                    </a>
                                                    <div className="group-text"></div>
                                                </div>
                                                <div className="menu-group-items">
                                                    <div className="group-items-header">
                                                        <div className="items-header-icon icon-menu-tutorials"><TbArrowGuide /></div>
                                                        <a className="items-header-title" href="/tutorial/">Tutorials</a>
                                                        <div className="items-header-back">
                                                            <div className="back-icon icon-arrow_down"></div>
                                                            <div className="back-text">Back</div>
                                                        </div>
                                                    </div>
                                                    <a className="menu-item" href="/tutorial/category/beginner/">
                                                        <div className="item-icon icon-ingramer_begin_s_24px-regular"><TbArrowGuide /></div>
                                                        <div className="item-title">Guide</div>
                                                    </a> <a className="menu-item" href="/tutorial/category/promotion/">
                                                        <div className="item-icon icon-menu-promotions"><BsBarChart/></div>
                                                        <div className="item-title">Promotions</div>
                                                    </a> <a className="menu-item" href="/tutorial/category/sales/">
                                                        <div className="item-icon icon-menu-sales"><RiShoppingBagLine/></div>
                                                        <div className="item-title">Sales</div>
                                                    </a> <a className="menu-item" href="/tutorial/category/content/">
                                                        <div className="item-icon icon-menu-content"><MdContentPaste/></div>
                                                        <div className="item-title">Content</div>
                                                    </a> <a className="menu-item" href="/tutorial/category/niche/">
                                                        <div className="item-icon icon-menu-niches"><TfiThought/></div>
                                                        <div className="item-title">Niches</div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="menu-group">
                                                <div className="menu-group-header">
                                                    <a className="group-title" href="/support/">
                                                        <div className="group-icon icon-menu-support"><TfiHeadphoneAlt/></div>
                                                        <div className="group-title-text">Support</div>
                                                        <div className="group-indicator icon-arrow_right"></div>
                                                    </a>
                                                    <div className="group-text"></div>
                                                </div>
                                                <div className="menu-group-items">
                                                    <div className="group-items-header">
                                                        <div className="items-header-icon icon-menu-support"><TfiHeadphoneAlt/></div>
                                                        <a className="items-header-title" href="/support/">Support</a>
                                                        <div className="items-header-back">
                                                            <div className="back-icon icon-arrow_down"></div>
                                                            <div className="back-text">Back</div>
                                                        </div>
                                                    </div>
                                                    <a className="menu-item" href="/support/#support_form">
                                                        <div className="item-icon icon-menu-mail"><RiMailLine/></div>
                                                        <div className="item-title">hello@influenzo.com</div>
                                                    </a>
                                                    <Link className="menu-item" to="/FAQ" data-menu-action="close-menu">
                                                        <div className="item-icon icon-menu-faq"><RiQuestionAnswerLine/></div>
                                                        <div className="item-title">Faq</div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="menu-section-info">
                                            <div className="section-info-title">
                                                <div className="info-title-icon icon-menu-lab"><HiOutlineBeaker/></div>
                                                <div className="info-title-text">Lab</div>
                                            </div>
                                            <div className="section-info-text">
                                                Feel exhausted from guessing hashtags each time you post on Facebook, Twitter, Youtube, or
                                                Instagram? </div>
                                            <div className="section-info-buttons">
                                                <a className="btn info-button" href="https://www.youtube.com/@inflacttube8574/"
                                                    rel="nofollow">
                                                    <div className="btn-text">Join us on</div>
                                                    <div className="btn-icon icon-menu-youtube"><TbBrandYoutube color="red"/></div>
                                                    <div className="btn-text">Youtube</div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div id="menu-buttons-pjax" className="menu-buttons" data-pjax-container="" data-pjax-push-state >
                            <a className={mode} href="" data-pjax="0" data-toggle="" data-target="" onClick={signin} style={{ background: `${props.border_color}` }} onMouseOver={props.handleover} onMouseLeave={props.handleleave}>
                                Sign In
                            </a>
                            {/* <a className="btn btn-outline-primary" href="/signup/" data-pjax="0" data-toggle="" data-target="">
                                <div className="btn-text">Sign up</div>
                            </a> */}
                        </div>

                    </div>
                </div>
            </nav >
        </>
    )
}

Nav.propTypes = {
    border_color: PropTypes.string, // Current value of the draggable element
    link_color: PropTypes.string, // Total range for the draggable element
    handleover: PropTypes.func, // Callback function for value changes
    handleleave: PropTypes.func
};

export default Nav