import React from "react";

import { IoLogoFacebook } from "react-icons/io";

import logo from '../../assets/icons/InstaPage/instaLogo.svg';
import appstore from '../../assets/icons/InstaPage/appstore.png';
import googleplay from '../../assets/icons/InstaPage/googleplay.png';

import igStyle from '../../css/InstaPage/Iglogin.module.css';
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";

const IgLogin = () => {
    return (
        <div className={igStyle.wrapper}>
            <div className={igStyle.header}>
                <div className={igStyle.top}>
                    <div className={igStyle.logo}>
                        <img className={igStyle.img} src={logo} alt="instagram" style={{ width: '175px' }} />
                    </div>
                    <div className={igStyle.form}>
                        <div className={igStyle.input_field}>
                            <input type="text" placeholder="Phone number, username, or email" className={igStyle.input} />
                        </div>
                        <div className={igStyle.input_field}>
                            <input type="password" placeholder="Password" className={igStyle.input} />
                        </div>
                        <div className={igStyle.login_btn}><button className={igStyle.btn}>Log in</button></div>
                    </div>
                    <div className={igStyle.or}>
                        <hr className={igStyle.line} style={{color:'rgb(219,219,219)'}}/>
                        <p className={igStyle.orPara}>OR</p>
                        {/* <div className={igStyle.line}></div> */}
                    </div>
                    <div className={igStyle.dif}>
                        <div className={igStyle.fb}>
                            <IoLogoFacebook  style={{ color: '#385185',verticalAlign:'middle'}} size={20} />
                            <p className={igStyle.fbPara}>Log in with Facebook</p>
                        </div>
                        <div className={igStyle.forgot}>
                            <a className={igStyle.forgotLink} href="#">Forgot password?</a>
                        </div>
                    </div>
                </div>
                <div className={igStyle.signup}>
                    <p className={igStyle.text}>Don&apos;t have an account? <a className={igStyle.signuplink} href="#">Sign up</a></p>
                </div>
                <div className={igStyle.apps}>
                    <p className={igStyle.getPara}>Get the app.</p>
                    <div className={igStyle.icons}>
                        <a className={igStyle.iconsAnchor} href="#"><img className={igStyle.iconsImg} src={appstore} alt="appstore" /></a>
                        <a className={igStyle.iconsAnchor} href="#"><img className={igStyle.iconsImg} src={googleplay} alt="googleplay" /></a>
                    </div>
                </div>
            </div>
            <div className={igStyle.footer}>
                <div className={igStyle.links}>
                    <a className={igStyle.linksAnchor} href="#">ABOUT US</a>
                    <a className={igStyle.linksAnchor} href="#">SUPPORT</a>
                    <a className={igStyle.linksAnchor} href="#">PRESS</a>
                    <a className={igStyle.linksAnchor} href="#">API</a>
                    <a className={igStyle.linksAnchor} href="#">JOBS</a>
                    <a className={igStyle.linksAnchor} href="#">PRIVACY</a>
                    <a className={igStyle.linksAnchor} href="#">TERMS</a>
                    <a className={igStyle.linksAnchor} href="#">DIRECTORY</a>
                    <a className={igStyle.linksAnchor} href="#">PROFILES</a>
                    <a className={igStyle.linksAnchor} href="#">HASHTAGS</a>
                    <a className={igStyle.linksAnchor} href="#">LANGUAGE</a>
                </div>
                <div className={igStyle.copyright}>
                    © 2024 INSTAGRAM
                </div>
            </div>
        </div>
    )
}
export default IgLogin;