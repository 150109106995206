import React, { useEffect } from 'react'
import Footer from '../../Landing_Page/Footer'
import Nav from '../../Landing_Page/Nav'
import ProductCategories from "./pages/ProductCategories";
import ProductSmokingHero from "./pages/ProductSmokingHero";
import { ThemeProvider } from "@mui/material/styles";
import theme from "assets/theme";
import CssBaseline from "@mui/material/CssBaseline";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import SoftBox from "../../../SoftBox";
import SoftTypography from "../../../SoftTypography";
import SoftButton from "../../../SoftButton";

// About Us page sections
import Information from "./pages/Information";
import Team from "./pages/Team";
import Featuring from "./pages/Featuring";
import Newsletter from "./pages/Newsletter";

// Images
import bgImage from "assets/images/bg-about-us.jpg";



const About = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>

        <Nav link_color={'black'} border_color={'var(--text-400-color)'}/>
        <SoftBox
          minHeight="75vh"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.6),
                rgba(gradients.dark.state, 0.6)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        >


          <Container>
            <Grid
              container
              item
              xs={12}
              lg={8}
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              sx={{ mx: "auto", textAlign: "center" }}
            >
              <SoftTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                })}
              >
                Work with an amazing design
              </SoftTypography>
              <SoftTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                We&apos;re constantly trying to express ourselves and actualize our dreams. If you
                have the opportunity to play this game
              </SoftTypography>
              <SoftButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>
                create account
              </SoftButton>
              <SoftTypography variant="h6" color="white" mt={8} mb={1}>
                Find us on
              </SoftTypography>
              <SoftBox display="flex" justifyContent="center" alignItems="center">
                <SoftTypography component="a" variant="body1" color="white" href="#" mr={3}>
                  <i className="fab fa-facebook" />
                </SoftTypography>
                <SoftTypography component="a" variant="body1" color="white" href="#" mr={3}>
                  <i className="fab fa-instagram" />
                </SoftTypography>
                <SoftTypography component="a" variant="body1" color="white" href="#" mr={3}>
                  <i className="fab fa-twitter" />
                </SoftTypography>
                <SoftTypography component="a" variant="body1" color="white" href="#">
                  <i className="fab fa-google-plus" />
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Container>
        </SoftBox>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Information />
          <Team />
          <ProductCategories />
          <Featuring />
          <Newsletter />
        </Card>

        <ProductSmokingHero />
        <Footer />
      </div>
    </ThemeProvider>
  )
}

export default About