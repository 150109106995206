import React ,{useState, useEffect}from 'react';
import { MdOutlinePermMedia, MdOutlineCloudUpload } from "react-icons/md";
import { useGlobalState } from "../Store/Store";
import PropTypes from "prop-types";
import style from "../../../../css/VideoEditor/video.module.css";

const UploadButton = ({ accept, multiple, className, onChange, toggle,resource }) => {
  // const [showTooltip, setShowTooltip] = useState(true); // State to control tooltip visibility

  // // Show the tooltip on component mount
  // useEffect(() => {
  //   setShowTooltip(true);
  // }, []); // Empty dependency array means it runs only once, when the component mounts

  // const handleHideTooltip = () => {
  //   setShowTooltip(false); // Hide the tooltip on button click
  // };
  const fileInputRef = React.useRef(null);
  const store = useGlobalState();

  const handleClick = () => {
    console.log("acceprct", typeof (accept))
    console.log("multiple", typeof (multiple))
    console.log("classnmae", typeof (className))
    console.log("onchange", typeof (onChange))

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
    store.isAddButtonClicked.set(false);
  };

  return (
    <>
      {toggle ? (
        <>
          <button
            type="button"
            className={style.reelUploadButton}
            onClick={handleClick}
      
            // style={{ border: 'none' }}
          >
             Upload
             <MdOutlineCloudUpload size={16} style={{marginLeft:"4px", verticalAlign:'middle'}}/>
          </button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept={accept}
            onChange={onChange}
          />
        </>
      ) : (
        <>
          <button
            type="button"
            className={className}
            onClick={handleClick}
            // style={{ border: 'none' }}
          >
            {resource === "media" ? "Upload Media" : "Upload Audio"}
          </button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept={accept}
            multiple={multiple}
            onChange={onChange}
          />
        </>
      )}
    </>
  );
  
};

UploadButton.propTypes = {
  accept: PropTypes.string.isRequired, // Current value of the draggable element
  multiple: PropTypes.bool, // Total range for the draggable element
  className: PropTypes.string.isRequired, // Whether the draggable element is disabled
  onChange: PropTypes.func.isRequired, // Callback function for value changes
  toggle: PropTypes.bool.isRequired,
  resource: PropTypes.string,
};
export default UploadButton;
