import React from "react";
import { Route, Routes, BrowserRouter, useLocation } from "react-router-dom";
import routes from "./routes";

import { ThemeProvider } from "@mui/material/styles";
import theme from "assets/theme";
import CssBaseline from "@mui/material/CssBaseline";
import brand from "assets/images/logo-ct.png";
import Sidenav from "examples/Sidenav";
import { useState, useEffect } from "react";
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";


// Soft UI Dashboard React Context Provider
// import { SoftUIControllerProvider } from "context";

// const SubAppWrapper = () => (

//     <SoftUIControllerProvider>
//       <Analysis />
//     </SoftUIControllerProvider>

// );

const getRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route) {
      return <Route exact path={route.route} element={route.component} key={route.key} />;
    }

    return null;
  });

const { path } = 'creator'
// const root = ReactDOM.createRoot(document.getElementById('root'));
const SubAppWrapper = () => {
  console.log("in subwrapper");
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav,  sidenavColor } = controller;
  const { pathname } = useLocation();
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return(
    <>

    <ThemeProvider theme={theme}>
    <CssBaseline  />
          <Sidenav
            color={sidenavColor}
            brand={brand}
            brandName="Soft UI Dashboard"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Routes>
            {getRoutes(routes)}
          </Routes>
    </ThemeProvider>
    </>
  );
}


export default SubAppWrapper;



