import styles from "../../../../css/VideoEditor/Anime.module.css";
import { MdOutlineDelete } from 'react-icons/md';
import PropTypes from 'prop-types';
import { updateAnimation,removeAnimation } from "../Store/Store";

const ANIMATION_TYPE_TO_LABEL = {
  fadeIn: "Fade In",
  fadeOut: "Fade Out",
  slideIn: "Slide In",
  slideOut: "Slide Out",
  breath: "Breath"
}

export const AnimationResource = ({animation}) => {

    console.log("props",animation)
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>
          {ANIMATION_TYPE_TO_LABEL[animation.type]}
        </div>
        <button
          className={styles.deleteButton}
          onClick={() => removeAnimation(animation.name)}
        >
          <MdOutlineDelete size="25" />
        </button>
      </div>
      {animation.type === "fadeIn" || animation.type === "fadeOut" ? (
        <FadeAnimation animation={animation} />
      ) : null}
      {animation.type === "slideIn" || animation.type === "slideOut" ? (
        <SlideAnimation animation={animation} />
      ) : null}
    </div>
  )
}

export const FadeAnimation = ({animation}) => {
  return (
    <div className="flex flex-col w-full items-start">
      <div className={styles.row}>
        <div className={styles.label}>Duration(s)</div>
        <input
          className={styles.inputField}
          type="number"
          value={animation.duration / 1000}
          onChange={e => {
            const duration = Number(e.target.value) * 1000;
            const isValidDuration = duration > 0;
            let newDuration = isValidDuration ? duration : 0;
            if (newDuration < 10) {
              newDuration = 10;
            }
            updateAnimation(animation.name, {
              ...animation,
              duration: newDuration
            });
          }}
        />
      </div>
    </div>
  )
}

export const SlideAnimation = ({animation}) => {
  return (
    <div className="flex flex-col w-full items-start">
      <div className={styles.row}>
        <div className={styles.label}>Duration(s)</div>
        <input
          className={styles.inputField}
          type="number"
          value={animation.duration / 1000}
          onChange={e => {
            const duration = Number(e.target.value) * 1000;
            const isValidDuration = duration > 0;
            let newDuration = isValidDuration ? duration : 0;
            if (newDuration < 10) {
              newDuration = 10;
            }
            updateAnimation(animation.name, {
              ...animation,
              duration: newDuration
            });
          }}
        />
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Direction</div>
        <select
          className={styles.selectField}
          value={animation.properties.direction}
          onChange={e => {
            updateAnimation(animation.name, {
              ...animation,
              properties: {
                ...animation.properties,
                direction: e.target.value
              }
            });
          }}
        >
          <option value="left">Left</option>
          <option value="right">Right</option>
          <option value="top">Top</option>
          <option value="bottom">Bottom</option>
        </select>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Use Mask</div>
        <input
          className={styles.inputField}
          type="checkbox"
          checked={animation.properties.useClipPath}
          onChange={e => {
            updateAnimation(animation.name, {
              ...animation,
              properties: {
                ...animation.properties,
                useClipPath: e.target.checked
              }
            });
          }}
        />
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Type</div>
        <select
          className={styles.selectField}
          value={animation.properties.textType}
          onChange={e => {
            updateAnimation(animation.name, {
              ...animation,
              properties: {
                ...animation.properties,
                textType: e.target.value
              }
            });
          }}
        >
          <option value="none">None</option>
          <option value="character">Character</option>
        </select>
      </div>
    </div>
  )
}

AnimationResource.propTypes = {
    animation: PropTypes.shape({
      type: PropTypes.oneOf([
        "fadeIn",
        "fadeOut",
        "slideIn",
        "slideOut",
        "breath"
      ]).isRequired,
      name: PropTypes.string.isRequired,
      duration: PropTypes.number.isRequired,
      properties: PropTypes.shape({
        direction: PropTypes.string,
        useClipPath: PropTypes.bool,
        textType: PropTypes.string
      })
    }).isRequired
  }

  FadeAnimation.propTypes = {
    animation: PropTypes.shape({
      name: PropTypes.string.isRequired,
      duration: PropTypes.number.isRequired
    }).isRequired
  }

  SlideAnimation.propTypes = {
    animation: PropTypes.shape({
      name: PropTypes.string.isRequired,
      duration: PropTypes.number.isRequired,
      properties: PropTypes.shape({
        direction: PropTypes.string.isRequired,
        useClipPath: PropTypes.bool.isRequired,
        textType: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }