import React, { useState, useRef, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { MdAdd } from "react-icons/md";
import SoftBox from "../../SoftBox";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "assets/theme";
import MediaResourcesPanel from "./MediaPanel/MediaResourcePanel";
import TimeLine from "./timeline/TimeLine";
import { useGlobalState, addScene, setCanvas, loadFFmpeg } from "./Store/Store";
import "./utils/fabric-utils";
import style from "../../../css/VideoEditor/video.module.css";
import api from "../../../backend_api";
import DownloadButton from "./MediaPanel/DownloadButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import UploadButton from "./MediaPanel/UploadButton";
import { CircularProgress, Backdrop } from '@mui/material';
function Video() {
  const store = useGlobalState();
  const [videoUrl, setVideoUrl] = useState("");
  const [sceneName, setSceneName] = useState("")
  const canvasRef = useRef(null)
  const [url, setUrl] = useState({ url: "", type: "" })
  const [create, setCreate] = useState(true)
  const [loading, setLoading] = useState(false)
  const [media_url, setMediaUrl] = useState(false)

  const location = useLocation();
  useEffect(() => {
    // Get query parameters
    const route_insta_reel_template = "/api/insta-reel-template/";

    const media_url = location.state?.media_url ?? null;
    const permalink = location.state?.parmalink ?? null;
    console.log("media_url = ", media_url);

    const fetchVideoAndScenes = () => {
      setLoading(true)
        console.log("fetching video")
        let sceneVideos = [];
        api.post(route_insta_reel_template, 
         {'mode': 'media_url', 
          'permalink': permalink, 
          'media_url': media_url }).then( response => {
            console.log(response.data.scenes);
            Object.keys(response.data.scenes).map((key) => {
              let sceneVideos1 = [...sceneVideos,
              { name: key, 
                src: 'video_' + key, 
                end: response.data.scenes[key].end * 1000, 
                start: response.data.scenes[key].start * 1000, 
                type: "video" 
              }
              ];
              sceneVideos = sceneVideos1;
            })
            console.log("sceneVideos", sceneVideos);
            store.sceneVideos.set(sceneVideos)
            store.isSceneVisible.set(true);
            store.isAddButtonClicked.set(true);
            addScene(sceneVideos);
            setCreate(false);
          }
        ).finally(() => {
          setLoading(false);
        })
      return sceneVideos;
    }

    if (media_url && permalink) {
      console.log("setting media url to", url);
      const sceneVideos = fetchVideoAndScenes();
      console.log("scenes_list = ", sceneVideos);
    }

  }, [location, store]);

  useEffect(() => {
    // This effect will run when the component mounts
    const initializeFFmpeg = async () => {
      if (!store.loaded.get()) {
        await loadFFmpeg();
      }
    };

    initializeFFmpeg();
  }, []);


  useEffect(() => {
    if (canvasRef.current) {
      const width = 198;
      const height = width * 16 / 9;

      const canvas = new fabric.Canvas(canvasRef.current, {
        height: height,
        width: width,
        backgroundColor: "#dadada",
      });

      fabric.Object.prototype.transparentCorners = false;
      fabric.Object.prototype.cornerColor = "black";
      fabric.Object.prototype.cornerStyle = "circle";
      fabric.Object.prototype.cornerStrokeColor = "black";
      fabric.Object.prototype.cornerSize = 10;
      fabric.Object.prototype.lockScalingFlip = true;
      fabric.Object.prototype.moving = true;

      setCanvas(canvas);

      fabric.util.requestAnimFrame(function render() {
        if (canvas && canvas.contextContainer) {
          canvas.renderAll();
        } else {
          console.error("Canvas or context is null. Skipping rendering.");
        }
        fabric.util.requestAnimFrame(render)
      })
      return () => {
        canvas.dispose();
      };
    }
  }, []);

  const handleFileChange = async (event) => {
    const files = event.target.files?.[0];
    if (!files) return;
    const url = URL.createObjectURL(files);
    const type = files.type.startsWith("video") ? "video" : "image";
    setUrl({ url: url, type: type })
  };

  const handleAddVideo = async (e) => {
    // getVideoLinkFromHtml(videoUrl)
    // e.preventDefault();
    // setLoading(true)
    // try {
    //   const response = await api.post("/api/insta-reel-template/", { url: videoUrl });
    //   const sceneVideos = response.data;

    //   store.isAddButtonClicked.set(true);
    //   addScene(sceneVideos);
    // } catch (error) {
    //   console.error("Error fetching scenes:", error);
    // }finally{
    // setLoading(false)
    // }

    const sceneVideos = [
      { name: "Scene1", src: "video1.mp4", end: 5000, start: 0, type: "video" },
      { name: "Scene2", src: "video2.mp4", end: 15000, start: 5000, type: "video" },
      { name: "Scene3", src: "video3.mp4", end: 20000, start: 15000, type: "video" },
      { name: "Scene4", src: "video4.mp4", end: 25000, start: 20000, type: "video" },
      { name: "Scene5", src: "video5.mp4", end: 35000, start: 25000, type: "video" },
      { name: "Scene6", src: "video6.mp4", end: 40000, start: 35000, type: "video" },
      { name: "Scene7", src: "video7.mp4", end: 45000, start: 40000, type: "video" },
      { name: "Scene8", src: "video8.mp4", end: 50000, start: 45000, type: "video" },
      { name: "Scene9", src: "video9.mp4", end: 55000, start: 50000, type: "video" },
      { name: "Scene10", src: "video10.mp4", end: 60000, start: 55000, type: "video" },
      { name: "Scene11", src: "video11.mp4", end: 65000, start: 60000, type: "video" },
      { name: "Scene12", src: "video12.mp4", end: 70000, start: 65000, type: "video" },
      { name: "Scene13", src: "video13.mp4", end: 75000, start: 70000, type: "video" },
      { name: "Scene14", src: "video14.mp4", end: 85000, start: 75000, type: "video" },
      { name: "Scene15", src: "video15.mp4", end: 90000, start: 85000, type: "video" },
    ];
    store.sceneVideos.set(sceneVideos)
    console.log("in index", store.sceneVideos.get())
    store.isSceneVisible.set(true);
    store.isAddButtonClicked.set(true);
    addScene(sceneVideos);
    setCreate(false)
  };


  return (
    <DashboardLayout>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 9999,
            }}
          >
            <CircularProgress size={16} sx={{color:'#008dda'}}/>
          </div>
        )} */}
        {loading && (
          <Backdrop
          open={true}
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        )}
        {/* <Nav border_color={"#17c1e8"} /> */}
        <SoftBox component="section" py={{ xs: 0, lg: 6 }} >
          <Container>
            <SoftBox
              width="100%"
              bgColor="white"
              borderRadius="xl"
              shadow="xl"
              mb={6}
              sx={{ overflow: "hidden" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} lg={5}>
                  <SoftBox p={2}>
                    <MediaResourcesPanel />
                  </SoftBox>
                </Grid>

                <Grid item xs={12} lg={7}>
                  {/* <SoftBox component="form" p={2} method="post"> */}
                  <SoftBox p={2} >
                    <Grid container spacing={2} pr={1} px={3} alignItems="center">
                      <Grid item xs={12} >
                        <SoftBox display="flex" alignItems="center" justifyContent="end">
                          {/* <SoftInput
                            variant="standard"
                            placeholder="Enter URL here..."
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={videoUrl}
                            onChange={(e) => setVideoUrl(e.target.value)}
                          /> */}
                          {url && url.type === "video" &&
                            <video src={url.url} width={20} height={30} ></video>
                            // <img src={url.url} width={20} height={30} ></img>
                          }
                          {url && url.type === "image" &&
                            // <video src={url.url} width={20} height={30} ></video> :
                            <img src={url.url} width={20} height={30} ></img>
                          }
                          <UploadButton
                            accept="image/*,video/mp4,video/x-m4v,video/*"
                            // multiple
                            className="bg-transparent text-center mx-2 mb-2 py-2 px-2 cursor-pointer border-none"
                            onChange={handleFileChange}
                            toggle={true}
                          />
                          <button
                            className={style.reelUploadButton}
                            onClick={handleAddVideo}
                          // onClick={() => getVideoLinkFromHtml(videoUrl)}
                          >
                            {create ? (<>Create
                              {/* {loading ? <CircularProgress size={16} sx={{color:'white'}}/> : <MdAdd size="16" style={{ verticalAlign: 'middle', marginLeft: "4px", }} />} */}
                              <MdAdd size="16" style={{ verticalAlign: 'middle', marginLeft: "4px", }} />
                            </>) : ("Created")}
                          </button>
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12}>
                        <SoftBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          width="100%"
                          height="100%"
                          style={{ minHeight: "300px" }}
                        >
                          <canvas id="canvas" ref={canvasRef}></canvas>
                        </SoftBox>
                      </Grid>
                    </Grid>
                  </SoftBox>
                </Grid>
              </Grid>
              <DownloadButton />
            </SoftBox>

            <Grid container spacing={2} style={{ paddingTop: "0px", overflowX: "visible", }}>
              <Grid item xs={12} style={{ paddingTop: "0px", overflowX: "visible", }}>
                <SoftBox
                  sx={{
                    position: "relative",
                    overflowX: "visible",
                    backgroundColor: "white",
                  }}>
                  <TimeLine/>
                  {/* {loading ? <CircularProgress size={16} sx={{color:'#008dda'}}/> : <TimeLine/>} */}
                </SoftBox>
              </Grid>
            </Grid>
          </Container>
        </SoftBox>
      </ThemeProvider>
    </DashboardLayout>
  );
}

export default Video;