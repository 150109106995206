import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { MdEmail, MdOutlineWifiPassword } from "react-icons/md";
import { EmailVerify, OtpVerify, Reset } from './ForgetPassword'
import { FaGoogle, FaFacebookF, FaUser } from "react-icons/fa";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../../constants"
import { IoInformationCircleOutline, IoCheckmarkCircleOutline } from "react-icons/io5";
import api from "../../../backend_api"

import logo from '../../../assets/icons/LoginPage/login.svg'
import logo2 from '../../../assets/icons/LoginPage/signup.svg'

import Password from "./Password";

import '../../../css/theme.css'
import lsStyle from '../../../css/LoginPage/Login.module.css'
import { CircularProgress } from '@mui/material';

const Login = () => {
    const [signupmode, setSignupmode] = useState(lsStyle.wrapper);
    const [lshowPassword, setlShowPassword] = useState(true);
    const [sshowPassword, setsShowPassword] = useState(true);
    // const [signinError, setSigninError] = useState("");
    const [signupError, setSignupError] = useState("");
    const [lError, setLerror] = useState("");
    const [sError, setSerror] = useState("");
    const [isError, setisError] = useState(false);
    const [forgotPasswordStep, setForgotPasswordStep] = useState(1);
    const [loading, setLoading] = useState(false)

    const [loginForm, setLoginForm] = useState({
        loginEmail: "",
        loginPassword: "",
    })

    const [signupForm, setSignupForm] = useState({
        username: "",
        signupEmail: "",
        signupPassword: "",
    })

    const eye_toggle = () => {
        setlShowPassword(!lshowPassword);
    };

    const eye_toggle1 = () => {
        setsShowPassword(!sshowPassword);
    };

    const login = (e) => {
        e.preventDefault();
        setSignupmode(lsStyle.wrapper);
    }

    const signup = (e) => {
        e.preventDefault();
        setSignupmode(lsStyle.wrapper + ' ' + lsStyle['sign-up-mode']);
    }

    const signupbtn = (e) => {
        e.preventDefault();
        setSignupmode(lsStyle.wrapper + ' ' + lsStyle['sign-up-mode2']);
    }

    const navigate = useNavigate();

    const signup_submit = async (e) => {
        e.preventDefault();
        setLoading(true)
        let form = new FormData(e.target);
        let formObj = Object.fromEntries(form.entries());
        console.log(formObj);
        const route_signup = "/api/user/register/";
        const route_signin = "/api/token/";
        // console.table(name, signupEmail, signupPass);
        try {
            localStorage.clear();
            const res_signup = await api.post(route_signup, { 'username': formObj.username, 'email': formObj.signupEmail, 'password': formObj.signupPassword });
            const res = await api.post(route_signin, { 'email_or_username': formObj.signupEmail, 'password': formObj.signupPassword });
            localStorage.setItem(ACCESS_TOKEN, res.data.access);
            localStorage.setItem(REFRESH_TOKEN, res.data.refresh);
            console.log(res.data.access);
            console.log(res.data.refresh);
            navigate('/details');
        }
        catch (error) {
            // console.log("error ===", error.response.data);
            const smessage = error.response.data;
            console.log(smessage['username'][0]);
            setisError(true);
            setSerror(smessage['username'][0]);
        } finally {
            setLoading(false)
        }

    }

    const login_submit = async (e) => {
        e.preventDefault();
        setLoading(true)
        let form = new FormData(e.target);
        let formObj = Object.fromEntries(form.entries());
        console.log(formObj);
        const route = "/api/token/";

        try {
            const res = await api.post(route, { 'email_or_username': formObj.loginEmail, 'password': formObj.loginPassword })
            localStorage.setItem(ACCESS_TOKEN, res.data.access);
            localStorage.setItem(REFRESH_TOKEN, res.data.refresh);
            navigate("/");
        }
        catch (error) {
            // alert(error);
            // console.log(error.response.data);
            const message = error.response.data;
            console.log(message['detail']);
            setLerror(message['detail'])
            // setLerror(error.response.data);
            // console.log(lError);
        } finally {
            setLoading(false)
        }
    }

    // const checkLoginPasswordStrength = (password) => {
    //     // Call the Password function with the password value
    //     const strength = Password(password);
    //     // Set the signinError state based on the password strength
    //     setSigninError(strength);
    // };

    const checkSignupPasswordStrength = (password) => {
        const strength = Password(password);
        setSignupError(strength);
    };

    const handleLoginInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        // if (name === "loginPassword") {
        //     checkLoginPasswordStrength(value);
        // }
        setLoginForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSignupInputChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        if (name === "signupPassword") {
            checkSignupPasswordStrength(value);
        }
        setSignupForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const inputs = Array.from(document.querySelectorAll('input'));
            const currentIndex = inputs.findIndex(input => input === e.target);
            const nextIndex = currentIndex + 1;
            if (nextIndex < inputs.length) {
                inputs[nextIndex].focus();
            }
        }
    };


    return (
        <>
            <div className={lsStyle['ls-body']}>
                <div className={signupmode}>
                    <div className={lsStyle['login-signup']}>
                        {forgotPasswordStep === 1 && (
                            <form className={lsStyle.form1 + ' ' + lsStyle['user-signin']} onSubmit={login_submit}>
                                <h3 className={lsStyle.title}>SIGNIN</h3>
                                <div className={lsStyle['input-field']}>
                                    <MdEmail />
                                    <input type="char" name="loginEmail" placeholder="Email" value={loginForm.loginEmail} onChange={handleLoginInputChange} onKeyDown={handleKeyDown} />
                                </div>
                                <div className={lsStyle['input-field']}>
                                    <MdOutlineWifiPassword />
                                    <input type={lshowPassword ? "password" : "text"} name="loginPassword" placeholder="Password" value={loginForm.loginPassword} onChange={handleLoginInputChange} onKeyDown={handleKeyDown} />
                                    <div className="ldiv" onClick={eye_toggle} style={{ border: 'none', marginRight: '4px' }}>{lshowPassword ? <IoIosEyeOff /> : <IoIosEye />}</div>
                                </div>
                                <div className={lsStyle['error-message']} style={{ color: 'red', textAlign: 'center' }}>
                                    {lError}
                                </div>
                                <a href="https://github.com/react-icons/react-icons" className={lsStyle.forgot}>Forgot Password ?</a>
                                <p className={lsStyle['account-text']}>Don&apos;t have an account ? <button className={lsStyle.btn2} onClick={signupbtn}> Sign up</button></p>
                                <button type="submit" className={lsStyle.btn1} disabled={loading}>
                                    {loading ? <CircularProgress size={16} sx={{color:'white'}}/> : "Signin"}
                                </button>
                                <hr className={lsStyle['or-line']} />
                                <p className={lsStyle['social-text']}>OR</p>
                                <div className={lsStyle['social-media']}>
                                    <button className={lsStyle['social-icon']}><FaGoogle /></button>
                                    <button className={lsStyle['social-icon']}><FaFacebookF /></button>
                                </div>
                            </form>
                        )}
                        {forgotPasswordStep === 2 && <EmailVerify nextStep={() => setForgotPasswordStep(3)} />}
                        {forgotPasswordStep === 3 && <OtpVerify nextStep={() => setForgotPasswordStep(4)} />}
                        {forgotPasswordStep === 4 && <Reset />}
                        <form onSubmit={signup_submit} className={lsStyle.form1 + ' ' + lsStyle['user-signup']} >
                            <h3 className={lsStyle.title}>SIGNUP</h3>
                            <div className={lsStyle['input-field']}>
                                <FaUser />
                                <input type="text" name="username" placeholder="Name" value={signupForm.username} onChange={handleSignupInputChange} onKeyDown={handleKeyDown} />
                            </div>
                            <div className={lsStyle['input-field']}>
                                <MdEmail />
                                <input type="email" name="signupEmail" placeholder="Email" value={signupForm.signupEmail} onChange={handleSignupInputChange} onKeyDown={handleKeyDown} />
                            </div>
                            <div className={lsStyle['input-field']}>
                                <MdOutlineWifiPassword />
                                <input type={sshowPassword ? "password" : "text"} name="signupPassword" placeholder="Password" value={signupForm.signupPassword} onChange={handleSignupInputChange} onKeyDown={handleKeyDown} />
                                <div className="sdiv" onClick={eye_toggle1} style={{ border: 'none', marginRight: '4px' }}>{sshowPassword ? <IoIosEyeOff /> : <IoIosEye />}</div>
                            </div>
                            {isError ?
                                (
                                    <>
                                        {sError && (
                                            <div className={lsStyle['error-message']} style={{ color: 'red', textAlign: 'center' }}>
                                                {sError}
                                            </div>
                                        )}
                                    </>
                                ) :
                                (
                                    <>
                                        {signupError && (
                                            <div className={`${lsStyle['error-message']} ${signupError ? lsStyle[signupError.severity] : ''}`} >
                                                {signupError.severity === "strong" ? <IoCheckmarkCircleOutline style={{ color: 'var(--success-color)' }} /> : " "}
                                                {signupError.severity === "weak" ? <IoInformationCircleOutline style={{ color: 'var(--error-color)' }} /> : " "}
                                                {signupError.severity === "moderate" ? <IoCheckmarkCircleOutline style={{ color: 'pink' }} /> : " "} {signupError.message}
                                            </div>
                                        )}
                                    </>
                                )}
                            <p className={lsStyle['account-text']}>Already have an account ? <button className={lsStyle.btn2} onClick={login}> Sign in</button></p>
                            <button type="submit" className={lsStyle.btn1} disabled={loading}>
                                {loading ? <CircularProgress size={16} sx={{color:"white"}}/> : "Signup"}
                            </button>
                            <hr className={lsStyle['or-line']} />
                            <p className={lsStyle['social-text']}>OR</p>
                            <div className={lsStyle['social-media']}>
                                <button className={lsStyle['social-icon']}><FaGoogle /></button>
                                <button className={lsStyle['social-icon']}><FaFacebookF /></button>
                            </div>
                        </form>
                    </div>
                    <div className={lsStyle['panels-container']}>
                        <div className={lsStyle.panel + ' ' + lsStyle['left-panel']}>
                            <div className={lsStyle.content}>
                                <p>Already have an account?</p>
                                <h3>Influenzo</h3>
                                <button className={lsStyle.btn_} onClick={login}>Signin</button>
                                <img src={logo} alt="logo" className={lsStyle.image} />
                            </div>
                        </div>
                        <div className={lsStyle.panel + ' ' + lsStyle['right-panel']}>
                            <div className={lsStyle.content}>
                                <p>Don&apos;t have an account?</p>
                                <h3>Influenzo</h3>
                                <button className={lsStyle.btn_} onClick={signup}>Signup</button>
                                <img src={logo2} alt="logo" className={lsStyle.image} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;