import Rout from './components/Router/Route';
import { BrowserRouter } from 'react-router-dom';


function App() {
  return (
    <Rout/>
    )
}

export default App;
