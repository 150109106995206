import React from "react";
import {
    MDBContainer,
    MDBTable,
    MDBTableBody,
    MDBTableHead,
} from "mdb-react-ui-kit";

import cStyle from '../../../css/PricingPage/Compare.module.css'

import { MdCheck } from "react-icons/md";

export default function Compare() {
    return (
        <>
            <div className={cStyle['details']}>
                <h2 className={cStyle['compareHeading']}>Compare Influenzo Plans</h2>
                <p className={cStyle['para']}>Discover why our pricing stands out as the top choice in our detailed comparison table!</p>
            </div>
            <MDBContainer className={cStyle.container}>
                <MDBTable
                    responsive
                    borderless
                    className={cStyle['table']}
                >
                    <MDBTableHead className={cStyle['table-head']} >
                        <tr >
                            <th scope="col" style={{ background: 'transparent' }} >
                                {/* <strong>Legend</strong>
            <div className={cStyle['head">
            <p><MdCheck className={cStyle['check']}/>Included with plan<br/> <RxCross2 className={cStyle['cross"/>Not included with plan</p>
            </div> */}
                            </th>
                            <th style={{ background: 'transparent' }} scope="col" className={cStyle['heading']}>
                                <strong>BASIC</strong>
                                {/* <div className={cStyle['head">
                <p>$</p>
              <button className={cStyle['btn">Sign-up</button>
              </div> */}
                            </th>
                            <th scope="col" className={cStyle['heading']} style={{ borderTop: '2px solid var(--ten-color)', background: 'transparent' }}>
                                <strong className={cStyle['active1']}>STANDARD</strong>
                                {/* <div className={cStyle['head">
              <p>$</p>
              <button className={cStyle['btn">Sign-up</button>
              </div> */}
                            </th>
                            <th className={cStyle['heading']} style={{ background: 'transparent' }} scope="col" >
                                <strong >PREMIUM</strong>
                                {/* <div className={cStyle['head">
              <p>$</p>
              <button className={cStyle['btn">Sign-up</button>
              </div> */}
                            </th>
                            {/* <th scope="col">
              <strong>Managed</strong>
            </th> */}
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody className={cStyle['table-body']} >
                        <tr className={cStyle['tbody-row']}>
                            <th className={cStyle['row-heading']} style={{ background: 'transparent' }} > Use</th>
                            <td style={{ background: 'transparent' }}></td>
                            <td style={{ background: 'transparent' }}></td>
                            <td style={{ background: 'transparent' }}></td>
                        </tr>
                        <tr className={cStyle['table-row']} >
                            <th scope="row" className={cStyle['tbody-row']} style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>HDD Storage</th>
                            <td style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>700 MB</td>
                            <td className={cStyle['active2']} style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>1,5 GB</td>
                            <td style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>50 GB</td>
                            {/* <td>up to 5T</td> */}
                        </tr>
                        <tr className={cStyle['table-row']}>
                            <th scope="row" className={cStyle['tbody-row']} style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>Web Server</th>
                            <td style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>
                                <MdCheck className={cStyle['check']} />
                            </td>
                            <td className={cStyle['active3']} style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>
                                <MdCheck className={cStyle['check']} />
                            </td>
                            <td style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>
                                <MdCheck className={cStyle['check']} />
                            </td>
                            {/* <td>
              <MdCheck className={cStyle['check']}/>
            </td> */}
                        </tr>
                        <tr className={cStyle['table-row']} >
                            <th scope="row" className={cStyle['tbody-row']} style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>Database</th>
                            <td style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>-</td>
                            <td className={cStyle['active3']} style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>Optional</td>
                            <td style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>
                                <MdCheck className={cStyle['check']} />
                            </td>
                            {/* <td>
              <MdCheck className={cStyle['check']}/>
            </td> */}
                        </tr>
                        <tr className={cStyle['table-row']}>
                            <th scope="row" className={cStyle['tbody-row']} style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>DNS</th>
                            <td style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>-</td>
                            <td className={cStyle['active3']} style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>-</td>
                            <td style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>
                                <MdCheck className={cStyle['check']} />
                            </td>
                            {/* <td>
              <MdCheck className={cStyle['check']} />
            </td> */}
                        </tr>
                        <tr className={cStyle['table-row']}>
                            <th scope="row" className={cStyle['tbody-row']} style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>Backups</th>
                            <td style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>-</td>
                            <td className={cStyle['active3']} style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>-</td>
                            <td style={{ borderBottom: '1px solid var(--border-color)', background: 'transparent' }}>
                                <MdCheck className={cStyle['check']} />
                            </td>
                            {/* <td>
              <MdCheck className={cStyle['check']} />
            </td> */}
                        </tr>
                        <tr className={cStyle['table-row']}>
                            <th style={{ background: 'transparent' }} scope="row" className={cStyle['tbody-row']}>Tech Support</th>
                            <td style={{ background: 'transparent' }} >None</td>
                            <td style={{ background: 'transparent' }} className={cStyle['active4']} >35$/incident</td>
                            <td style={{ background: 'transparent' }}>15$/incident</td>
                            {/* <td>24/7 included</td> */}
                        </tr>
                    </MDBTableBody>
                </MDBTable>
            </MDBContainer>
        </>
    );
}