"use client";
import React, { useEffect } from "react";
import { useGlobalState, setPlaying, handleSeek, updateVideoElements } from "../Store/Store";
import { formatTimeToMinSecMili } from "../utils/utils";
import { MdPlayArrow, MdPause } from "react-icons/md";
// import ScaleRangeInput from "./ScaleRangeInput";
import styles from "../../../../css/VideoEditor/video.module.css";

// const MARKINGS = [
//   {
//     interval: 5000,
//     color: "black",
//     size: 16,
//     width: 1,
//   },
//   {
//     interval: 1000,
//     color: "black",
//     size: 8,
//     width: 1,
//   },
// ];



const SeekPlayer = () => {
  const store = useGlobalState();
  const Icon = store.playing.get() ? MdPause : MdPlayArrow;
  const formattedTime = formatTimeToMinSecMili(store.currentTimeInMs.get());
  const formattedMaxTime = formatTimeToMinSecMili(store.maxTime.get());

  // useEffect(()=>{
  //   updateVideoElements()
  // },[store.trimmedVideo2])

  return (
    <div className={styles.seekPlayer}>
      <div className={styles.seekPlayerControls}>
        <button
          className={styles.seekPlayerButton}
          onClick={() => {
            setPlaying(!store.playing.get());
          }}
        >
          <Icon size="25" />
        </button>
        <span className={styles.seekPlayerTime}>{formattedTime}</span>
        <div className={styles.seekPlayerDivider} />
        <span className={styles.seekPlayerTime}>{formattedMaxTime}</span>
      </div>
      {/* <ScaleRangeInput
        max={store.maxTime.get()}
        value={store.currentTimeInMs.get()}
        onChange={handleSeek}
        height={30}
        markings={MARKINGS}
        backgroundColor="transparent"
      /> */}
    </div>
  );
};

export default SeekPlayer;
