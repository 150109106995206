import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useGlobalState, updateEditorElementTimeFrame, updateAudioElementTimeFrame, refreshTrimVideo, refreshTrimVideo2, onDone } from "../Store/Store";
import styles from "../../../../css/VideoEditor/video.module.css";
import DragableView from "./DragableView";


const TimeFrameView = (props) => {
  const store = useGlobalState();
  const { element } = props;
  const [frames, setFrames] = useState([]);

  const videoRef = useRef(null);
  // const videoRef2 = useRef(null);
  // const trimmedVideoRef = useRef(null);
  const trimmedUrl = store.trimmedUrls.get()
  // const [trimmedUrl, setUrl] = useState({})

  const editedMediaDict = element.media || {};
  const editedMediaArray = Object.values(editedMediaDict);
  const editedMedia = editedMediaArray.length > 0 ? editedMediaArray[0] : {};

  const editedMediaStart = editedMedia.timeFrame?.start ?? 0;
  const editedMediaEnd = editedMedia.timeFrame?.end ?? 0;

  const sceneElementStart = element.timeFrame.start;
  const sceneElementEnd = element.timeFrame.end

  const editedMediaLeftPercentage = (editedMediaStart / store.maxTime.get()) * 100;
  const editedMediaWidthPercentage = ((editedMediaEnd - editedMediaStart) / store.maxTime.get()) * 100;

  const sceneElementLeftPercentage = (sceneElementStart / store.maxTime.get()) * 100;
  const sceneElementWidthPercentage = ((sceneElementEnd - sceneElementStart) / store.maxTime.get()) * 100;

  const disabled = element.type === "audio";
  const { scene: sceneName, name: mediaName, type, properties } = editedMedia;

  const audioLeft = element.type === "audio" ? (element.timeFrame.start / store.maxTime.get()) * 100 : 0
  const audioWidth = element.type === "audio" ? ((element.timeFrame.end - element.timeFrame.start) / store.maxTime.get()) * 100 : 0

  const textLeft = element.type === "text" ? (element.timeFrame.start / store.maxTime.get()) * 100 : 0
  const textWidth = element.type === "text" ? ((element.timeFrame.end - element.timeFrame.start) / store.maxTime.get()) * 100 : 0

  const EFFECT_TYPE_TO_CSS_FILTER = {
    blackAndWhite: "grayscale(100%)",
    saturate: "saturate(200%)",
    sepia: "sepia(100%)",
    invert: "invert(100%)",
    none: "none",
  };

  const selectedEffect = store.selectedEffect[element.name]?.get() || "none";
  // const [selectedEffect, setSelectedEffect] = useState("")

  const handleSelectTimeFrame = async () => {
    // store.selectedElement.set(JSON.parse(JSON.stringify(element)));
    
    store.elementToAddAnimation.set(JSON.parse(JSON.stringify(element)))
    // }
    // if (element.type === "text" || element.type === "audio") {
    //   store.selectedTextElement.set(JSON.parse(JSON.stringify(element)))
    // }
    // console.log(store.selectedElement.get());
    // await trimVideo()
  };

  // useEffect(() => {
  //   setSelectedEffect(store.selectedEffect.get())
  // }, [store.selectedEffect]);


  useEffect(() => {
    if (editedMedia && type === "video" && videoRef.current) {
      const totalFrames = 20;
      const videoElement = videoRef.current;

      const captureFrames = async () => {
        const newFrames = [];
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = 100;
        canvas.height = 50;

        for (let i = 0; i < totalFrames; i++) {
          const time = (videoElement.duration / totalFrames) * i;
          videoElement.currentTime = time;
          await new Promise((resolve) => {
            videoElement.onseeked = () => {
              context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
              newFrames.push({
                src: canvas.toDataURL("image/png"),
                time: videoElement.currentTime * 1000,
              });
              resolve();
            };
          });
        }
        // console.log("newFrames", newFrames);
        setFrames(newFrames);
      };

      videoElement.onloadedmetadata = captureFrames;
    } else if (editedMedia && editedMedia.type === "image") {
      const totalFrames = 20;
      const newFrames = new Array(totalFrames).fill(editedMedia.properties.src);
      setFrames(newFrames);
    }

  }, [element.media]);


  const renderFrame = (src, index) => (
    <img
      key={index}
      src={src}
      alt={`Frame ${index}`}
      style={{
        width: 40,
        height: 60,
        objectFit: "cover",
        pointerEvents: "none",
        filter: EFFECT_TYPE_TO_CSS_FILTER[selectedEffect],
      }}
    />
  );

  return (
    <>
      {element.type === "scene" && (
        <div
          key={element.name}
          className={styles.timeFrameView}
          style={{
            position: "relative",
            width: `100%`,
            overflowX: "visible",
          }}
          onClick={handleSelectTimeFrame}
        >
          {editedMedia && (
            <DragableView
              value={editedMediaStart}
              total={store.maxTime.get()}
              disabled={false}
              element={element}
              onChange={(newValue) => {
                const end = editedMediaEnd ?? 0;
                const timeFrame = { start: newValue, end: newValue + (end - editedMediaStart) };
                if (editedMediaStart !== newValue) {
                  updateEditorElementTimeFrame(editedMedia, timeFrame);
                }
                else {
                  console.log("selected element successfully.........")
                }
              }}
              style={{
                left: `${editedMediaLeftPercentage}%`,
                width: `${editedMediaWidthPercentage}%`,
                overflowX: 'visible',
              }}
            >
              <div className={styles.mtimeFrameContent}>
                {editedMedia.type === "video" && frames.length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      overflowX: "visible",
                      pointerEvents: "none"
                    }}
                  >
                    {frames.map((frame, index) => renderFrame(frame.src, index))}
                  </div>
                ) : null}
                {editedMedia.type === "image" && frames.length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      overflowX: "visible",
                      pointerEvents: "none"
                    }}
                  >
                    {frames.map((frame, index) => renderFrame(frame, index))}
                  </div>
                ) : null}
              </div>
            </DragableView>
          )}

          <div
            className={styles.vTimeFrameView}
            style={{
              marginLeft: `${sceneElementLeftPercentage}%`,
              width: `${sceneElementWidthPercentage}%`,
              position: "absolute",
              top: 0,
              bottom: 0,
              zIndex: 2,
              border: "4px solid #008dda",
              borderRadius: "4px",
            }}
          />
          {/* Hidden video element for frame extraction */}
          {editedMedia.type === "video" && (
            <video
              onLoadedData={onDone}
              ref={videoRef}
              // onLoadedData={makeFrames(editedMedia,videoRef)}
              src={editedMedia.properties.src}
              style={{ display: "none" }}
            // id={editedMedia.name}
            />
          )}
          {editedMedia.type === "image" && (
            <img
              onLoad={onDone}
              src={editedMedia.properties.src}
              style={{ display: "none" }}
              id={editedMedia.name}
              alt=""
            />
          )}
          {trimmedUrl && type === "video" && (
            <video src={trimmedUrl[sceneName]} preload="auto" onLoadedData={refreshTrimVideo2} id={`trimmed-${sceneName}` + `.mp4`} style={{ display: 'none' }}></video>)}

          {trimmedUrl && type === "image" && (
            <img src={trimmedUrl[sceneName]} onLoad={refreshTrimVideo2} id={`trimmed-${sceneName}` + `.mp4`} style={{ display: 'none' }}></img>)}

        </div>
      )}
      {element.type === "audio" && (
        <div
          key={element.name}
          className={styles.timeFrameView}
          style={{
            position: "relative",
            width: `100%`,
            overflowX: "visible",
          }}
          onClick={handleSelectTimeFrame}
        >

          <DragableView
            value={element.timeFrame.start}
            total={store.maxTime.get()}
            disabled={false}
            element={element}
            onChange={(newValue) => {
              const end = element.timeFrame.end;
              const timeFrame = { start: newValue, end: newValue + (end - element.timeFrame.start) };
              updateAudioElementTimeFrame(element, timeFrame);
            }}
            style={{
              left: `${audioLeft}%`,
              width: `${audioWidth}%`,
              overflowX: 'visible',
            }}
          >
            {element.type === "audio" && (
              <audio
                onLoadedData={onDone}
                src={element.properties.src}
                // style={{ display: "none" }}
                id={element.name}
                alt=""
              />
            )}
          </DragableView>
          <div
            className={styles.vTimeFrameView}
            style={{
              marginLeft: `${sceneElementLeftPercentage}%`,
              width: `${sceneElementWidthPercentage}%`,
              position: "absolute",
              top: 0,
              bottom: 0,
              zIndex: 2,
              border: "4px solid #008dda",
              borderRadius: "4px",
            }}
          />
        </div>
      )
      }
      {element.type === "text" && (
        <div
          key={element.name}
          className={styles.timeFrameView}
          style={{
            position: "relative",
            width: `100%`,
            overflowX: "visible",
          }}
          onClick={handleSelectTimeFrame}
        >

          <DragableView
            value={element.timeFrame.start}
            total={store.maxTime.get()}
            disabled={false}
            element={element}
            onChange={(newValue) => {
              const end = element.timeFrame.end;
              const timeFrame = { start: newValue, end: newValue + (end - element.timeFrame.start) };
              updateAudioElementTimeFrame(element, timeFrame);
            }}
            style={{
              left: `${textLeft}%`,
              width: `${textWidth}%`,
              overflowX: 'visible',
              border: '1px solid red'
            }}
          >
            {element.name}
          </DragableView>
          <div
            style={{
              marginLeft: `${sceneElementLeftPercentage}%`,
              width: `${sceneElementWidthPercentage}%`,
              position: "absolute",
              top: 0,
              bottom: 0,
              zIndex: 2,
              border: "4px solid #008dda",
              borderRadius: "4px",
            }}
          />
        </div>
      )
      }
    </>
  );
};

TimeFrameView.propTypes = {
  element: PropTypes.shape({
    type: PropTypes.string.isRequired,
    media: PropTypes.object,
    name: PropTypes.string.isRequired,
    timeFrame: PropTypes.shape({
      start: PropTypes.number.isRequired,
      end: PropTypes.number.isRequired,
    }).isRequired,
    properties: PropTypes.shape({
      name: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default TimeFrameView;