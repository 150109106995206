import React, { lazy, Suspense } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

// Lazy loading components
const LazyDashboard = lazy(() => import("layouts/dashboard"));
const LazyTables = lazy(() => import("layouts/tables"));
const LazyEasyFind = lazy(() => import("layouts/billing"));
const LazyVirtualReality = lazy(() => import("layouts/virtual-reality"));
const LazyRTL = lazy(() => import("layouts/rtl"));
const LazyProfile = lazy(() => import("layouts/profile"));
const LazySignIn = lazy(() => import("components/Pages/Signin_Signup_Page/Login.jsx"));
const LazySignUp = lazy(() => import("components/Pages/Signin_Signup_Page/Login.jsx"));
const LazyEasyEdit = lazy(() => import("components/Pages/VideoEditor"));

// Soft UI Dashboard React icons
import Shop from "../examples/Icons/Shop";
import Office from "../examples/Icons/Office";
import Settings from "../examples/Icons/Settings";
import Document from "../examples/Icons/Document";
import SpaceShip from "../examples/Icons/SpaceShip";
import CustomerSupport from "../examples/Icons/CustomerSupport";
import CreditCard from "../examples/Icons/CreditCard";
import Cube from "../examples/Icons/Cube";

const routes = [
  // {
  //   type: "collapse",
  //   name: "EasyInsight",
  //   key: "easyinsight",
  //   route: "/EasyInsight",
  //   icon: <Shop size="12px" />,
  //   component: (
  //     <Suspense fallback={<CircularProgress sx={{ color: '#008dda' }}/>} >
  //       <LazyDashboard />
  //     </Suspense>
  //   ),
  //   noCollapse: true,
  // },
  // {
  //   type: "collapse",
  //   name: "Tables",
  //   key: "tables",
  //   route: "/tables",
  //   icon: <Office size="12px" />,
  //   component: (
  //     <Suspense fallback={<div>Loading...</div>}>
  //       <LazyTables />
  //     </Suspense>
  //   ),
  //   noCollapse: true,
  // },
  {
    type: "collapse",
    name: "EasyFind",
    key: "easyfind",
    route: "/EasyFind",
    icon: <CreditCard size="12px" />,
    component: (
      <Suspense fallback={<CircularProgress sx={{ color: '#008dda' }}/>}>
        <LazyEasyFind />
      </Suspense>
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "EasyEdit",
    key: "EasyEdit",
    route: "/EasyEdit",
    icon: <Cube size="12px" />,
    component: (
      <Suspense fallback={<CircularProgress sx={{ color: '#008dda' }}/>} style={{overFlowX:'hidden'}}>
        <LazyEasyEdit />
      </Suspense>
    ),
    noCollapse: true,
  },
  // {
  //   type: "collapse",
  //   name: "RTL",
  //   key: "rtl",
  //   route: "/rtl",
  //   icon: <Settings size="12px" />,
  //   component: (
  //     <Suspense fallback={<div>Loading...</div>}>
  //       <LazyRTL />
  //     </Suspense>
  //   ),
  //   noCollapse: true,
  // },
  { type: "title", title: "Account Pages", key: "account-pages" },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: (
      <Suspense fallback={<div>Loading...</div>}>
        <LazyProfile />
      </Suspense>
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    route: "/login",
    icon: <Document size="12px" />,
    component: (
      <Suspense fallback={<div>Loading...</div>}>
        <LazySignIn />
      </Suspense>
    ),
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Sign Up",
    key: "sign-up",
    route: "/login",
    icon: <SpaceShip size="12px" />,
    component: (
      <Suspense fallback={<div>Loading...</div>}>
        <LazySignUp />
      </Suspense>
    ),
    noCollapse: true,
  },
];

export default routes;
