import React from "react";
import pStyle from '../../../css/PricingPage/Pricing.module.css'
import Compare from "./Compare";
import { VscTag } from "react-icons/vsc";
import Nav from "../Landing_Page/Nav";
import Footer from "../Landing_Page/Footer";

const Pricing = () => {
    const handleover=(e)=>{
        e.target.style.background = 'transparent';
        e.target.style.color='var(--text-400-color)'
    }

    const handleleave=(e)=>{
        e.target.style.background = 'var(--text-400-color)';
        e.target.style.color='white'
    }
    return (
        <>
            <div className={pStyle['pricing-table']}>
            <Nav link_color={'black'} border_color={'var(--text-400-color)'} text_color={'white'} handleover={handleover} handleleave={handleleave}/>
                <div className={pStyle.details}>
                    <h2 className={pStyle.pageHeading}>
                        Pick your Perfect Plan
                    </h2 >
                    <p className={pStyle.para}>Easily create all of your content</p>
                </div>
                <div className={pStyle.grid}>
                    <div className={pStyle['box'] + ' ' + pStyle['basic']}>
                        <div className={pStyle.logo}><VscTag className={pStyle.img} /></div>
                        <div className={pStyle.title}>
                            BASIC
                        </div>
                        <div className={pStyle.price}>
                            <span><b className={pStyle.boldPrice}>$123</b>/month</span>
                        </div>
                        <div className={pStyle.features}>
                            <div>1 cpu</div>
                            <div>1 cpu</div>
                            <div>1 cpu</div>
                            <div>1 cpu</div>
                            <div>1 cpu</div>
                        </div>
                        <div className={pStyle.button}>
                            <button className={pStyle.pricingButton}>Learn More</button>
                        </div>
                    </div>
                    <div className={pStyle['box'] + ' ' + pStyle['professional']} >
                        <div className={pStyle.logo}><VscTag className={pStyle.img} /></div>
                        <div className={pStyle['title']} >
                            STANDARD
                        </div>
                        <div className={pStyle['price']} >
                            <span><b className={pStyle.boldPrice}>$123</b>/month</span>
                        </div>
                        <div className={pStyle['features']} >
                            <div>1 cpu</div>
                            <div>1 cpu</div>
                            <div>1 cpu</div>
                            <div>1 cpu</div>
                        </div>
                        <div className={pStyle['button']} >
                            <button className={pStyle.pricingButton}>Learn More</button>
                        </div>
                    </div>
                    <div className={pStyle['box'] + ' ' + pStyle['ninja']}>
                        <div className={pStyle.logo}><VscTag className={pStyle.img} /></div>
                        <div className={pStyle['title']}>
                            PREMIUM
                        </div>
                        <div className={pStyle['pricecancel']}>
                            <span><del><b className={pStyle.boldPrice}>$123</b></del>/month</span>
                        </div>
                        <div className={pStyle['price']}>
                            <span><b className={pStyle.boldPrice}>$123</b>/month</span>
                        </div>
                        <div className={pStyle['features']}>
                            <div>1 cpu</div>
                            <div>1 cpu</div>
                            <div>1 cpu</div>
                            <div>1 cpu</div>
                        </div>
                        <div className={pStyle['button']}>
                            <button className={pStyle.pricingButton}>Learn More</button>
                        </div>
                    </div>
                </div>
                <div className={pStyle['compare']}>
                    <Compare />
                </div>
            {/* <Footer /> */}
            </div>
        </>
    )
}

export default Pricing;