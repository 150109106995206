import { none, hookstate, useHookstate } from '@hookstate/core';
import anime from "animejs"
import { isHtmlVideoElement, isHtmlImageElement, isHtmlAudioElement } from '../utils/utils';
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from '@ffmpeg/util';

const globalState = hookstate({
  playing: false,
  currentTimeInMs: 0,
  currentKeyFrame: 0,
  maxTime: 30 * 1000,
  fps: 60,
  setLoading:false,
  uploadedMedia: [],
  uploadedAudio: [],
  mediaElements: {},
  sceneElements: [],
  isSceneVisible: false,
  isAddButtonClicked: false,
  mediaSceneElement: [],
  selectedElement: null,
  animationTimeLine: anime.timeline(),
  calculateValue: 10,
  deltaValue: {},
  updated: [],
  trimmedVideos: {},
  loaded: false,
  selectedTrimedVideo: null,
  ffmpeg: null,
  trimmedVideo2: {},
  updatedSceneMedia: null,
  trimmedUrls: {},
  frames: [],
  trimVideoId: new Set(),
  currentVideoIndex: 0,
  updateTimeframeScene: null,
  selectedVideoFormat: 'mp4',
  audioUrls: {},
  externalAudioUrls: {},
  downloadUrl: null,
  combinedUrl: null,
  bothCombineUrl: null,
  recordedVideo: null,
  combinedAudio: null,
  sceneVideos: [],
  animations: [],
  elementToAddAnimation: null,
  selectedEffect: {},
  selectedScene: {},
  deletedScene: null
});


export const useGlobalState = () => useHookstate(globalState);
const ffmpeg = new FFmpeg
let startedTime = 0;
let startedTimePlay = 0;

export const loadFFmpeg = async () => {
  console.log("ffmpeg loading.....")
  await ffmpeg.load();
  globalState.loaded.set(true);
  console.log("ffmpeg loaded sucessfully.....")
  globalState.ffmpeg.set(ffmpeg)
  return ffmpeg
};

export const setCanvas = (canvas) => {
  globalState.canvas.set(canvas);
}

export const refreshElement = (element) => {
  const canvas = globalState.canvas.get();
  if (!canvas) return;

  if (element.type === "text") {
    const textObject = new fabric.Textbox(element.properties.text, {
      name: element.name,
      left: element.placement.x,
      top: element.placement.y,
      scaleX: element.placement.scaleX,
      scaleY: element.placement.scaleY,
      width: element.placement.width,
      height: element.placement.height,
      angle: element.placement.rotation,
      fontSize: element.properties.fontSize,
      fontWeight: element.properties.fontWeight,
      objectCaching: false,
      selectable: true,
      lockUniScaling: true,
      fill: element.properties.fontColor || "black", // Use the fontColor or default to black
    });
    const sceneElements = globalState.sceneElements.get();

    // Find the index of the element by its unique identifier (e.g., `name`)
    const elementIndex = sceneElements.findIndex(el => el.name === element.name);

    if (elementIndex !== -1) {
      // Update the element's fabricObject property
      globalState.sceneElements[elementIndex].fabricObject.set(textObject);

      canvas.add(textObject)
      canvas.on("object:modified", function (e) {
        if (!e.target) return
        const target = e.target
        if (target !== textObject) return
        const placement = element.placement
        const newPlacement = {
          ...placement,
          x: target.left ?? placement.x,
          y: target.top ?? placement.y,
          rotation: target.angle ?? placement.rotation,
          width: target.width ?? placement.width,
          height: target.height ?? placement.height,
          scaleX: target.scaleX ?? placement.scaleX,
          scaleY: target.scaleY ?? placement.scaleY
        }
      })
    }
  }
}

export const refreshTrimVideo = () => {
  const canvas = globalState.canvas.get();
  if (!canvas) return;
  // canvas.remove(...canvas.getObjects());
  const sceneName = globalState.updatedSceneMedia.get()
  const mediaList = globalState.trimmedVideo2.get();
  const mediaL = Object.values(mediaList)
  const media = mediaL.find((scene) => (scene.scene === sceneName))
  if (media.type === "video") {
    if (document.getElementById(media.properties.name) == null) {
      return
    }

    const mediaElement = document.getElementById(
      media.properties.name
    )
    if (!isHtmlVideoElement(mediaElement)) {
      return
    }

    const mediaObject = new fabric.CoverVideo(mediaElement, {
      name: media.name,
      left: media.placement.x,
      top: media.placement.y,
      width: media.placement.width,
      height: media.placement.height,
      scaleX: media.placement.scaleX,
      scaleY: media.placement.scaleY,
      angle: media.placement.rotation,
      objectCaching: false,
      // selectable: true,
      // evented: true,
      // hasControls: true,
      lockUniScaling: false,
      customFilter: media.properties.effect.type,
    });

    const updatedMedia = {
      ...media,
      fabricObject: mediaObject,
      properties: {
        ...media.properties,
        imageObject: mediaObject
      }
    }

    globalState.trimmedVideos[media.scene].set(updatedMedia);

    globalState.selectedTrimedVideo.set(updatedMedia)

    mediaElement.width = 100;
    mediaElement.height = (mediaElement.videoHeight * 100) / mediaElement.videoWidth;

    canvas.add(mediaObject);

    canvas.on("object:modified", function (e) {
      if (!e.target) return;
      const target = e.target;
      if (target !== mediaObject) return;
      const placement = media.placement;
      const newPlacement = {
        ...placement,
        x: target.left ?? placement.x,
        y: target.top ?? placement.y,
        rotation: target.angle ?? placement.rotation,
        width: target.width && target.scaleX ? target.width * target.scaleX : placement.width,
        height: target.height && target.scaleY ? target.height * target.scaleY : placement.height,
        scaleX: 1,
        scaleY: 1
      };

      const updatePlacement = { ...media, placement: newPlacement }
      const trimVideos = globalState.trimmedVideos.get()


    });
  }
  else if (media.type === "image") {
    if (document.getElementById(media.properties.name) == null) {
      return
    }

    const mediaElement = document.getElementById(
      media.properties.name
    )

    if (!isHtmlImageElement(mediaElement)) {
      return
    }

    const mediaObject = new fabric.CoverImage(mediaElement, {
      name: media.name,
      left: media.placement.x,
      top: media.placement.y,
      width: media.placement.width,
      height: media.placement.height,
      scaleX: media.placement.scaleX,
      scaleY: media.placement.scaleY,
      angle: media.placement.rotation,
      objectCaching: false,
      // selectable: true,
      // evented: true,
      // hasControls: true,
      lockUniScaling: false,
      customFilter: media.properties.effect.type,
    });


    const updatedMedia = {
      ...media,
      fabricObject: mediaObject,
      properties: {
        ...media.properties,
        imageObject: mediaObject
      }
    }

    globalState.trimmedVideos[media.scene].set(updatedMedia);
    globalState.selectedTrimedVideo.set(updatedMedia)
    mediaElement.width = 100;
    mediaElement.height = (mediaElement.videoHeight * 100) / mediaElement.videoWidth;

    canvas.add(mediaObject);
    canvas.on("object:modified", function (e) {

      if (!e.target) return;
      const target = e.target;
      if (target !== mediaObject) return;
      const placement = media.placement;
      const newPlacement = {
        ...placement,
        x: target.left ?? placement.x,
        y: target.top ?? placement.y,
        rotation: target.angle ?? placement.rotation,
        width: target.width && target.scaleX ? target.width * target.scaleX : placement.width,
        height: target.height && target.scaleY ? target.height * target.scaleY : placement.height,
        scaleX: 1,
        scaleY: 1
      };

      // Correctly update the editor element state
      const updatePlacement = { ...media, placement: newPlacement }
      const trimVideos = globalState.trimmedVideos.get()

    });
  }

  const trimmedMedia = globalState.trimmedVideos.get();
  const fomedia = Object.values(trimmedMedia)
  const upmedia = fomedia.find((scene) => (scene.scene === sceneName))
  if (upmedia.fabricObject) {
    upmedia.fabricObject.on("selected", function (e) {
      setselectedTrimVideo(upmedia)
    })
  }
  // };
  const selectedTrimVideo = globalState.selectedTrimedVideo.get()
  if (selectedTrimVideo && selectedTrimVideo.fabricObject) {
    canvas.setActiveObject(selectedTrimVideo.fabricObject)
  }
  const current = globalState.currentTimeInMs.get()

  updateTimeTo(current)
  canvas.renderAll();

};

export const refreshTrimVideo2 = () => {
  const canvas = globalState.canvas.get();

  if (!canvas) return;
  canvas.remove(...canvas.getObjects());
  const sceneName = globalState.updatedSceneMedia.get()

  const mediaList = globalState.trimmedVideo2.get();
  const mediaList2 = globalState.trimmedVideos.get();

  const mediaL = Object.values(mediaList)
  const mediaL2 = Object.values(mediaList2)

  const media = mediaL.find((scene) => (scene.scene === sceneName))

  for (let index = 0; index < mediaL.length; index++) {
    const media = mediaL[index];
    const media2 = mediaL2[index]
    switch (media.type) {
      case "video": {
        if (document.getElementById(media.properties.name) == null) {
          return
        }

        const mediaElement = document.getElementById(
          media.properties.name
        )
        if (!isHtmlVideoElement(mediaElement)) {
          return
        }

        const mediaObject = new fabric.CoverVideo(mediaElement, {
          name: media.name,
          left: media.placement.x,
          top: media.placement.y,
          width: media.placement.width,
          height: media.placement.height,
          scaleX: media.placement.scaleX,
          scaleY: media.placement.scaleY,
          angle: media.placement.rotation,
          objectCaching: false,
          // selectable: true,
          // evented: true,
          // hasControls: true,
          lockUniScaling: true,
          customFilter: media2.properties.effect.type,
        });

        const updatedMedia = {
          ...media,
          fabricObject: mediaObject,
          properties: {
            ...media.properties,
            imageObject: mediaObject
          }
        }

        globalState.trimmedVideos[media.scene].set(updatedMedia);

        globalState.selectedTrimedVideo.set(updatedMedia)

        mediaElement.width = 100;
        mediaElement.height = (mediaElement.videoHeight * 100) / mediaElement.videoWidth;

        canvas.add(mediaObject);

        canvas.on("object:modified", function (e) {
          if (!e.target) return;
          const target = e.target;
          if (target !== mediaObject) return;
          const placement = media.placement;
          const newPlacement = {
            ...placement,
            x: target.left ?? placement.x,
            y: target.top ?? placement.y,
            rotation: target.angle ?? placement.rotation,
            width: target.width && target.scaleX ? target.width * target.scaleX : placement.width,
            height: target.height && target.scaleY ? target.height * target.scaleY : placement.height,
            scaleX: 1,
            scaleY: 1
          };

          const updatePlacement = { ...media, placement: newPlacement }
          const trimVideos = globalState.trimmedVideos.get()
        });
        break;
      }
      case "image": {
        if (document.getElementById(media.properties.name) == null) {
          return
        }
        const mediaElement = document.getElementById(
          media.properties.name
        )
        if (!isHtmlImageElement(mediaElement)) {
          return
        }

        const mediaObject = new fabric.CoverImage(mediaElement, {
          name: media.name,
          left: media.placement.x,
          top: media.placement.y,
          width: media.placement.width,
          height: media.placement.height,
          scaleX: media.placement.scaleX,
          scaleY: media.placement.scaleY,
          angle: media.placement.rotation,
          objectCaching: false,
          // selectable: true,
          // evented: true,
          // hasControls: true,
          lockUniScaling: true,
          customFilter: media2.properties.effect.type,
        });


        const updatedMedia = {
          ...media,
          fabricObject: mediaObject,
          properties: {
            ...media.properties,
            imageObject: mediaObject
          }
        }

        globalState.trimmedVideos[media.scene].set(updatedMedia);
        globalState.selectedTrimedVideo.set(updatedMedia)
        mediaElement.width = 100;
        mediaElement.height = (mediaElement.videoHeight * 100) / mediaElement.videoWidth;

        canvas.add(mediaObject);
        canvas.on("object:modified", function (e) {

          if (!e.target) return;
          const target = e.target;
          if (target !== mediaObject) return;
          const placement = media.placement;
          const newPlacement = {
            ...placement,
            x: target.left ?? placement.x,
            y: target.top ?? placement.y,
            rotation: target.angle ?? placement.rotation,
            width: target.width && target.scaleX ? target.width * target.scaleX : placement.width,
            height: target.height && target.scaleY ? target.height * target.scaleY : placement.height,
            scaleX: 1,
            scaleY: 1
          };

          // Correctly update the editor element state
          const updatePlacement = { ...media, placement: newPlacement }
          const trimVideos = globalState.trimmedVideos.get()
        });
        break;
      }
      default: {
        throw new Error("Not implemented");
      }
    }
    const upmedia = media
    if (upmedia.fabricObject) {
      upmedia.fabricObject.on("selected", function (e) {
        setselectedTrimVideo(upmedia)
      })
    }
  }
  const selectedTrimVideo = globalState.selectedTrimedVideo.get()
  if (selectedTrimVideo && selectedTrimVideo.fabricObject) {
    canvas.setActiveObject(selectedTrimVideo.fabricObject)
  }
  const current = globalState.currentTimeInMs.get()

  updateTimeTo(current)
  refreshAnimations()
  canvas.renderAll();

};

export const getMediaDuration = (mediaUrl) => {
  return new Promise((resolve, reject) => {
    const mediaElement = document.createElement('video');
    mediaElement.src = mediaUrl;
    mediaElement.onloadedmetadata = () => {
      resolve(mediaElement.duration);
    };
    mediaElement.onerror = (error) => {
      reject(error);
    };
  });
};

export const getAudioDuration = (mediaUrl) => {
  return new Promise((resolve, reject) => {
    const mediaElement = document.createElement('audio');

    mediaElement.src = mediaUrl;
    mediaElement.onloadedmetadata = () => {
      resolve(mediaElement.duration);
    };
    mediaElement.onerror = (error) => {
      reject(error);
    };
  });
};

export const updateAudioElements = () => {
  const media = globalState.sceneElements.get()
  // const media = Object.values(mediaList)
  const updateMedia = media.filter(element => element.type === "audio").forEach(element => {
    const audio = document.getElementById(element.properties.name)
    if (isHtmlAudioElement(audio)) {
      const audioTime =
        (globalState.currentTimeInMs.get() - element.timeFrame.start) / 1000
      audio.currentTime = audioTime
      if (globalState.playing.get()) {
        audio.play()
      } else {
        audio.pause()
      }
    }
  })
}

export const setPlaying = (playing) => {
  globalState.playing.set(playing);
  updateVideoElements();
  updateAudioElements()
  if (playing) {
    startedTime = Date.now();
    const current = globalState.currentTimeInMs.get();
    startedTimePlay = current;
    requestAnimationFrame(() => {
      playFrames();
    });
  }
};


export const updateVideoElements = () => {
  const trimmedVideos = globalState.trimmedVideo2.get();
  console.log("trimvideo in updateVideo",trimmedVideos)
  const trimObject = Object.values(trimmedVideos)
  const mediaList = trimObject.map(video => ({
    name: video.properties.name,
    start: video.sceneTimeFrame.start,
    end: video.sceneTimeFrame.end,
    type: video.type
  }));

  const currentMediaList = Array.from(globalState.trimVideoId.get());

  mediaList.forEach(media => {
    if (!currentMediaList.some(existing => existing.name === media.name)) {
      globalState.trimVideoId.set(new Set([...currentMediaList, media]));
    }
  });

  console.log(globalState.trimVideoId.get())
  playNextVideo();
};

const playNextVideo = () => {
  const mediaList = Array.from(globalState.trimVideoId.get());
  const currentTime = globalState.currentTimeInMs.get();
  const videoToPlay = mediaList.find(({ start, end }) => start <= currentTime && currentTime <= end);
  if (videoToPlay) {
    const { name, start, end, type } = videoToPlay;
    const video = document.getElementById(name);

    if (video && type === "video" && isHtmlVideoElement(video)) {
      const videoTime = (currentTime - start) / 1000;
      video.currentTime = Math.max(0, videoTime);
      const getPlay = globalState.playing.get()
      if (getPlay) {
        video.play();
      }

      const checkPlayingState = setInterval(() => {
        if (!globalState.playing.get() && video) {
          video.pause();
        }
      }, 100);

      video.addEventListener('ended', () => {
        clearInterval(checkPlayingState);
        playNextVideo();
      }, { once: true });
    }
    else if (video && type === "image" && isHtmlImageElement(video)) {
      const imageDuration = (end - start);

      let checkImageDisplayState;

      const startImageDisplay = () => {
        checkImageDisplayState = setInterval(() => {
          if (!globalState.playing.get()) {
            clearTimeout(imageTimeout);
            clearInterval(checkImageDisplayState);
          }
        }, 100);
      };

      const imageTimeout = setTimeout(() => {
        clearTimeout(imageTimeout);
        clearInterval(checkImageDisplayState);
        playNextVideo();
      }, imageDuration);

      startImageDisplay();
    }
    else {
      console.log("no video left")
    }
  }
};

export const playFrames = () => {
  if (!globalState.playing.get()) {
    return;
  }
  const elapsedTime = Date.now() - startedTime;
  const newTime = startedTimePlay + elapsedTime;
  updateTimeTo(newTime);


  if (newTime > globalState.maxTime.get()) {
    globalState.currentKeyFrame.set(0);
    setPlaying(false);
  } else {
    requestAnimationFrame(() => {
      playFrames();
    });
  }
};

export const updateTimeTo = (newTime) => {
  setCurrentTimeInMs(newTime);

  const trimListfo = globalState.trimmedVideos.get();
  const trimfo = Object.values(trimListfo);
  const trimList = globalState.trimmedVideo2.get();
  const trim = Object.values(trimList);
  for (let index = 0; index < trimfo.length; index++) {
    const t1 = trimfo[index]
    const t2 = trim[index]
    if (!t1.fabricObject) {
      return;
    }
    const isInside = t2.sceneTimeFrame.start <= newTime && newTime <= t2.sceneTimeFrame.end;
    t1.fabricObject.visible = isInside;
  }
};

export const setCurrentTimeInMs = (time) => {
  const fps = globalState.fps.get();
  const currentKey = Math.floor((time / 1000) * fps);
  globalState.currentKeyFrame.set(currentKey);
  globalState.currentTimeInMs.set(time);

};

export const handleSeek = (value) => {
  if (globalState.playing.get()) {
    setPlaying(false);
  }
  updateTimeTo(value);
  updateVideoElements();
  updateAudioElements();
};

export const addUploadedMedia = (type, src, name, duration) => {
  globalState.uploadedMedia.merge([{ type, src, name, duration }]);
};

export const addUploadedAudio = (type, src, name, duration) => {
  globalState.uploadedAudio.merge([{ type, src, name, duration }]);
};

export const updateEditorElementTimeFrame = (editedMedia, timeFrame) => {
  const elements = globalState.mediaElements.get();
  const Media = Object.values(elements)
  const updatedElements = Media.map(el => {
    if (el.name === editedMedia.name) {
      globalState.updateTimeframeScene.set(el.scene)
      const start = editedMedia.timeFrame.start ?? 0;
      const end = editedMedia.timeFrame.start ?? 0;
      const newTimeFrame = {
        start: timeFrame.start !== undefined ? timeFrame.start : start,
        end: timeFrame.end !== undefined ? timeFrame.end : end
      };
      return { ...el, timeFrame: { ...el.timeFrame, ...newTimeFrame } };
    } else {
      return el;
    }
  });

  updateEditorElement(updatedElements)

};

export const updateAudioElementTimeFrame = (editedAudio, timeFrame) => {
  const Media = globalState.sceneElements.get();
  const updatedElements = Media.map(el => {
    if (el.name === editedAudio.name) {
      // Ensure to provide a default value (0) if start is not defined
      const start = editedAudio.timeFrame.start;
      const newTimeFrame = {
        start: timeFrame.start !== undefined ? timeFrame.start : start,
        end: timeFrame.end !== undefined ? timeFrame.end : (editedAudio.timeFrame.end)
      };
      return { ...el, timeFrame: { ...el.timeFrame, ...newTimeFrame } };
    } else {
      return el;
    }
  });
};

export const addTrimmedResource = (url, sceneName, mediaName, start, sceneEnd, type, sceneStart, end, width, height) => {
  // Initialize final width and height based on the original values
  let finalWidth = width;
  let finalHeight = height;

  // Calculate aspect ratio
  const aspectRatio = height / width;

  // Case: Width > Height
  if (width > height) {
    finalWidth = 198;  // Fix width to 198
    finalHeight = 198 * aspectRatio;  // Adjust height proportionally

    // Ensure height doesn't exceed 352
    if (finalHeight > 352) {
      finalHeight = 352;  // Fix height to 352
      finalWidth = 352 / aspectRatio;  // Adjust width proportionally
    }
  }
  // Case: Height >= Width
  else {
    finalHeight = 352;  // Fix height to 352
    finalWidth = 352 / aspectRatio;  // Adjust width proportionally

    // Ensure width doesn't exceed 198
    if (finalWidth > 198) {
      finalWidth = 198;  // Fix width to 198
      finalHeight = 198 * aspectRatio;  // Adjust height proportionally
    }
  }
  const canvasWidth = 198;
  const canvasHeight = 352;
  const xPos = (canvasWidth - finalWidth) / 2;  // Center horizontally
  const yPos = (canvasHeight - finalHeight) / 2;  // Center vertically

  // Continue with the rest of your function logic
  addTrimmedVideo({
    name: `trimmed-${sceneName}.mp4`,  // Corrected the string concatenation
    type: type,
    scene: sceneName,
    sceneTimeFrame: {
      start: sceneStart,
      end: sceneEnd,
    },
    timeFrame: {
      start: start,
      end: end,
    },
    placement: {
      x: xPos,
      y: yPos,
      width: finalWidth,   // Use adjusted width
      height: finalHeight,  // Use adjusted height
      rotation: 0,
      scaleX: 1,
      scaleY: 1,
    },
    properties: {
      src: url,
      mediaName: mediaName,
      name: `trimmed-${sceneName}.mp4`,  // Corrected the string concatenation
      effect: {
        type: "none",
      },
    },
  });
};



export const onDone = () => {
  console.log("video element uploaded successfully")
}

export const addTrimmedVideo = (trimmedVideo) => {
  const currentTrimmedVideos = globalState.trimmedVideos.get();
  const currentTrimmedVideos2 = globalState.trimmedVideo2.get();
  const selectedScene = globalState.selectedElement.get()?.name;
  if (selectedScene && currentTrimmedVideos[selectedScene]) {
    globalState.trimmedVideos.merge({
      [selectedScene]: {
        ...currentTrimmedVideos[selectedScene],
        ...trimmedVideo,
      }
    });
    globalState.trimmedVideo2.merge({
      [selectedScene]: {
        ...currentTrimmedVideos2[selectedScene],
        ...trimmedVideo,
      }
    });
    globalState.updatedSceneMedia.set(selectedScene)
  } else {
    globalState.trimmedVideos.merge({ [trimmedVideo.scene]: trimmedVideo });
    globalState.trimmedVideo2.merge({ [trimmedVideo.scene]: trimmedVideo })
  }
  // const sortedScenes = Object.fromEntries(
  //   Object.entries(globalState.trimmedVideos.get()).sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
  // );

  console.log(globalState.trimmedVideos.get());

  updateVideoElements()
  globalState.deletedScene.set(null)
};

export function isEditorAudioElement(element) {
  return element.type === "audio"
}
export function isEditorVideoElement(element) {
  return element.type === "video"
}

export function isEditorImageElement(element) {
  return element.type === "image"
}

export const addMediaResource = (type, src, name, duration, sceneStart, sceneName, sceneEnd, width, height) => {
  // handleDelete(name,false);
  if (type === 'video') {
    addEditorElement({
      name,
      type: "video",
      scene: sceneName,
      sceneStart: sceneStart,
      sceneEnd: sceneEnd,
      placement: {
        x: 0,
        y: 0,
        width: width,
        height: height,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
      },
      timeFrame: { start: sceneStart ? sceneStart : 0, end: sceneStart ? sceneStart + duration : duration },
      properties: {
        src,
        name,
        effect: {
          type: "none",
        },
      },
    });
  } else if (type === 'image') {
    addEditorElement({
      name,
      type: "image",
      scene: sceneName,
      sceneStart: sceneStart,
      sceneEnd: sceneEnd,
      placement: {
        x: 0,
        y: 0,
        width: width,
        height: height,
        rotation: 0,
        scaleX: 1,
        scaleY: 1,
      },
      timeFrame: {
        start: sceneStart ? sceneStart : 0,
        end: sceneStart ? sceneStart + duration : duration,
      },
      properties: {
        src,
        name,
        effect: {
          type: "none",
        },
      },
    });
  }
};

export const addAudioResource = (type, src, name, duration) => {
  addAudioElement({
    name,
    type: type,
    placement: {
      x: 0,
      y: 0,
      width: 200,
      height: 200,
      rotation: 0,
      scaleX: 1,
      scaleY: 1,
    },
    timeFrame: {
      start: 0,
      end: duration,
    },
    properties: {
      src,
      name,
      effect: {
        type: "none",
      },
    },
  });
}

export const addTextResource = (options) => {
  addTextElement({
    name: `Text ${options.id}`,
    type: "text",
    placement: {
      x: 0,
      y: 0,
      width: 100,
      height: 100,
      rotation: 0,
      scaleX: 1,
      scaleY: 1
    },
    timeFrame: {
      start: 0,
      end: globalState.maxTime.get()
    },
    properties: {
      text: options.text,
      fontSize: options.fontSize,
      fontWeight: options.fontWeight,
      fontColor: options.fontColor, // Add font color here
      splittedTexts: []
    }
  });
}


export const clearMediaFromPreviousScene = (mediaName) => {
  const sceneElements = globalState.sceneElements.get();
  console.log("sceneElemenets clear", sceneElements)
  // const sceneElementsArray = JSON.parse(JSON.stringify(sceneElements));
  // let sceneName;
  // const updatedScenes = sceneElements.map((scene) => {
  //   if (scene.media && ((Object.values(scene.media))[0]?.name !== mediaName)) {

  //     return {
  //       ...scene,
  //       media: {}, 
  //     };
  //   }
  //   return scene;
  // });
  // console.log("update",JSON.parse(JSON.stringify(updatedScenes)))
  // globalState.sceneElements.set(JSON.parse(JSON.stringify(updatedScenes)));

  // const media = globalState.mediaElements.get()[sceneName]
  // console.log(sceneName,media)
  // const trimfo = globalState.trimmedVideos.get()[sceneName]
  // console.log(trimfo)
  // const trim = globalState.trimmedVideo2.get()[sceneName]
  // console.log(trim)
};

export const updateEditorElement = (mediaArray) => {
  const mediaElements = globalState.mediaElements.get();
  mediaArray.forEach(media => {
    if (mediaElements[media.name]) {
      globalState.mediaElements[media.name].set(JSON.parse(JSON.stringify(media)))
    } else {
      console.warn(`Media with name ${media.name} not found.`);
    }
  });
  addMediaInScene(false)
};

export const setselectedTrimVideo = (selectedElement) => {

  const canvas = globalState.canvas.get();
  if (canvas) {
    if (selectedElement?.fabricObject) {
      canvas.setActiveObject(selectedElement.fabricObject);
    } else {
      canvas.discardActiveObject();
    }
  }
};

export const updateSelectedElement = () => {
  const mediaList = globalState.mediaElements.get();
  const mediaElement = Object.values(mediaList);
  const selectedName = globalState.selectedElement?.name?.get();
  const selectedElement = (mediaElement.name === selectedName) ?? null;
  globalState.selectedElement.set(JSON.parse(JSON.stringify(selectedElement)));
};

export const addEditorElement = (element) => {
  const mediaElements = globalState.mediaElements.get();

  if (mediaElements[element.name]) {
    globalState.mediaElements[element.name].set(none);
  }
  globalState.mediaElements.merge({ [element.name]: element });

  updateSelectedElement()
  addMediaInScene(true);
};

export const addAudioElement = (audio) => {
  const scene = globalState.sceneElements.get()
  if (scene && scene.length > 0) {
    globalState.sceneElements.merge([audio])

  } else {
    alert("First Add Scenes...........");
  }
  globalState.externalAudioUrls.set(prev => ({
    ...prev,
    [audio.name]: {
      url: audio.properties.src,
      sceneStart: audio.timeFrame.start,
      sceneEnd: audio.timeFrame.end,
      type: audio.type
    }
  }));
}

export const addTextElement = (text) => {
  const scene = globalState.sceneElements.get()
  if (scene && scene.length > 0) {
    globalState.sceneElements.merge([text])
  } else {
    alert("First Add Scenes...........");
  }
  refreshElement(text)
}

export const addScene = (sceneVideos) => {
  const updatedElements = sceneVideos.map((sceneVideo) => {
    console.log("sceneVideo", sceneVideo);
    return {
      name: sceneVideo.name,
      type: 'scene',
      src: sceneVideo.src,
      timeFrame: { start: sceneVideo.start, end: sceneVideo.end },
      media: {},
      properties: {
        effect: { type: 'none' },
      },
    };
  });
  globalState.sceneElements.set(updatedElements);
  globalState.mediaSceneElement.set(updatedElements);
  const scene = globalState.mediaSceneElement.get()
  const recondingEnd = scene[scene.length - 1].timeFrame.end
  globalState.maxTime.set(recondingEnd)
};

export const addMediaInScene = (toggle) => {
  const sceneElements = globalState.sceneElements.get();
  const mediaElements = globalState.mediaElements.get();
  console.log(mediaElements)
  const sceneElementsArray = JSON.parse(JSON.stringify(sceneElements));
  const mediaElementsObject = JSON.parse(JSON.stringify(mediaElements));

  const updatedScenes = sceneElementsArray.map((scene) => {
    const matchingMediaElement = Object.values(mediaElementsObject).find(
      (media) => (media.scene === scene.name)
    );
    if (matchingMediaElement) {
      console.log("matching", matchingMediaElement)
      if (toggle) {
        globalState.updatedSceneMedia.set(scene.name)
      }
      return {
        ...scene,
        media: {
          [matchingMediaElement.name]: matchingMediaElement,
        },
      };
    }
    return {
      ...scene,
      media: {},
    };
  });
  globalState.sceneElements.set(updatedScenes);
  console.log("sceneElement", globalState.sceneElements.get())
  const UpdatedSceneElement = JSON.parse(JSON.stringify(globalState.sceneElements.get()));
  if (globalState.deletedScene.get()) {
    //   const sceneName = globalState.updatedSceneMedia.get()
    // }
    // else{
    globalState.updatedSceneMedia.set(globalState.deletedScene.get())
  }
  const sceneName = globalState.updatedSceneMedia.get()
  console.log("sceneName", sceneName)
  const trimScene = UpdatedSceneElement.find((scene) => (scene.name === sceneName))
  console.log("trim", trimScene)
  trimVideo(trimScene)
};

export const addMediaInScene2 = () => {
  const sceneElements = globalState.sceneElements.get();
  const mediaElements = globalState.mediaElements.get();
  const sceneElementsArray = JSON.parse(JSON.stringify(sceneElements));
  const mediaElementsObject = JSON.parse(JSON.stringify(mediaElements));

  const updatedScenes = sceneElementsArray.map((scene) => {
    const matchingMediaElement = Object.values(mediaElementsObject).find(
      (media) => (media.scene === scene.name)
    );
    if (matchingMediaElement) {
      return {
        ...scene,
        media: {
          [matchingMediaElement.name]: matchingMediaElement,
        },
      };
    }
    return {
      ...scene,
      media: {},
    };
  });
  // trimVideo(updatedScenes)
  globalState.sceneElements.set(updatedScenes);
  console.log("sceneElement", globalState.sceneElements.get())
  const UpdatedSceneElement = JSON.parse(JSON.stringify(globalState.sceneElements.get()));
  const sceneName = globalState.updateTimeframeScene.get()
  const trimScene = UpdatedSceneElement.find((scene) => (scene.name === sceneName))
  console.log("trimScene", trimScene)
  trimVideo(trimScene)
  // refreshTrimVideo(trimScene)
};


const trimVideo = async (Scene) => {
  const editedMedia = Object.values(Scene.media)[0]
  console.log("editedMedia", editedMedia)
  const sceneName = Scene.name;
  const mediaName = editedMedia.name;
  const type = editedMedia.type;
  const width = editedMedia.placement.width;
  const height = editedMedia.placement.height;
  if (editedMedia && type === "video") {
    const videoPath = editedMedia.properties.src;
    const duration = Scene.timeFrame.end - Scene.timeFrame.start
    const mediaDuration = millisecondsToHHMMSS(duration)
    const deltaValue = JSON.parse(JSON.stringify(globalState.deltaValue.get()));
    const del = deltaValue[sceneName] !== undefined ? deltaValue[sceneName] : 0;
    const start = Math.abs(0 - del)
    const mediaStart = millisecondsToHHMMSS(start)
    const ffmpeg = globalState.ffmpeg.get()
    await ffmpeg.writeFile('input.mp4', await fetchFile(videoPath));
    try {
      await ffmpeg.exec([
        "-ss",
        mediaStart,
        "-i",
        "input.mp4",
        "-t",
        mediaDuration,
        "-c:v",
        "copy",
        "output.mp4"
      ]);
    } catch (error) {
      console.error("FFmpeg execution error:", error);
    }
    const data = await ffmpeg.readFile('output.mp4');
    const url = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }));
    const end = duration + start
    addTrimmedResource(url, sceneName, mediaName, start, Scene.timeFrame.end, type, Scene.timeFrame.start, end, width, height)
    const updatedTrimmedUrls = { ...globalState.trimmedUrls.get() };
    // Check if URL for the scene already exists, and update it
    if (updatedTrimmedUrls[sceneName]) {
      updatedTrimmedUrls[sceneName] = url;  // Update the existing URL
    } else {
      updatedTrimmedUrls[sceneName] = url;  // Add the new URL
    }
    globalState.trimmedUrls.set(updatedTrimmedUrls);
  }
  else if (editedMedia && type === "image") {
    const url = editedMedia.properties.src;
    const deltaValue = JSON.parse(JSON.stringify(globalState.deltaValue.get()));
    const del = deltaValue[sceneName] !== undefined ? deltaValue[sceneName] : 0;
    const duration = Scene.timeFrame.end - Scene.timeFrame.start
    const start = Math.abs(0 - del)
    const end = duration + start
    addTrimmedResource(url, sceneName, mediaName, start, Scene.timeFrame.end, type, Scene.timeFrame.start, end, width, height)
    globalState.trimmedUrls.merge({ [Scene.name]: url })
  }
  else {
    console.log("media is not added to the scene")
  }
};

export const millisecondsToHHMMSS = (ms) => {
  const seconds = Math.floor(ms / 1000);
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const pad = (num) => String(num).padStart(2, '0');

  return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
};

export const extractAudioFromVideo = async () => {

  const trimmedVideo = Object.values(globalState.trimmedVideos.get());
  const ffmpeg = globalState.ffmpeg.get();

  for (let i = 0; i < trimmedVideo.length; i++) {
    const video = trimmedVideo[i];
    const scene = video.scene;
    const type = video.type;
    const { start, end } = video.sceneTimeFrame;
    const duration = end - start; // Calculate duration for audio
    const durationInHHMMSS = millisecondsToHHMMSS(duration)
    const outputAudioFileName = `output${i}.mp3`;

    if (video.type === "video") {
      const src = video.properties.src;
      const videoFileName = `input${i}.mp4`;

      // Write the video file
      await ffmpeg.writeFile(videoFileName, await fetchFile(src));
      try {
        // Extract audio from video
        await ffmpeg.exec([
          '-i',
          videoFileName,
          '-vn',                        // No video
          '-acodec', 'libmp3lame',       // Convert to MP3
          outputAudioFileName
        ]);
      } catch (error) {
        console.error("FFmpeg execution error:", error);
      }
    } else if (video.type === "image") {
      try {
        // Generate silent audio using `anullsrc` for the duration of the image scene
        await ffmpeg.exec([
          '-f', 'lavfi',                 // Use a lavfi (FFmpeg's filter generator)
          '-i', `anullsrc=r=44100:cl=stereo`, // Generate silent audio (stereo, 44100Hz)
          '-t', durationInHHMMSS,     // Set the duration of the silent audio
          '-q:a', '9',                   // Lower audio quality for smaller file
          outputAudioFileName
        ]);
      } catch (error) {
        console.error("FFmpeg silent audio generation error:", error);
      }
    }

    // Read the audio file (whether from video or silence)
    const data = await ffmpeg.readFile(outputAudioFileName);
    const url = URL.createObjectURL(new Blob([data.buffer], { type: 'audio/mp3' }));

    // Update globalState with the new audio URL
    globalState.audioUrls.set(prev => ({
      ...prev,
      [scene]: {
        url: url,
        sceneStart: start,
        sceneEnd: end,
        type: type
      }
    }));
  }
  await combineAudio2();
  await combineAudio()
}

export const audiomerging = async () => {
  await extractAudioFromVideo();
  await combineAudio();
}

export const recordCanvasMedia = async () => {
  const scene = globalState.mediaSceneElement.get()
  const recondingEnd = scene[scene.length - 1].timeFrame.end
  const video = document.createElement("video");
  const canvas = document.getElementById("canvas");
  const stream = canvas.captureStream();
  video.srcObject = stream;
  video.play();
  const options = { mimeType: "video/webm; codecs= av1" };
  const mediaRecorder = new MediaRecorder(stream, options);
  const chunks = [];
  mediaRecorder.ondataavailable = function (e) {
    chunks.push(e.data);
  };
  mediaRecorder.onstop = function (e) {
    const blob = new Blob(chunks, { type: "video/webm" });
    const url = URL.createObjectURL(blob);
    globalState.recordedVideo.set(url)
    insertAudioInVideo(url)
  };
  mediaRecorder.start();
  setTimeout(() => {
    mediaRecorder.stop();
  }, recondingEnd);
}

// let frameCount = 0;
// const frames =[]
// export const animation = () => {
//   // Clear and draw on the canvas
//   // context.clearRect(0, 0, canvas.width, canvas.height);
//   // Draw something that changes over time
//   // ...
//   // Extract frame
//   const frame = globalState.canvas.get()?.toDataURL();
//   frames.push(frame);
//   frameCount++;
//   if (frameCount < 60) { // Extract 60 frames
//     requestAnimationFrame(animation);
//   }
//   console.log(frames)
// };

// export const convertFabricCanvasToMp4 = async () => 
//   console.log("console.log")
//   const fabricCanvas = globalState.canvas.get()
//   // 1. Create an OffscreenCanvas of the same dimensions as the Fabric.js canvas
//   const offscreenCanvas = new OffscreenCanvas(720, 1280);
//   const ctx = offscreenCanvas.getContext("2d", { willReadFrequently: true, desynchronized: true });

//   // 2. Create an MP4 Muxer
//   let muxer = new Mp4Muxer.Muxer({
//     target: new Mp4Muxer.ArrayBufferTarget(),
//     video: {
//       codec: "avc",
//       width: offscreenCanvas.width,
//       height: offscreenCanvas.height,
//     },
//     fastStart: "in-memory",
//   });

//   // 3. Set up VideoEncoder
//   let videoEncoder = new VideoEncoder({
//     output: (chunk, meta) => muxer.addVideoChunk(chunk, meta),
//     error: (e) => console.error(e),
//   });

//   videoEncoder.configure({
//     codec: "avc1.42001f",
//     width: offscreenCanvas.width,
//     height: offscreenCanvas.height,
//     bitrate: 500_000,
//     bitrateMode: "constant",
//   });
//   const scene = globalState.mediaSceneElement.get()
//   const recordingEnd = scene[scene.length - 1].timeFrame.end
//   const fps = 30;
//   const duration = recordingEnd / 1000; // You can customize this duration (in seconds)
//   const numFrames = fps * duration;

//   // 4. Render each frame to the OffscreenCanvas and encode it
//   console.log("fabricCanvas", fabricCanvas)

//   for (let frameNumber = 0; frameNumber < numFrames; frameNumber++) {
//     // Draw the Fabric.js canvas onto the OffscreenCanvas
//     const fabricCanvasElement = fabricCanvas.toCanvasElement();
//     // console.log("fabricElement",fabricCanvasElement)
//     const fabricCanvasWidth = fabricCanvasElement.width;
//     const fabricCanvasHeight = fabricCanvasElement.height;

//     const canvasWidth = offscreenCanvas.width;
//     const canvasHeight = offscreenCanvas.height;

//     // Calculate aspect ratios
//     const fabricAspectRatio = fabricCanvasWidth / fabricCanvasHeight;
//     const offscreenAspectRatio = canvasWidth / canvasHeight;

//     let drawWidth, drawHeight;
//     let offsetX = 0, offsetY = 0;

//     // Scale proportionally based on aspect ratio
//     if (fabricAspectRatio > offscreenAspectRatio) {
//       // If fabric canvas is wider relative to height
//       drawWidth = canvasWidth;
//       drawHeight = canvasWidth / fabricAspectRatio;
//       offsetY = (canvasHeight - drawHeight) / 2;  // Center vertically
//     } else {
//       // If fabric canvas is taller relative to width
//       drawHeight = canvasHeight;
//       drawWidth = canvasHeight * fabricAspectRatio;
//       offsetX = (canvasWidth - drawWidth) / 2;  // Center horizontally
//     }

//     // Clear the canvas before rendering each frame
//     ctx.clearRect(0, 0, canvasWidth, canvasHeight);

//     // Draw the fabric canvas content scaled and centered
//     ctx.drawImage(fabricCanvasElement, 0, 0, fabricCanvasWidth, fabricCanvasHeight, offsetX, offsetY, drawWidth, drawHeight);

//     // Encode the current frame
//     await renderCanvasFrameAndEncode({ offscreenCanvas, videoEncoder, frameNumber, fps });
//   }

//   // Flush remaining encodes
//   await videoEncoder.flush();

//   // Finalize the MP4 file
//   muxer.finalize();

//   // 5. Get the MP4 data and trigger the download
//   const buffer = muxer.target.buffer;
//   downloadMp4File(new Blob([buffer]));


// // Helper function to render each frame and encode it
// export const renderCanvasFrameAndEncode = async ({ offscreenCanvas, videoEncoder, frameNumber, fps }) => {
//   let frame = new VideoFrame(offscreenCanvas, {
//     timestamp: (frameNumber * 1e6) / fps, // Timestamp in microseconds
//   });
//   videoEncoder.encode(frame);
//   frame.close(); // Release the VideoFrame to free memory
// }

// export const downloadMp4File = (blob) => {
//   let url = window.URL.createObjectURL(blob);
//   let a = document.createElement("a");
//   a.style.display = "none";
//   a.href = url;
//   a.download = "fabric_canvas.mp4";
//   document.body.appendChild(a);
//   a.click();
//   window.URL.revokeObjectURL(url);
// }


export const combineAudio2 = async () => {
  const audioUrls = Object.values(globalState.audioUrls.get());
  const urls = audioUrls.map(audioObj => audioObj.url); // Adjust based on structure
  const ffmpeg = globalState.ffmpeg.get(); // Get FFmpeg instance from global state
  try {
    let command1 = ""
    for (let index = 0; index < audioUrls.length; index++) {
      const audioUrl = urls[index]
      const audioFileName = `input${index}.mp3`; // Ensure it's the correct extension
      const audio = await fetchFile(audioUrl)
      await ffmpeg.writeFile(audioFileName, audio);
      console.log(`File ${audioFileName} written to FFmpeg FS.`);
      command1 += `[${index}:a]`
    };

    // Generate the ffmpeg commandfil
    const inputParams = urls.flatMap((_, index) => ["-i", `input${index}.mp3`]);
    const filterComplex = `${command1}concat=n=${urls.length}:v=0:a=1`

    // Run the ffmpeg command to concatenate audios
    await ffmpeg.exec([
      ...inputParams,
      "-filter_complex", `${filterComplex}`,
      "output.mp3"
    ]);

    // Get the output audio and create a URL for it '[0:a][1:a]concat=n=2:v=0:a=1'
    const data = await ffmpeg.readFile("output.mp3");
    const audioBlob = new Blob([data.buffer], { type: "audio/mpeg" });
    const audioUrl = URL.createObjectURL(audioBlob);
    globalState.combinedUrl.set(audioUrl);
  }
  catch (error) {
    console.error('FFmpeg execution error:', error);
  }
};

export const combineAudio = async () => {
  const audioUrls = Object.values(globalState.externalAudioUrls.get());
  const interAudioUrl = globalState.combinedUrl.get()
  const urls = audioUrls.map(audioObj => audioObj.url); // Adjust based on structure
  const ffmpeg = globalState.ffmpeg.get(); // Get FFmpeg instance from global state
  try {
    let filterComplex = ""
    let audioDelays = "";
    await ffmpeg.writeFile('internal.mp3', await fetchFile(interAudioUrl));
    for (let index = 0; index < audioUrls.length; index++) {
      const audioUrl = audioUrls[index].url;
      const start = audioUrls[index].start;
      const audioFileName = `input${index}.mp3`; // Ensure it's the correct extension
      const audio = await fetchFile(audioUrl)
      await ffmpeg.writeFile(audioFileName, audio);
      console.log(`File ${audioFileName} written to FFmpeg FS.`);
      audioDelays += `[${index + 1}:a]`;
    };

    // Generate the ffmpeg commandfil
    const inputParams = audioUrls.flatMap((_, index) => ["-i", `input${index}.mp3`]);
    filterComplex = "[0:a]" + audioDelays + " " + `amix=inputs=${(audioUrls.length) + 1}:duration=longest [a]`;

    // Run the ffmpeg command to concatenate audios
    await ffmpeg.exec([
      "-i", "internal.mp3", ...inputParams,
      "-filter_complex", `${filterComplex}`,
      "-map", "[a]",
      "output.mp3"
    ]);

    // Get the output audio and create a URL for it '[0:a][1:a]concat=n=2:v=0:a=1'
    const data = await ffmpeg.readFile("output.mp3");
    const audioBlob = new Blob([data.buffer], { type: "audio/mpeg" });
    const audioUrl = URL.createObjectURL(audioBlob);
    globalState.bothCombineUrl.set(audioUrl);
  }
  catch (error) {
    console.error('FFmpeg execution error:', error);
  }
};

export const insertAudioInVideo = async (src) => {
  await extractAudioFromVideo();
  const audioUrl = globalState.bothCombineUrl.get();
  console.log("audioUrl", audioUrl);
  const videoUrl = src
  const ffmpeg = globalState.ffmpeg.get()

  const video = await fetchFile(videoUrl)
  const audio = await fetchFile(audioUrl)

  await ffmpeg.writeFile("video_url.mp4", video);
  await ffmpeg.writeFile("inputAudio.mp3", audio);
  try {
    await ffmpeg.exec([
      '-i', 'video_url.mp4',
      '-i', 'inputAudio.mp3',
      '-map', '0:v',
      '-map', '1:a',
      '-c:v', 'copy',
      '-shortest', 'output_video.mp4'
    ]);
  } catch (error) {
    console.error("FFmpeg execution error:", error);
  }
  const data = await ffmpeg.readFile("output_video.mp4");
  const url = URL.createObjectURL(new Blob([data.buffer], { type: 'video/mp4' }));
  globalState.downloadUrl.set(url)
  const a = document.createElement("a");
  a.href = url;
  a.download = "video.mp4";
  a.click();
}

export const addAnimation = (animation) => {
  globalState.animations.merge([animation]);
  // refreshAnimations();
}

export const updateAnimation = (name, animation) => {
  const index = globalState.animations.get().findIndex((a) => a.name === name);
  globalState.animations[index].set(animation);
  refreshAnimations();
}

export const removeAnimation = (name) => {
  const animation = globalState.animations.get().filter(animation => animation.name !== name);
  globalState.animations.set(animation);
  refreshAnimations();
}

export const refreshAnimations = () => {
  const animations = globalState.animations.get();
  console.log("animation",animations)
  console.log(globalState.animationTimeLine.get())
  const currentTimeline = globalState.animationTimeLine.get();
  console.log("currentTimeline",currentTimeline)
  if (currentTimeline) {
    anime.remove(currentTimeline); 
  }

  const maxTime = globalState.maxTime.get();
  const newAnimeTimeLine = anime.timeline({
    autoplay: false,
    duration: maxTime,
  });
  console.log("newTimeline",newAnimeTimeLine)
  globalState.animationTimeLine.set(newAnimeTimeLine);
  console.log("animationTimeline",globalState.animationTimeLine.get())
  const mediaElements = Object.values(globalState.trimmedVideos.get());
  console.log(mediaElements)
  for (let i = 0; i < animations.length; i++) {
    const animation = animations[i];
    console.log("animation",animation)
    const editorElement = mediaElements.find(
      (element) => element.scene === animation.targetId
    );
    console.log("editorElement",editorElement)
    const fabricObject = editorElement?.fabricObject;
    console.log("favricObject",fabricObject)
    if (!editorElement || !fabricObject) {
      console.warn(`No fabric object found for scene: ${animation.targetId}`);
      continue;
    }

    fabricObject.clipPath = undefined;

    switch (animation.type) {
      case "fadeIn": {
        newAnimeTimeLine.add(
          {
            opacity: [0, 1],
            duration: animation.duration,
            delay: 1000,
            targets: fabricObject,
            easing: "linear",
          },
          editorElement.sceneTimeFrame.start
        );
        break;
      }
      case "fadeOut": {
        newAnimeTimeLine.add(
          {
            opacity: [1, 0],
            duration: animation.duration,
            delay: 1000,
            targets: fabricObject,
            easing: "linear",
          },
          editorElement.sceneTimeFrame.end - animation.duration
        );
        break;
      }
      case "slideIn": {
        newAnimeTimeLine.add(
          {
            translateX: [-500, 0],
            duration: animation.duration,
            targets: fabricObject,
            easing: "easeInOutQuart",
          },
          editorElement.timeFrame.start
        );
        break;
      }
      case "slideOut": {
        newAnimeTimeLine.add(
          {
            translateX: [0, 500],
            duration: animation.duration,
            targets: fabricObject,
            easing: "easeInOutQuart",
          },
          editorElement.timeFrame.end - animation.duration
        );
        break;
      }
      case "breathe": {
        newAnimeTimeLine.add(
          {
            scale: [1, 1.1],
            duration: animation.duration,
            targets: fabricObject,
            direction: 'alternate',
            easing: "easeInOutSine",
            loop: true,
          },
          editorElement.timeFrame.start
        );
        break;
      }
      default: {
        console.warn(`Unknown animation type: ${animation.type}`);
        break;
      }
    }
  }

  globalState.animationTimeLine.set(newAnimeTimeLine);
};

const getTextObjectsPartitionedByCharacters = (textObject, element) => {
  let copyCharsObjects = []
  // replace all line endings with blank
  const characters = (textObject.text ?? "").split("").filter(m => m !== "\n")
  const charObjects = textObject.__charBounds
  if (!charObjects) return []
  const charObjectFixed = charObjects
    .map((m, index) => m.slice(0, m.length - 1).map(m => ({ m, index })))
    .flat()
  const lineHeight = textObject.getHeightOfLine(0)
  for (let i = 0; i < characters.length; i++) {
    if (!charObjectFixed[i]) continue
    const { m: charObject, index: lineIndex } = charObjectFixed[i]
    const char = characters[i]
    const scaleX = textObject.scaleX ?? 1
    const scaleY = textObject.scaleY ?? 1
    const charTextObject = new fabric.Text(char, {
      left: charObject.left * scaleX + element.placement.x,
      scaleX: scaleX,
      scaleY: scaleY,
      top: lineIndex * lineHeight * scaleY + element.placement.y,
      fontSize: textObject.fontSize,
      fontWeight: textObject.fontWeight,
      fill: "#fff"
    })
    copyCharsObjects.push(charTextObject)
  }
  return copyCharsObjects
}

export const updateEffect = (name, effect) => {
  const editedElement = Object.values(globalState.trimmedVideos.get())
  const index = editedElement.findIndex(element => element.scene === name)
  const element = editedElement[index]
  if (isEditorVideoElement(element) || isEditorImageElement(element)) {
    const updatedMedia = {
      ...element, // Spread the top-level properties of element
      properties: {
        ...element.properties, // Spread the properties of the properties object
        effect: {
          ...element.properties.effect, // Spread the properties of the effect object
          type: effect // Update the type key with the new value
        }
      }
    };
    globalState.trimmedVideos[name].set(updatedMedia)
  }
  globalState.updatedSceneMedia.set(name)
  refreshTrimVideo2()
}

export const handleDelete = (mediaName, toggle) => {
  console.log("media", mediaName)
  if (toggle) {
    const uploadedMedia = globalState.uploadedMedia.get()
    const indexToRemove = uploadedMedia.findIndex(item => item.name === mediaName);
    if(indexToRemove !==1){
    globalState.uploadedMedia[indexToRemove].set(none)
    }
  }
  const mediaElements = globalState.mediaElements.get()
  const deletedMedia = mediaElements[mediaName].scene
  globalState.deletedScene.set(deletedMedia)
  console.log(deletedMedia)
  const sceneElements = globalState.sceneElements.get()
  const indexOfScene = sceneElements.findIndex(item => item.name === deletedMedia)
  globalState.sceneElements[indexOfScene].media.set({})
  globalState.mediaElements[mediaName].set(none)
  globalState.trimmedUrls[deletedMedia].set(none)
  console.log(globalState.trimmedUrls.get())
  const trimmedVideos = globalState.trimmedVideos.get()
  const fabric = trimmedVideos[deletedMedia].fabricObject
  console.log("fabric", fabric)
  const deletedTrimMedia =  globalState.trimmedVideo2[deletedMedia].name
  globalState.trimmedVideo2[deletedMedia].set(none)
  globalState.trimmedVideos[deletedMedia].set(none)
  console.log(globalState.trimVideoId.get(),deletedTrimMedia)
  const trimmedId = globalState.trimVideoId.get()
  const trimIdArray =[...trimmedId]
  const indexofTrimMedia = trimIdArray.findIndex(item => item.name === deletedTrimMedia)
  // globalState.trimmedId[indexofTrimMedia].set(none)
  if (indexofTrimMedia !== -1) {
    const itemToRemove = trimIdArray[indexofTrimMedia];
    const updatedSet = new Set(trimmedId); // Clone the set
    updatedSet.delete(itemToRemove); 
    globalState.trimVideoId.set(updatedSet);
  }
  console.log(globalState.uploadedMedia.get(), globalState.mediaElements.get(), globalState.sceneElements.get(), globalState.trimmedVideos.get(), globalState.trimmedVideo2.get())
  refreshTrimVideo2()
  // updateVideoElements()
}