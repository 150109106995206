import React from 'react';

import '../../../css/LandingPage/docker.css'; 

import influenzo from '../../../assets/icons/LandingPage/logo.png';


function Footer() {
    const handleCookieConsentClick = (event) => {
        // Your function to handle the Cookie consent button click
        event.preventDefault(); // Prevent default action if necessary
        // Logic for toggling info display goes here
        // OneTrust.ToggleInfoDisplay(); // Uncomment and modify this line according to your needs
    };

    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="footer-content">
                    <div className="footer-info">
                        <a className="footer-logo" href="/">
                            <img src={influenzo} alt="Influenzo Logo" loading="lazy" width="160px" height="160px" />
                        </a>

                        {/* Your language selector component */}
                        <select className="select-v2 lang-selector is-secondary is-md" style={{ width: 'fill' }}>
                            <option value="/de/">DE</option>
                            <option value="/es/">ES</option>
                            <option value="/pt/">PT</option>
                            <option value="/it/">IT</option>
                            <option value="/ru/">RU</option>
                            <option value="/tr/">TR</option>
                            <option value="/fr/">FR</option>
                            <option value="/" selected>EN</option>
                        </select>

                        <div className="contact-us">
                            Any questions?
                            <a className="contact-email" href="mailto:swarit@influenzo.io">
                                <div className="icon-mail_mob"></div>
                                swarit@influenzo.com
                            </a>
                        </div>
                        {/* <div className="social-links">
                            Add your social links here
                        </div> */}
                        <div className="copy">
                            Copyright © 2024
                        </div>
                        <div className="service">
                            influenzo is not endorsed or certified by Instagram. All Instagram TM logos and trademarks displayed on this application are property of Instagram. Service provider: Wiseway SIA, Avotu iela 23-3, Riga, LV-1011, Latvia
                        </div>
                    </div>

                    <div className="footer-nav">
                        <div className="nav-block">
                            <div className="block-title">INFLUENZO</div>
                            <div className="nav-item"><a href="/about">Support</a></div>
                            <div className="nav-item"><a href="/blog">Blog</a></div>
                            {/* <div className="nav-item"><a href="/contact">Become a Partner</a></div> */}
                        </div>

                        {/* <div className="nav-block">
                            <div className="block-title">SERVICES</div>
                            <div className="nav-item"><a href="/direct-messages/">Direct Messages</a></div>
                            <div className="nav-item"><a href="/scheduled-posting/">Scheduled Posting</a></div>
                            <div className="nav-item"><a href="https://bot.inflact.com/">Promo</a></div>
                        </div> */}

                        <div className="nav-block">
                            <div className="block-title">TOOLS</div>
                            {/* <div className="nav-item"><a href="/tools/instagram-hashtag-generator/" title="Instagram hashtag generator">Hashtag Generator</a></div>
                            <div className="nav-item"><a href="/tools/instagram-top-hashtags/" title="Most Popular Instagram Hashtags">Hashtag Trends</a></div>
                            <div className="nav-item"><a href="/downloader/" title="Download all social media photos and videos">Downloader</a></div>
                            <div className="nav-item"><a href="/tools/profile-analyzer/" title="Analyze Instagram profile">Profile Analyzer</a></div>
                            <div className="nav-item"><a href="/tools/instagram-search/" title="Instagram Search">Instagram Search</a></div>
                            <div className="nav-item"><a href="/tools/stories-viewer/" title="Stories Viewer">Stories Viewer</a></div>
                            <div className="nav-item"><a href="/profiles/instagram-viewer/" title="Viewer for Instagram">Viewer for Instagram</a></div>
                            <div className="nav-item"><a href="/tools/fonts-generator/" title="Fonts Generator">Fonts Generator</a></div> */}
                            <div className="nav-item"><a href="/creator/easyedit" title="Fonts Generator">EasyEdit</a></div> 
                            <div className="nav-item"><a href="/creator/easyfind" title="Fonts Generator">EasyFind</a></div> 
                        </div>

                        <div className="nav-block">
                            <div className="block-title">LEGAL</div>
                            <div className="nav-item"><a href="/termsandconditions">Terms of Service</a></div>
                            <div className="nav-item"><a href="/privacypolicy">Privacy Policy</a></div>
                            <div className="nav-item"><a href="/billing-information/">Billing Information</a></div>
                            <div className="nav-item"><a href="/refund-and-cancellation-policy/">Refund and Cancellation Policy</a></div>
                            <div className="nav-item">
                                <a href="javascript:void(0)"
                                   onClick={handleCookieConsentClick}
                                   data-cf-modified-72e5e74a50e799faf3059c1d-="">
                                    Cookie consent
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;