"use client";
import React, { useState } from "react";
import {useGlobalState, updateEffect } from "../Store/Store";
import PropTypes from "prop-types";
import { Select, MenuItem } from "@mui/material";

const EFFECT_TYPE_TO_LABEL = {
  blackAndWhite: "Black and White",
  saturate: "Saturate",
  sepia: "Sepia",
  invert: "Invert",
  none: "None",
};



const EffectResource = ({ editorElement }) => {

  const store = useGlobalState();

  console.log("editorElement", editorElement);
  const [selectedEffect, setSelectedEffect] = useState(editorElement.properties.effect.type || '');

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedEffect(selectedValue);
    store.selectedEffect.set({ [editorElement.scene]: selectedValue })
    console.log("selectedEffect", selectedValue);

    // Use the selectedValue directly to update the effect
    updateEffect(editorElement.scene, selectedValue);
  };

  return (
    <div className="rounded-lg overflow-hidden items-center bg-slate-800 m-[15px] flex flex-col relative min-h-[100px] p-2">
      <div className="flex flex-row justify-between w-full">
        <div className="text-white py-1 text-base text-left w-full">
          {EFFECT_TYPE_TO_LABEL[editorElement.properties.effect.type]}
        </div>
      </div>
      {/* Select effect from dropdown */}
      <Select
        className="bg-slate-100 text-black rounded-lg px-2 py-1 ml-2 w-16 text-xs"
        value={selectedEffect}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Select scene" }}
        style={{ minWidth: 30 }}
      >
        <MenuItem value="" disabled>
          Select Effect
        </MenuItem>
        {Object.keys(EFFECT_TYPE_TO_LABEL).map((type) => (
          <MenuItem key={type} value={type}>
            {EFFECT_TYPE_TO_LABEL[type]}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

EffectResource.propTypes = {
  editorElement: PropTypes.shape({
    scene: PropTypes.string.isRequired,
    properties: PropTypes.shape({
      effect: PropTypes.shape({
        type: PropTypes.string,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export default EffectResource;
