import React from 'react';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';

// import Topbar from "../topbar/Topbar";
// import Homepage from "../blogpage/homepage/Homepage";
// import Login from "../blogspage/login/Login";
// import Register from "../components/pages/register/Register";
// import Settings from "../components/pages/settings/Settings";
// import Single from "../blogpage/single/Single";
// import Write from "../components/pages/write/Write";


import LandingPage from '../Pages/Landing_Page/Landing';
import Login from '../Pages/Signin_Signup_Page/Login';
import Details from '../Pages/Profile_data_pages/Details';
import Page from '../Pages/NewPage';
import IgLogin from '../Pages/Iglogin';
import Pricing from '../Pages/Pricing_Page/Pricing';
import ErrorPage from '../Pages/ErrorPage';
import ProtectedRoute from "./protectedRoute";

import SubAppWrapper from '../../Analysis/index';
import { SoftUIControllerProvider } from "context";
import FAQ from 'components/Pages/Extra/FAQ';
import Termsandconditions from 'components/Pages/Extra/Termsandconditions';
import PrivacyPolicy from 'components/Pages/Extra/PrivacyPolicy';
import Contact from 'components/Pages/Extra/Contact';
import About from 'components/Pages/Extra/AboutUs/About';
import Author from 'components/Pages/Extra/Author';
import Video from 'components/Pages/VideoEditor';
import Health from 'components/Pages/health';
import FacebookSdk from 'components/Pages/Profile_data_pages/Facebook';


function Logout() {
  localStorage.clear();
  return <Navigate to="/login" />;
}

const getRoutes = (allRoutes) =>
  allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }

    if (route.route) {
      return <Route exact path={route.route} element={route.component} key={route.key} />;
    }

    return null;
  });

const Rout = () => {
  return (

      <BrowserRouter>
      {/* <Topbar /> */}
        <Routes>
        
        {/* <Route path="/posts" element={<Homepage />} /> */}
        {/* <Route path="/register" element={currentUser ? <Homepage /> : <Register />} /> */}
        {/* <Route path="/login" element={currentUser ? <Homepage /> : <Login />} /> */}
        {/* <Route path="/post/:id" element={<Single />} /> */}
        {/* <Route path="/write" element={currentUser ? <Write /> : <Login />} /> */}
        {/* <Route path="/settings" element={currentUser ? <Settings /> : <Login />} /> */}
        {/* <Route path="/about" element={<About/>} /> */}
        
        <Route path="/FAQ" element={<FAQ/>} />
        <Route path="/termsandconditions" element={<Termsandconditions/>} />
        <Route path="/privacypolicy" element={<PrivacyPolicy/>} />

          <Route path='/' element={<LandingPage />} />
          <Route path='/login' element={<Login />} />
          {/* <Route path='/login' element={<SoftUIControllerProvider><Login/></SoftUIControllerProvider>} /> */}
          <Route path='/logout' element={<Logout />} />
          <Route path='/details' element={<ProtectedRoute><Details /></ProtectedRoute>} />
          <Route path='/page' element={<Page />} />
          <Route path='/iglogin' element={<IgLogin />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/*' element={<ErrorPage />} />
          <Route path='/creator/*' element={<ProtectedRoute><SoftUIControllerProvider><SubAppWrapper /></SoftUIControllerProvider></ProtectedRoute>} />
          <Route path='/about' element={<SoftUIControllerProvider><About/></SoftUIControllerProvider>} />
          <Route path='/blog' element={<SoftUIControllerProvider><Author/></SoftUIControllerProvider>}/>
          <Route path='/contact' element={<SoftUIControllerProvider><Contact/></SoftUIControllerProvider>}/>
          <Route path='/EasyEdit' element={<SoftUIControllerProvider><Video/></SoftUIControllerProvider>}/>
          <Route path='/health' element={<Health/>} />
          <Route path="/facebook" element={<FacebookSdk/>} />

        </Routes>
        </BrowserRouter>

  );
}

export default Rout;

